import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom"
import {Button, Form, Row, Col, DatePicker, Input, Select, Table, Pagination, Modal} from 'antd'
import {PlusOutlined, SearchOutlined, ReloadOutlined, DownloadOutlined, SelectOutlined} from '@ant-design/icons'
import PageTop from '../../../../components/layout/PageTop'
import SearchArea from '../../../../components/layout/SearchArea'
import PageBottom from '../../../../components/layout/PageBottom'
import common from "../../../../utils/common"

function InspectItems() {
    const [form] = Form.useForm()
    const [formDialog] = Form.useForm()
    const initParams = {
        code: '',
        name: "",
        categoryId: "",
        partId: "",
        status: ''
    }
    const [searchParams, setSearchParams] = useState(initParams)
    const [dialogVisible, setDialogVisible] = useState(false)
    const [dataList, setDataList] = useState([])
    const [positionList, setPositionList] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [total, setTotal] = useState(0)
    const [pagination, setPagination] = useState({page: 1, limit: 15, })

    const TYPE_LIST = [
        {label: '车辆常规保养检查项目', value: '1'},
        {label: '竣工交付质检项目', value: '2'},
    ]
    const POSITION_LIST = [
        {label: '发动机', value: '1'},
        {label: '制作系', value: '2'},
        {label: '转向系', value: '3'},
        {label: '行驶系', value: '4'},
        {label: '传动系', value: '5'},
        {label: '灯光导线', value: '6'},
        {label: '车架车身', value: '7'},
    ]
    const onSearch = (vals) => {
        // 处理undefined参数
        Object.keys(vals).map(key => {
            if (vals[key] === undefined) {
                vals[key] = ''
            }
        })
        // 合并默认参数和表单参数
        vals = {...searchParams, ...vals}
        setSearchParams(vals)
        setPagination({
            ...pagination,
            page: 1
        })
        // TODO search
    }
    const expandedRowRender = (record, index, indent, expanded) => {
        if (expanded && record && record.itemList) {
            return <Table
                scroll={{x: '100%'}}
                columns={[
                    {
                        title: '项序',
                        dataIndex: 'sort',
                        key: 'sort',
                        ellipsis: true,
                        width: 30,
                    },
                    {
                        title: '所属部位',
                        dataIndex: 'position',
                        key: 'position',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '项目名称/提示',
                        dataIndex: 'spec',
                        key: 'spec',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '状态',
                        dataIndex: 'status',
                        key: 'status',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '创建时间',
                        dataIndex: 'createAt',
                        key: 'createAt',
                        ellipsis: true,
                        width: 180,
                    },
                    {
                        title: '创建人',
                        dataIndex: 'creator',
                        key: 'creator',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '操作',
                        key: 'operation',
                        align: 'center',
                        ellipsis: true,
                        width: 100,
                        render: (text, record) => {
                            return (
                                <div className={"action-btns"}>
                                    <Link to={``}>编辑</Link>
                                    <Link to={``}>删除</Link>
                                </div>
                            )
                        }
                    }
                ]} dataSource={record.itemList} pagination={false} />
        }
    }
    const onInit = () => {
        common.ajax('get', '/support/term/findByTaxonomyAndOwnerId', {
            taxonomy: 'vehicleInspectionPart',
            ownerId: common.getUser().company.id,
        }).then(res => {
            res = res.filter(item => item.status == 1)
            setPositionList(res)
        }).finally(common.loadingStop)

        common.ajax('get', '/support/term/findByTaxonomyAndOwnerId', {
            taxonomy: 'vehicleInspectionProjectCategory',
            ownerId: common.getUser().company.id,
        }).then(res => {
            res = res.filter(item => item.status == 1)
            setCategoryList(res)
        }).finally(common.loadingStop)
    }
    const onGetData = async () => {
        const res = await common.ajax('get', '/support/inspectionProject/page', {
            ...searchParams,
            ...pagination,
            ownerId: common.getUser().company.id,
        }).finally(common.loadingStop)
        if (res && res.list) {
            setDataList(res.list)
        }
        setTotal(res.pagination.total)
    }
    useEffect(() => {
        onInit()
    }, [])
    useEffect(() => {
        onGetData()
    }, [pagination])
    return (
        <>
            <PageTop title="预检项目" >
                <Button icon={<PlusOutlined />} type="primary" onClick={() => {
                    setDialogVisible(true)
                    formDialog.resetFields()
                    formDialog.setFieldsValue({
                        sort: dataList.length,
                        status: 'enable'
                    })
                }}>新增</Button>
            </PageTop>
            <SearchArea>
                <Form form={form} onFinish={onSearch} initialValues={searchParams} >
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="项目编号" className='label-character-4' name="code">
                                <Input autoComplete='off' type="text" placeholder={'输入项目编号'} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="项目类别" className='label-character-4' name="categoryId">
                                <Select >
                                    <Select.Option value={''} key={0}>全部</Select.Option>
                                    {
                                        categoryList.map((position, pindex) => {
                                            return <Select.Option value={position.id} key={position.id}>{position.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="所属部位" className='label-character-4' name="partId">
                                <Select >
                                    <Select.Option value={''} key={0}>全部</Select.Option>
                                    {
                                        positionList.map((position, pindex) => {
                                            return <Select.Option value={position.id} key={position.id}>{position.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="状态" className='label-character-4' name="status">
                                <Select >
                                    <Select.Option value={''} key={0}>全部</Select.Option>
                                    <Select.Option value={'enable'} key={1}>启用</Select.Option>
                                    <Select.Option value={'disabled'} key={2}>禁用</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="项目名称" className='label-character-4' name="name">
                                <Input autoComplete='off' type="text" placeholder={'输入项目名称'} />
                            </Form.Item>
                        </Col>
                        <Col span={18}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined />} onClick={() => {form.submit()}}>搜索</Button>
                                <Button icon={<ReloadOutlined />} onClick={() => {
                                    setSearchParams(initParams)
                                    form.resetFields()
                                    setTimeout(() => {
                                        form.resetFields()
                                        setSearchParams(initParams)
                                        form.submit()
                                    }, 30)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table
                rowKey={record => record.id}
                columns={[
                    {
                        title: '项序',
                        dataIndex: 'sort',
                        key: 'sort',
                        ellipsis: true,
                        width: 30,
                    },
                    {
                        title: '检查单编号',
                        dataIndex: 'code',
                        key: 'code',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '检查单类别',
                        dataIndex: 'categoryName',
                        key: 'categoryName',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '所属部位',
                        dataIndex: 'partName',
                        key: 'partName',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '项目名称/提示',
                        dataIndex: 'name',
                        key: 'name',
                        ellipsis: true,
                        width: 300,
                    },
                    {
                        title: '状态',
                        dataIndex: 'status',
                        key: 'status',
                        ellipsis: true,
                        width: 120,
                        render: (text, record) => {
                            return (
                                text == 'enable' ? '启用' : '禁用'
                            )
                        }
                    },
                    {
                        title: '创建时间',
                        dataIndex: 'createdAt',
                        key: 'createdAt',
                        ellipsis: true,
                        width: 180,
                    },
                    {
                        title: '创建人',
                        dataIndex: 'creatorName',
                        key: 'creatorName',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '操作',
                        key: 'operation',
                        align: 'center',
                        ellipsis: true,
                        width: 100,
                        render: (text, record) => {
                            return (
                                <div className={"action-btns"}>
                                    <Link onClick={() => {
                                        formDialog.setFieldsValue(record)
                                        setDialogVisible(true)
                                    }}>编辑</Link>
                                    {/* <Link to={``}>删除</Link> */}
                                </div>
                            )
                        }
                    }
                ]}
                dataSource={dataList}
                scroll={{x: '100%'}}
                pagination={false}
            />
            {/* 可折叠版本 */}
            {/* <Table
                rowKey={record => record.id}
                columns={[
                    {
                        title: '序号',
                        key: 'index',
                        width: 30,
                        fixed: 'left',
                        render: (text, record, index) => `${index + 1}`,
                    },
                    {
                        title: '检查单编号',
                        dataIndex: 'code',
                        key: 'code',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '检查单名称',
                        dataIndex: 'name',
                        key: 'name',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '检查单类别',
                        dataIndex: 'type',
                        key: 'type',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '操作',
                        key: 'operation',
                        align: 'center',
                        ellipsis: true,
                        width: 100,
                        render: (text, record) => {
                            return (
                                <div className={"action-btns"}>
                                    <Link to={``}>编辑</Link>
                                    <Link to={``}>删除</Link>
                                </div>
                            )
                        }
                    }
                ]}
                dataSource={[{
                    id: '20250108150920',
                    sort: 1, code: 'INS20250108A0001', name: '车辆常规保养检查套餐1', type: '车辆常规保养检查项目', status: '启用', createAt: '2025-01-08 14:59:55', creator: '测试', itemList: [
                        {sort: 1, position: '制动系', spec: '外露螺栓、螺母拧紧状况', status: '启用', createAt: '2025-01-08 14:59:55', creator: '测试'},
                        {sort: 2, position: '制动系', spec: '制动管、制动液检查，不足添加（8万公里更换一次）', status: '启用', createAt: '2025-01-08 14:59:55', creator: '测试'},
                        {sort: 3, position: '制动系', spec: '查询自诊断系统故障存储器（解码）', status: '启用', createAt: '2025-01-08 14:59:55', creator: '测试'},
                        {sort: 4, position: '制动系', spec: '轮胎气压是否正常（前后轮：240kpa）', status: '启用', createAt: '2025-01-08 14:59:55', creator: '测试'},
                        {sort: 5, position: '制动系', spec: '全车灯光功能是否正常', status: '启用', createAt: '2025-01-08 14:59:55', creator: '测试'},
                        {sort: 6, position: '制动系', spec: '喇叭、刮水器、洗涤器是否正常', status: '启用', createAt: '2025-01-08 14:59:55', creator: '测试'},
                        {sort: 7, position: '制动系', spec: '检查车门限位器及车门铰链，必要时打油润滑', status: '启用', createAt: '2025-01-08 14:59:55', creator: '测试'},
                        {sort: 8, position: '制动系', spec: '目测线路情况 （干涉、磨损、坚固）', status: '启用', createAt: '2025-01-08 14:59:55', creator: '测试'},
                        {sort: 9, position: '制动系', spec: '驱动电机固定、三相线紧固、接地线固定情况', status: '启用', createAt: '2025-01-08 14:59:55', creator: '测试'},
                        {sort: 10, position: '转向系', spec: '驱动电机水冷系统、位置、温度传感器检测', status: '启用', createAt: '2025-01-08 14:59:55', creator: '测试'},
                        {sort: 11, position: '转向系', spec: '动力电池组箱体紧固检查，有无破损', status: '启用', createAt: '2025-01-08 14:59:55', creator: '测试'},
                        {sort: 12, position: '转向系', spec: '动力电池组MSD拉手、高低压插接件情况', status: '启用', createAt: '2025-01-08 14:59:55', creator: '测试'},
                        {sort: 13, position: '行驶系', spec: 'DC/DC转换器线束、插接件是否正常', status: '启用', createAt: '2025-01-08 14:59:55', creator: '测试'},
                        {sort: 14, position: '灯光导线', spec: '电机控制器接地线是否牢固', status: '启用', createAt: '2025-01-08 14:59:55', creator: '测试'},
                        {sort: 15, position: '灯光导线', spec: '冷却液是否正常，不足添加', status: '启用', createAt: '2025-01-08 14:59:55', creator: '测试'},
                        {sort: 16, position: '灯光导线', spec: '齿轮油(电机油）是否正常，不足添加', status: '启用', createAt: '2025-01-08 14:59:55', creator: '测试'},
                        {sort: 17, position: '灯光导线', spec: '目测充电机插接件紧固情况、诊断测试', status: '启用', createAt: '2025-01-08 14:59:55', creator: '测试'},
                        {sort: 18, position: '灯光导线', spec: '充电机接地线检测，绝缘电阻≥100MΩ,接地电阻≤0.1Ω', status: '启用', createAt: '2025-01-08 14:59:55', creator: '测试'},
                        {sort: 19, position: '灯光导线', spec: '检查空调系统', status: '启用', createAt: '2025-01-08 14:59:55', creator: '测试'},
                        {sort: 20, position: '车架车身', spec: '试车检查：脚、手制动器，查询故障存储器，终检', status: '启用', createAt: '2025-01-08 14:59:55', creator: '测试'},
                    ]
                }, {
                    id: '20250108150935',
                    sort: 2, code: 'INS20250108A0002', name: '竣工交付质检套餐1', type: '竣工交付质检项目', status: '启用', createAt: '2025-01-08 14:59:55', creator: '测试', itemList: [
                        {sort: 1, position: '制动系', spec: '外露螺栓、螺母拧紧状况', status: '启用', createAt: '2025-01-08 14:59:55', creator: '测试'},
                        {sort: 2, position: '制动系', spec: '制动管、制动液检查，不足添加（8万公里更换一次）', status: '启用', createAt: '2025-01-08 14:59:55', creator: '测试'}
                    ]
                }]}
                scroll={{x: '100%'}}
                pagination={false}
                expandable={{
                    expandedRowRender,
                    defaultExpandedRowKeys: ['0'],
                    rowExpandable: (record) => {
                        return record.index != '小计'
                    }
                }}
            /> */}

            {/* 分页 */}
            <PageBottom pagination={
                <Pagination
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total}
                    current={pagination.page}
                    showSizeChanger
                    pageSizeOptions={['15', '30', '50', '100']}
                    defaultPageSize={pagination.limit}
                    onChange={(page, limit) => {
                        setPagination({page, limit})
                    }}
                    onShowSizeChange={(page, limit) => {
                        setPagination({page, limit})
                    }}
                />
            }></PageBottom>
            {/* 弹框 */}
            <Modal
                visible={dialogVisible}
                width={1000}
                footer={null}
                onCancel={() => {setDialogVisible(false)}}
                zIndex={1000}>
                <br />
                <PageTop title="预检项目" >
                    <Button icon={<PlusOutlined />} type="primary" onClick={() => {
                        formDialog.submit()
                    }}>保存</Button>
                </PageTop>
                <Form form={formDialog} onFinish={(vals) => {
                    Object.keys(vals).map(key => {
                        if (vals[key] === undefined) {
                            vals[key] = ''
                        }
                    })
                    // TODO save 
                    if (vals.id != '') {
                        common.ajax('post', '/support/inspectionProject/updateById', {
                            ...vals,
                            ownerId: common.getUser().company.id,
                        }).then(res => {
                            formDialog.resetFields()
                            setDialogVisible(false)
                            onGetData()
                        }).finally(common.loadingStop)
                    } else {
                        common.ajax('post', '/support/inspectionProject/create', {
                            ...vals,
                            ownerId: common.getUser().company.id,
                        }).then(res => {
                            formDialog.resetFields()
                            setDialogVisible(false)
                            setPagination({
                                ...pagination,
                                page: 1
                            })
                        }).finally(common.loadingStop)
                    }
                }} >
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label="检查单类别" rules={[{required: true}]} className='label-character-4' name="categoryId">
                                <Select >
                                    {
                                        categoryList.map((position, pindex) => {
                                            return <Select.Option value={position.id} key={position.id}>{position.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="所属部位" rules={[{required: true}]} className='label-character-4' name="partId">
                                <Select >
                                    {
                                        positionList.map((position, pindex) => {
                                            return <Select.Option value={position.id} key={position.id}>{position.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="项目状态" rules={[{required: true}]} className='label-character-4' name="status">
                                <Select >
                                    <Select.Option value={'enable'} key={1}>启用</Select.Option>
                                    <Select.Option value={'disabled'} key={2}>禁用</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label="名称" rules={[{required: true}]} className='label-character-4' name="name">
                                <Input autoComplete='off' type="text" placeholder={'输入名称'} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="备注" rules={[{required: true}]} className='label-character-4' name="description">
                                <Input autoComplete='off' type="text" placeholder={'输入备注'} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="序号" rules={[{required: true}]} className='label-character-4' name="sort">
                                <Input autoComplete='off' type="text" placeholder={'输入序号（正序排列）'} />
                            </Form.Item>
                            <Form.Item hidden name="id">
                                <Input autoComplete='off' type="text" readOnly />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {/* <Form form={formDialog} onFinish={(vals) => {
                    Object.keys(vals).map(key => {
                        if (vals[key] === undefined) {
                            vals[key] = ''
                        }
                    })
                    // TODO save 
                }} >
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label="检查单编号" className='label-character-4' name="code">
                                <Input autoComplete='off' type="text" placeholder={'自动生成检查单编号'} disabled />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="检查单名称" className='label-character-4' name="name">
                                <Input autoComplete='off' type="text" placeholder={'输入检查单名称'} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="检查单类别" className='label-character-4' name="type">
                                <Select >
                                    {
                                        categoryList.map((position, pindex) => {
                                            return <Select.Option value={position.id} key={position.id}>{position.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="所属部位" className='label-character-4' name="position">
                                <Select >
                                    {
                                        positionList.map((position, pindex) => {
                                            return <Select.Option value={position.id} key={position.id}>{position.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="项目名称/提示" className='label-character-4' name="spec">
                                <Input autoComplete='off' type="text" placeholder={'输入项目名称/提示'} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="项目状态" className='label-character-4' name="status">
                                <Select >
                                    <Select.Option value={'enable'} key={1}>启用</Select.Option>
                                    <Select.Option value={'disabled'} key={2}>禁用</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label="标记" className='label-character-4' name="flag">
                                <Input autoComplete='off' type="text" placeholder={'设置标记'} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="序号" className='label-character-4' name="sort">
                                <Input autoComplete='off' type="text" placeholder={'输入序号（正序排列）'} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form> */}
            </Modal>
        </>
    )
}

export default InspectItems