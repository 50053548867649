import React, {useEffect, useState} from 'react'
import {Space, Button, Modal, Form, Select, Input, InputNumber} from 'antd'
import {SoundFilled} from '@ant-design/icons'
import common from '../../../../utils/common'

function RepairBoard() {
    const [settingForm] = Form.useForm()
    const [broadcastForm] = Form.useForm()
    const [settingVisible, setSettingVisible] = useState(false)
    const [broadcastVisible, setBroadcastVisible] = useState(false)
    const [totalData2, setTotalData2] = useState([])
    const [totalData3, setTotalData3] = useState([])
    const [totalData4, setTotalData4] = useState([])
    const [totalData5, setTotalData5] = useState([])
    const onGetData = async () => {
        // /support/board
        const res = await common.ajax('get', '/support/repair/list', {
            code: '',
            name: '',
            plate: '',
            status: 0,
            type: '',
            repairCategoryIds: '',
            consignorCompanyId: '',
            employeeNames: '',
            employeeIds: '',
            phone: '',
            makeAtStart: '',
            makeAtEnd: '',
            clearingAtStart: '',
            clearingAtEnd: '',
            exampleVin: '',
            bothVin: '',
            productName: '',
            ownerId: common.getUser().company.id,
            statuses: '2,3,4,5',
            orderBy: " created_at DESC ",
            page: 1,
            limit: 100
        }).finally(common.loadingStop)
        if (res && res.repairs) {
            let status2 = res.repairs.filter(item => item.status == 2)
            let status3 = res.repairs.filter(item => item.status == 3)
            let status4 = res.repairs.filter(item => item.status == 4)
            let status5 = res.repairs.filter(item => item.status == 5)
            setTotalData2(status2)
            setTotalData3(status3)
            setTotalData4(status4)
            setTotalData5(status5)
        }
    }
    useEffect(() => {
        onGetData()
    }, [])
    return (
        <div>
            <div style={{display: 'flex', height: 'calc(100vh - 64px - 60px - 60px)'}}>
                <div style={{width: 0, flex: 1, paddingLeft: '10px', overflow: 'hidden', background: '#00b57844'}}>
                    <div style={{fontSize: '36px', fontWeight: 800}}>待取车（{totalData5.length}台）</div>
                    <div style={{display: 'flex', flexWrap: 'wrap', overflow: 'hidden'}}>
                        {
                            totalData5.map((item, index) => {
                                return <div key={index} style={{width: '135px', padding: '8px 0', border: "2px solid #666", textAlign: 'center', borderRadius: '4px', marginBottom: '8px', marginRight: '10px', background: '#f8f8f8'}}>
                                    <div style={{fontSize: '28px', fontWeight: 800}}>{item.repairCategoryName}</div>
                                    <div style={{fontSize: '24px', overflow: 'hidden', 'text-overflow': 'ellipsis', 'white-space': 'nowrap'}}>{item.plate}</div>
                                </div>
                            })
                        }
                    </div>
                </div>
                <div style={{width: 0, flex: 1, paddingLeft: '10px', overflow: 'hidden', background: '#ff8f1f44'}}>
                    <div style={{fontSize: '36px', fontWeight: 800}}>已竣工（{totalData4.length}台）</div>
                    <div style={{display: 'flex', flexWrap: 'wrap', overflow: 'hidden'}}>
                        {
                            totalData4.map((item, index) => {
                                return <div key={index} style={{width: '135px', padding: '8px 0', border: "2px solid #666", textAlign: 'center', borderRadius: '4px', marginBottom: '8px', marginRight: '10px', background: '#f8f8f8'}}>
                                    <div style={{fontSize: '28px', fontWeight: 800}}>{item.repairCategoryName}</div>
                                    <div style={{fontSize: '24px', overflow: 'hidden', 'text-overflow': 'ellipsis', 'white-space': 'nowrap'}}>{item.plate}</div>
                                </div>
                            })
                        }
                    </div>
                </div>
                <div style={{width: 0, flex: 1, paddingLeft: '10px', overflow: 'hidden', background: '#ff314144'}}>
                    <div style={{fontSize: '36px', fontWeight: 800}}>施工中（{totalData3.length}台）</div>
                    <div style={{display: 'flex', flexWrap: 'wrap', overflow: 'hidden'}}>
                        {
                            totalData3.map((item, index) => {
                                return <div key={index} style={{width: '135px', padding: '8px 0', border: "2px solid #666", textAlign: 'center', borderRadius: '4px', marginBottom: '8px', marginRight: '10px', background: '#f8f8f8'}}>
                                    <div style={{fontSize: '28px', fontWeight: 800}}>{item.repairCategoryName}</div>
                                    <div style={{fontSize: '24px', overflow: 'hidden', 'text-overflow': 'ellipsis', 'white-space': 'nowrap'}}>{item.plate}</div>
                                </div>
                            })
                        }
                    </div>
                </div>
                <div style={{width: 0, flex: 1, paddingLeft: '10px', overflow: 'hidden', background: '#1677ff44'}}>
                    <div style={{fontSize: '36px', fontWeight: 800}}>待派工（{totalData2.length}台）</div>
                    <div style={{display: 'flex', flexWrap: 'wrap', overflow: 'hidden'}}>
                        {
                            totalData2.map((item, index) => {
                                return <div key={index} style={{width: '135px', padding: '8px 0', border: "2px solid #666", textAlign: 'center', borderRadius: '4px', marginBottom: '8px', marginRight: '10px', background: '#f8f8f8'}}>
                                    <div style={{fontSize: '28px', fontWeight: 800}}>{item.repairCategoryName}</div>
                                    <div style={{fontSize: '24px', overflow: 'hidden', 'text-overflow': 'ellipsis', 'white-space': 'nowrap'}}>{item.plate}</div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
            <div >
                <div style={{lineHeight: '30px', fontSize: '24px', marginTop: '5px', display: 'flex', justifyContent: 'space-between'}}>
                    上海东方汽车销售服务有限公司
                    <Space>
                        <Button type='primary' onClick={() => {setSettingVisible(true)}}>看板设置</Button>
                        <Button type='primary' onClick={() => {setBroadcastVisible(true)}}>立即播报</Button>
                    </Space>
                </div>
                <div style={{lineHeight: '25px', fontSize: '20px'}}>成都市一环路南四段A世界电脑城&nbsp;&nbsp;&nbsp;&nbsp;联系电话：028-85781020</div>
            </div>
            <Modal title={`看板设置`}
                visible={settingVisible}
                width={600}
                footer={null}
                onCancel={() => {setSettingVisible(false)}}
                zIndex={1000}>
                <Form labelCol={{span: 4}} wrapperCol={{span: 20}} labelAlign='left' form={settingForm} initialValues={{
                    min: 3,
                    type: 2,
                    word: '请{车牌号，XXX，XXXX}到客户区取车！'
                }}
                    onFinish={vals => {
                        Object.keys(vals).map(key => {
                            if (vals[key] == undefined) {
                                vals[key] = ''//dataUpdateRecord[key]
                            }
                        })
                    }}
                >
                    <Form.Item label={"更新频率"} name="min">
                        <InputNumber placeholder={'更新频率'} addonAfter="分钟" />
                    </Form.Item>
                    <Form.Item label={"播报类型"} name="type">
                        <Select placeholder="播报类型" >
                            {
                                [1, 2, 3].map((c, cindex) => {
                                    return <Select.Option value={c} key={cindex}>类型{cindex}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label={"播报格式"} name="word">
                        <Input.TextArea rows={3} placeholder={'播报格式'} />
                    </Form.Item>
                    <Form.Item label={" "} >
                        <Space>
                            <Button type='primary' onClick={() => {
                                settingForm.submit()
                            }}>保存</Button>
                            <Button>默认</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal title={`立即播报`}
                visible={broadcastVisible}
                width={600}
                footer={null}
                onCancel={() => {setBroadcastVisible(false)}}
                zIndex={1000}>
                <Form labelCol={{span: 4}} wrapperCol={{span: 20}} labelAlign='left' form={broadcastForm} initialValues={{
                    word: '请{车牌号，XXX，XXXX}到客户区取车！'
                }}
                    onFinish={vals => {
                        Object.keys(vals).map(key => {
                            if (vals[key] == undefined) {
                                vals[key] = ''//dataUpdateRecord[key]
                            }
                        })
                    }}
                >
                    <Form.Item label={"播报内容"} name="word">
                        <Input.TextArea rows={3} placeholder={'播报内容'} value={'请{车牌号，XXX，XXXX}到客户区取车！'} />
                    </Form.Item>
                    <Form.Item label={"点击试听"} >
                        <Button>
                            点击试听<SoundFilled />
                        </Button>
                    </Form.Item>
                    <Form.Item label={" "} >
                        <Space>
                            <Button type='primary' onClick={() => {
                                broadcastForm.submit()
                            }}>立即播报</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default RepairBoard
