import React, {useEffect, useState} from "react"
import {Button, Col, DatePicker, Form, Input, InputNumber, Modal, Pagination, Row, Select, Table, Tabs} from "antd";
import {
    CheckSquareOutlined,
    CloseOutlined,
    EditOutlined,
    HistoryOutlined,
    ReloadOutlined,
    SearchOutlined,
    SelectOutlined
} from "@ant-design/icons";
import SearchArea from "../../layout/SearchArea";
import common from "../../../utils/common";
import moment from "moment";
import PageBottom from "../../layout/PageBottom";
import {connect} from "react-redux";
import OldRepairItemList from "../OldRepairItemList";
import LookupEmployee from "../../passport/LookupEmployee";
import BigNumber from "bignumber.js";

const FormItem = Form.Item
const {TabPane} = Tabs
const {Option} = Select;
const {RangePicker} = DatePicker;

// 订单状态
//  const REPAIR_STATUS_CREATING = 1;  // 1工单编辑
//  const REPAIR_STATUS_WAITING = 2;   // 2等待派工
//  const REPAIR_STATUS_SUPPORTING = 3;    // 3维修中（配件出库，取消出库）
//  const REPAIR_STATUS_SUPPORTED = 4; // 4已竣工（取消竣工）
//  const REPAIR_STATUS_PREPAID = 5;   // 5订单预结（取消预结）
//  const REPAIR_STATUS_SETTLED = 6;   // 6.订单结算（取消结算）
//  const REPAIR_STATUS_RECEIVED = 7; // 订单收款

// 收费区分 1:客户自费、2:保险维修、3:厂家索赔、4:委托费用
const CHARGE_TYPE_CUSTOMER = 1
const CHARGE_TYPE_INSURE = 2
const CHARGE_TYPE_FACTORY = 3

let chargeTypeAlias = {
    [CHARGE_TYPE_CUSTOMER]: '客户自费',
    [CHARGE_TYPE_INSURE]: '保险维修',
    [CHARGE_TYPE_FACTORY]: '厂家索赔',
}


// 维修明细类型type： 1.工时费用 2.预估材料 3.维修材料 4.礼券 5.委外维修项目 6.委外维修材料 7.委内维修项目 8.委内维修材料
const REPAIR_ITEM_TYPE_PROJECT = '1';
//  const REPAIR_ITEM_TYPE_PREDICT_PRODUCT = '2';
const REPAIR_ITEM_TYPE_PRODUCT = '3';
//  const REPAIR_ITEM_TYPE_COUPON = '4';
const REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT = '5';
const REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT = '6';
const REPAIR_ITEM_TYPE_DELEGATE_PROJECT = "7";
const REPAIR_ITEM_TYPE_DELEGATE_PRODUCT = "8";

// 维修业务状态 1.启用 2.禁用
const BUSINESS_KIND_STATUS_ENABLE = 1

//  const BUSINESS_KIND_STATUS_DISABLE = 2


function RepairHistory(props) {

    // vehicleId String类型 车辆ID
    // plate String类型 车牌号码
    // vin String类型 车架号码/VIN
    // spareFrameNumber String类型 备用车架号/备用VIN
    // searchAreaVisible bool类型 是否显示搜索框
    // firstLoad bool类型 首次进入是否加载类表数据
    let {
        vehicleId = "",
        plate = "",
        showTabPaneArr = ["1", "2", "3", "5"],
        vin = "",
        spareFrameNumber = "",
        searchAreaVisible = true,
        firstLoad = true
    } = props

    let initialSearch = {
        vehicleId: vehicleId || "",
        plate: plate || "",
        customerName: "",
        // 车架号
        vin: vin || "",
        // 备用车架号
        spareFrameNumber: spareFrameNumber || "",
        code: "",
        repairCategoryId: "",
        employeeName: "",
        employeeId: "",
    }

    let initialRepair = {
        id: "",
        code: "",
    }

    let initialDeliveryItem = {
        id: "",
        productId: "",
        employeeName: "",
        employeeId: "",
    }

    let [businessKinds, setBusinessKinds] = useState([]) // 维修业务
    let [search, setSearch] = useState(initialSearch) // 搜索输入的内容
    let [query, setQuery] = useState(initialSearch) // 提交ajax搜索值
    let [total, setTotal] = useState(0) // 总记录条数
    let [repairs, setRepairs] = useState([]) // 维修工单列表
    let [originMileage, setOriginMileage] = useState([]) // 原始的里程
    let [repair, setRepair] = useState(initialRepair) // 选择的维修工单
    let [projects, setProjects] = useState([]) // 维修项目列表
    let [products, setProducts] = useState([]) // 维修材料列表
    //  let [coupons, setCoupons] = useState([]) // 维修礼券列表
    let [companies, setCompanies] = useState([]) // 经营主体
    let [otherRepairItems, setOtherRepairItems] = useState([]) // 其他费用
    let [repairCategories, setRepairCategories] = useState([]) // 工单类型
    let [rowKey, setRowKey] = useState([]) // 选中的列
    let [oldRepairItemListVisible, setOldRepairItemListVisible] = useState(false) //  原始的维修明细记录
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)    //  选择员工
    let [mileageChangeVisible, setMileageChangeVisible] = useState(false) // 修改里程弹框
    let [deliveryItem, setDeliveryItem] = useState([initialDeliveryItem]) // 领料人
    let [groups, setGroups] = useState([]) //班组
    let [employees, setEmployees] = useState({})// 员工
    let [companyIds, setCompanyIds] = useState([])// 所有的公司
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 6,
    })

    // 获取维修记录
    let getRepairHistory = () => {
        common.loadingStart()
        let params = {
            ...query,
            ...pagination,
            ownerId: common.getUser().company.id,
        }

        common.ajax('get', '/support/repair/list', params).then(res => {
            // 如果维修工单的记录数为 0 返回false
            if (res.pagination.total === 0) {
                setRepairItems([])
                setRepairs([])
                //  setCoupons([])
            } else {
                //  setCoupons(res.repairs[0].plans || []) // 设置礼券
                getRepairItems(res.repairs[0].id || "") // 获取工单列表中的第一条工单的维修明细
                repair = res.repairs[0] || initialRepair
                setRepair({...repair})
                setRowKey([res.repairs[0].id] || "")  // 默认设置维修工单列表第一个为选择的行
                setRepairs(res.repairs || [])
                setTotal(res.pagination.total)
            }
        }).finally(() => {
            common.loadingStop()
        })

    }

    // 页码发生变化就请求数据
    let [loadNum, setLoadNum] = useState(0);
    useEffect(() => {
        if (firstLoad || loadNum > 1) {
            getRepairHistory()
        }
        setLoadNum(loadNum + 1);
    }, [pagination, query])

    useEffect(() => {
        if ((vin !== undefined && vin !== null && vin !== "") || (spareFrameNumber !== undefined && spareFrameNumber !== null && spareFrameNumber !== "")) {
            setSearch({...search, vin: vin || "", spareFrameNumber: spareFrameNumber || ""})
            query = {...search, vin: vin || "", spareFrameNumber: spareFrameNumber || ""}
            setQuery({...query})
        }
    }, [vin, spareFrameNumber])

    useEffect(() => {
        if (plate !== undefined && plate !== null && plate !== "") {
            setSearch({...search, plate: plate})
            query = {...search, plate: plate}
            setQuery({...query})
        }
    }, [plate])

    // 设置行选择
    const rowSelection = {
        type: "radio",
        selectedRowKeys: rowKey, // 选中的列
        onChange: (selectedRowKeys, selectedRows) => {
            // 如果当前选中列和以选择的列 相同 , 则返回
            if (rowKey[0] === selectedRowKeys[0]) {
                return true
            }
            setRowKey(selectedRowKeys)
            repair = {...repair, id: selectedRowKeys[0]}
            setRepair(repair)
            // 通过维修工单id获取维修明细
            getRepairItems(`${selectedRowKeys}`)
        },
    }

    // 获取业务类别
    let getBusinessKinds = () => {
        common.loadingStart()
        let params = {
            ownerId: "",
            status: BUSINESS_KIND_STATUS_ENABLE, // 维修业务状态：1.启用
        }
        common.ajax('get', '/support/businessKind/list', params).then(res => {
            businessKinds = res.businessKinds || []
            let obj = {}
            businessKinds.forEach(item => {
                obj[item.id] = chargeTypeAlias[item.chargeType]
            })
            setBusinessKinds(businessKinds)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(() => {
        getBusinessKinds()  // 获取业务类别
    }, [])

    // 获取工单类型
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/support/term/findByTaxonomy', {taxonomy: 'repairCategory'})
            .then((data) => {
                setRepairCategories(data)
            })
            .finally(() => {
                common.loadingStop()
            })
    }, [])

    // 设置维修明细
    let setRepairItems = (repairItems) => {
        projects = []
        products = []
        otherRepairItems = []
        companyIds = []
        repairItems.forEach(repairItem => {
            repairItem.name = repairItem.projectName === "" ? repairItem.productName : repairItem.projectName
            if (repairItem.type === REPAIR_ITEM_TYPE_PROJECT) {
                projects.push(repairItem)
                if (repairItem.companyId !== null && repairItem.companyId !== "") {
                    companyIds.push(repairItem.companyId)
                }
            }
            repairItem.type === REPAIR_ITEM_TYPE_PRODUCT && products.push(repairItem)
            repairItem.type === REPAIR_ITEM_TYPE_DELEGATE_PROJECT && otherRepairItems.push(repairItem)
            repairItem.type === REPAIR_ITEM_TYPE_DELEGATE_PRODUCT && otherRepairItems.push(repairItem)
            repairItem.type === REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT && otherRepairItems.push(repairItem)
            repairItem.type === REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT && otherRepairItems.push(repairItem)
        })
        setCompanyIds([...new Set(companyIds)]) // 设置公司ids
        let sectionIds = []
        setProjects(projects.map(project => {
            if (!sectionIds.includes(project.sectionId)) {
                project.batchAction = true// 是否显示批量按钮
                sectionIds.push(project.sectionId)
            }
            return project
        }))
        setProducts([...products])
        setOtherRepairItems([...otherRepairItems])
    }

    // 获取维修明细
    let getRepairItems = (id) => {
        common.loadingStart()
        common.ajax('get', '/support/repairItem/findByRepairId?repairId=' + id)
            .then(repairItems => {
                // 设置维修明细
                setRepairItems([...repairItems])
                // 获取领料人
                getWmsEmployeeName()
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    // 获取领料人
    let getWmsEmployeeName = () => {
        if (repair.code === "") {
            return true
        }
        let params = {
            ownerId: common.getUser().company.id,
            orderId: repair.id,
            kind: "part",
            status: 20,
            isReceipt: "yes",
            ignoreAuthority: "yes",
            limit: 1000,
        }
        common.loadingStart()
        common.ajax('get', '/wms/deliveryItem/list', params)
            .then(res => {
                deliveryItem = res.deliveryItems || []
                setDeliveryItem([...deliveryItem])
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    //  当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 修改里程
    let updateMileage = () => {
        common.loadingStart()
        common.ajax(
            'post',
            '/support/repair/updateMileageById',
            {
                ownerId: common.getUser().company.id,
                id: repair.id,
                mileage: repair.mileage,
            })
            .then((res => {
                common.toast("更新成功")
                setMileageChangeVisible(false)
            }))
            .finally(common.loadingStop)
    }

    //  获取当前登录员工的信息
    useEffect(() => {
        common.loadingStart()
        common.ajax("get", "/passport/employee/findCompaniesByAuthUserId")
            .then((res) => {
                companies = res || []
                setCompanies([...companies])
            }).finally(() => {
            common.loadingStop()
        })
    }, [])

    // 分组
    let groupBy = (list, fn) => {
        const groups = {};
        list.forEach(function (o) {
            const group = JSON.stringify(fn(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);
        });
        return groups;
    }

    // 获取公司下的所有员工
    let getEmployeesByCompany = () => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/list', {
            companyIds: companyIds,
            limit: 1000,
            status: 1 // 显示在职人员,离职的不显示
        }).then((data) => {
            let groupData = groupBy(data.employees, (data) => {
                return data.companyId
            })
            setEmployees(groupData)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(() => {
        if (companyIds.length > 0) {
            getEmployeesByCompany()
        }
    }, [companyIds])

    // 修改主修人/派工工时
    let updateDispatchTime = (params) => {
        if (params.dispatchTime === null || params.dispatchTime === "") {
            return
        }
        if (params.workerId === null || params.workerId === "") {
            return
        }
        common.loadingStart()
        common.ajax("post", "/support/repairItem/updateDispatchTime", {
            id: params.id,
            dispatchTime: params.dispatchTime,
            workerId: params.workerId,
        })
            .then((res) => {
            }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取班组
    let getGroups = () => {
        let params = {
            taxonomy: "group",
            userIds: companyIds,
        }
        common.loadingStart()
        common.ajax('get', '/passport/term/findUserTermsByTaxonomy', params)
            .then((data) => {
                groups = data || []
                projects.forEach(project => {
                    if (project.groupId !== "") {
                        Object.keys(employees).forEach(key => {
                            if (key === JSON.stringify(project.companyId)) {
                                project.workers = employees[key].filter(employee => employee.groupId === project.groupId)
                            }
                        })
                    }
                })
                setGroups(data)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        if (companyIds.length > 0 && projects.length > 0) {
            getGroups()
        }
    }, [companyIds, employees, projects])

    // 维修工单列
    let columns = [
        {
            title: '维修单号',
            width: 110,
            dataIndex: 'code',
            ellipsis: true,
        },
        {
            title: '工单类型',
            width: 100,
            dataIndex: 'repairCategoryId',
            ellipsis: true,
            render: text => {
                return (
                    <div>
                        {repairCategories.map(category => {
                            if (category.id === text) {
                                return category.name
                            }
                        })}
                    </div>
                )
            }
        },
        {
            title: '车牌号',
            width: 80,
            dataIndex: 'plate',
            ellipsis: true,
        },
        {
            title: '进厂日期',
            width: 100,
            dataIndex: 'makeAt',
            ellipsis: true,
            render: (text) => {
                return text === null ? "" : moment(text).format('YYYY-MM-DD');
            }
        },
        {
            title: '送修人',
            width: 80,
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: '车系',
            width: 100,
            dataIndex: 'seriesName',
            ellipsis: true,
        },
        {
            title: '里程数',
            width: 120,
            dataIndex: 'mileage',
            ellipsis: true,
            render: (text, record) => {
                return common.can("support.repair.mileage") ? (
                    <Input readOnly
                           style={{width: "100px"}}
                           value={text}
                           addonAfter={
                               <EditOutlined
                                   onClick={() => {
                                       setOriginMileage(text) // 设置原始的里程
                                       repair = record
                                       setRepair(repair)
                                       setMileageChangeVisible(true)
                                   }}/>
                           }
                    />
                ) : text
            }
        },
        {
            title: '车架号',
            width: 150,
            dataIndex: 'vin',
            ellipsis: true,
        },
        {
            title: '备用车架号',
            width: 150,
            dataIndex: 'spareFrameNumber',
            ellipsis: true,
        },
        {
            title: '客户名称',
            width: 100,
            dataIndex: 'customerName',
            ellipsis: true,
        },
        {
            title: '送修人',
            width: 100,
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: '',
            width: 20,
        },
        {
            title: '结算日期',
            width: 100,
            dataIndex: 'clearingAt',
            ellipsis: true,
            render: text => {
                return text === null ? "-" : moment(text).format("YYYY-MM-DD");
            }
        },
        {
            title: '结算金额',
            width: 100,
            dataIndex: 'total',
            align: "right",
            ellipsis: true,
        },
        {
            title: '',
            width: 50,
        },
        {
            title: '服务顾问',
            width: 120,
            dataIndex: 'employeeName',
            ellipsis: true,
        },
        {
            title: '经营主体',
            width: 120,
            dataIndex: 'companyName',
            ellipsis: true,
        },
    ]

    // 工时费用
    const projectColumns = [
        {
            title: '项目名称',
            dataIndex: 'projectName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '维修工段',
            dataIndex: 'sectionName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '维修班组',
            dataIndex: 'groupName',
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                if (!common.can("important.support.repair.update.dispatchTime")) {
                    return (<div>{text}</div>)
                }
                return (
                    <Select
                        value={record.groupId}
                        style={{width: 100}}
                        onChange={(val, option) => {
                            setProjects(projects.map((item, index) => {
                                if (record.id === item.id) {
                                    item.groupId = val
                                    item.groupName = option.children
                                    item.workerId = ''
                                    Object.keys(employees).forEach(key => {
                                        if (key === JSON.stringify(record.companyId)) {
                                            item.workers = employees[key].filter(employee => employee.groupId === val)
                                        }
                                    })
                                }
                                return item;
                            }))
                        }}
                    >
                        <Option value="">请选择</Option>
                        {groups.map(group => (
                            record.sectionId === group.parentId && (group.companyIds.indexOf(record.companyId) !== -1) ?
                                <Option key={group.id} value={group.id}>{group.name}</Option> : null
                        ))}
                    </Select>
                )
            }
        },
        {
            title: '主修人',
            dataIndex: 'workerName',
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (record.workers === undefined) {
                    return (<div>{text}</div>)
                }
                if (!common.can("important.support.repair.update.dispatchTime")) {
                    return (<div>{text}</div>)
                }
                return <div style={{display: "flex", justifyContent: "flex-start"}}>
                    <Select
                        style={{width: 100}}
                        value={record.workerId}
                        onChange={(val, option) => {
                            setProjects(projects.map((item, index) => {
                                if (record.id === item.id) {
                                    item.workerId = val
                                    item.workerName = option.children
                                    updateDispatchTime(item)
                                }
                                return item;
                            }))
                        }}>
                        <Option value="">请选择</Option>
                        {
                            record.workers.map(worker =>
                                <Option key={worker.id} value={worker.id}>{worker.name}</Option>)
                        }
                    </Select>
                    <Button icon={<CheckSquareOutlined/>}
                            hidden={!(record.batchAction !== undefined && record.workerId !== null && record.workerId !== undefined && record.workerId !== "")}
                            onClick={() => {
                                setProjects(projects.map((item) => {
                                    if (record.sectionId === item.sectionId) {
                                        item.groupId = record.groupId
                                        item.groupName = record.groupName
                                        item.workerId = record.workerId
                                    }
                                    return item
                                }))
                            }}>批量
                    </Button>
                </div>
            }
        },
        {
            title: '工时费',
            dataIndex: 'salePrice',
            align: "right",
            ellipsis: true,
            width: 80,
        },
        {
            title: '派工工时',
            dataIndex: 'dispatchTime',
            align: "right",
            ellipsis: true,
            width: 100,
            render: (text, record) => {
                if (!common.can("important.support.repair.update.dispatchTime")) {
                    return (<div>{text}</div>)
                }
                return (
                    <InputNumber
                        style={{width: '100%'}}
                        value={record.dispatchTime}
                        precision={2}
                        min={0}
                        onBlur={e => {
                            setProjects(projects.map((item) => {
                                if (record.id === item.id) {
                                    item.dispatchTime = e.target.value
                                    updateDispatchTime(item)
                                }
                                return item;
                            }))
                        }}
                    />
                )
            }
        },
        {
            title: '礼券抵扣',
            dataIndex: 'couponDiscount',
            align: 'right',
            width: 100,
            ellipsis: true,
        },
        {
            title: '会员卡折扣',
            dataIndex: 'cardDiscount',
            align: 'right',
            width: 100,
            ellipsis: true,
        },
        {
            title: '手动折扣',
            dataIndex: 'manualDiscount',
            align: 'right',
            width: 100,
            ellipsis: true,
        },
        {
            title: '实付金额',
            dataIndex: 'total',
            align: 'right',
            width: 100,
            ellipsis: true,
        },
        {
            title: '付费类型',
            dataIndex: 'chargeType',
            align: 'center',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return businessKinds.map(businessKind => businessKind.id === record.businessKind ?
                    chargeTypeAlias[businessKind.chargeType] :
                    null
                )
            }
        },
        {
            title: '业务类别',
            dataIndex: 'businessKind',
            ellipsis: true,
            width: 100,
            render: (text, record) => {
                return businessKinds.map(businessKind => businessKind.id === record.businessKind ? businessKind.name : null)
            }
        },
        {
            title: '备注',
            align: 'center',
            dataIndex: 'spec',
            width: 150,
            ellipsis: true,
        },
    ]

    // 维修材料列
    const productColumns = [
        {
            title: '材料名称',
            dataIndex: 'productName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '配件编号',
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true,
        },
        {
            title: '单位',
            dataIndex: 'unit',
            width: 80,
            ellipsis: true,
            render: (text) => (
                <span>{text || "个"}</span>
            )
        },
        {
            title: '仓库',
            dataIndex: 'warehouse',
            width: 100,
            ellipsis: true
        },
        {
            title: '单价',
            dataIndex: 'price',
            align: "right",
            ellipsis: true,
            width: 80,
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 80,
            align: "right",
            ellipsis: true,
        },
        {
            title: '金额',
            dataIndex: 'total',
            width: 80,
            align: "right",
            ellipsis: true,
        },
        {
            title: '付费类型',
            dataIndex: 'chargeType',
            align: 'center',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return businessKinds.map(businessKind => businessKind.id === record.businessKind ?
                    chargeTypeAlias[businessKind.chargeType] :
                    null
                )
            }
        },
        {
            title: '业务类别',
            dataIndex: 'businessKind',
            ellipsis: true,
            width: 100,
            render: (text, record) => {
                return businessKinds.map(businessKind => businessKind.id === record.businessKind ? businessKind.name : null)
            }
        },
        {
            title: '领料人',
            width: 100,
            render: (text, record) => {
                for (let i = 0; i < deliveryItem.length; i++) {
                    if (deliveryItem[i].productId === record.productId) {
                        return deliveryItem[i].employeeName
                    }
                }
            }
        }
    ]

    // 礼券销售列
    //  const couponColumns = [
    //      {
    //          title: '序号',
    //          dataIndex: 'id',
    //          width: 50,
    //          ellipsis: true,
    //          render: (text, record, index) => {
    //              return <span>{index + 1}</span>
    //          },
    //      },
    //      {
    //          title: '礼券名称',
    //          dataIndex: 'couponName',
    //          width: 100,
    //          ellipsis: true,
    //      },
    //      //  {
    //      //      title: '礼券类别',
    //      //      dataIndex: 'type',
    //      //      width: 80,
    //      //      ellipsis: true,
    //      //  },
    //      {
    //          title: '数量',
    //          dataIndex: 'quantity',
    //          width: 80,
    //          ellipsis: true,
    //      },
    //      //  {
    //      //      title: '业务类型',
    //      //      dataIndex: 'businessKind',
    //      //      width: 80,
    //      //      ellipsis: true,
    //      //  },
    //      //  {
    //      //      title: '财务类型',
    //      //      dataIndex: 'financeType',
    //      //      width: 80,
    //      //      ellipsis: true,
    //      //  },
    //      //  {
    //      //      title: '有效期',
    //      //      dataIndex: 'workTime',
    //      //      width: 80,
    //      //      ellipsis: true,
    //      //  },
    //
    //      {
    //          title: '单收款金额',
    //          dataIndex: 'price',
    //          width: 80,
    //          ellipsis: true,
    //      },
    //      {
    //          title: '实付金额',
    //          dataIndex: 'total',
    //          width: 100,
    //          align: 'center',
    //          ellipsis: true,
    //          //  render: (text, record) => (
    //          //      <InputNumber value={record.money} disabled precision={2}/>
    //          //  ),
    //      },
    //      {
    //          title: '单张礼券面额',
    //          dataIndex: 'oneAmount',
    //          width: 100,
    //          ellipsis: true,
    //      },
    //      {
    //          title: '单张折扣比例',
    //          dataIndex: 'oneDiscount',
    //          width: 100,
    //          ellipsis: true,
    //      },
    //      {
    //          title: '单张最大抵扣金额',
    //          dataIndex: 'oneMax',
    //          width: 100,
    //          ellipsis: true,
    //      },
    //
    //      //  {
    //      //      title: '使用规则',
    //      //      dataIndex: 'rule',
    //      //      width: 80,
    //      //      ellipsis: true,
    //      //  },
    //      //  {
    //      //      title: '使用范围',
    //      //      dataIndex: 'range',
    //      //      width: 80,
    //      //      ellipsis: true,
    //      //  },
    //      //  {
    //      //      title: '使用业务',
    //      //      dataIndex: 'useType',
    //      //      width: 80,
    //      //      ellipsis: true,
    //      //  },
    //  ]

    // 协修工单列
    const otherRepairItemColumns = [
        {
            title: '费用名称',
            dataIndex: 'name',
            width: 150,
            ellipsis: true,
        },
        {
            title: '数量',
            align: 'right',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
        },
        {
            title: '单价',
            align: 'right',
            dataIndex: 'deliveryPrice',
            width: 100,
            ellipsis: true,
        },
        {
            title: '销售单价',
            align: 'right',
            dataIndex: 'salePrice',
            width: 100,
            ellipsis: true,
        },
        {
            title: '受托方结算金额(入库价)',
            align: 'right',
            dataIndex: 'receiptPrice',
            width: 200,
            ellipsis: true,
        },
        {
            title: '客户成交价',
            align: 'right',
            dataIndex: 'total',
            width: 150,
            ellipsis: true,
        },
        {
            title: '',
            width: 30,
        },
        {
            title: '业务类别',
            dataIndex: 'businessKind',
            ellipsis: true,
            width: 100,
            render: (text, record) => {
                return businessKinds.map(businessKind => businessKind.id === record.businessKind ? businessKind.name : null)
            }
        },
        {
            title: '付费类型',
            dataIndex: 'chargeType',
            align: 'center',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return businessKinds.map(businessKind => businessKind.id === record.businessKind ?
                    chargeTypeAlias[businessKind.chargeType] :
                    null)
            }
        },
        {
            title: '备注',
            align: 'center',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
        },
    ]


    return (
        <React.Fragment>
            {
                searchAreaVisible ? <SearchArea>
                    <Form className="ant-advanced-search-form">
                        <Row gutter={24}>
                            <Col span={6}>
                                <Row>
                                    <Col span={24}>
                                        <FormItem label={"车牌号码"}>
                                            <Input placeholder={"请输入车牌号"} value={search.plate} onChange={(e) => {
                                                setSearch({...search, plate: e.target.value.trim()})
                                            }}/>
                                        </FormItem>
                                    </Col>
                                </Row>

                            </Col>
                            <Col span={6}>
                                <FormItem label={"客户名称"}>
                                    <Input placeholder={"请输入客户名称"} value={search.customerName} onChange={(e) => {
                                        setSearch({...search, customerName: e.target.value.trim()})
                                    }}/>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label={"VIN号码"} className='label-character-3'>
                                    <Input placeholder={"请输入VIN号码"} value={search.vin} onChange={(e) => {
                                        setSearch({...search, vin: e.target.value.trim()})
                                    }}/>
                                </FormItem>
                            </Col>
                            <Col span={6}>

                                <FormItem label={"维修单号"}>
                                    <Input placeholder={"请输入维修单号"} value={search.code} onChange={(e) => {
                                        setSearch({...search, code: e.target.value.trim()})
                                    }}/>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label={"工单类型"}>
                                    <Select value={search.repairCategoryId} onChange={(val) => {
                                        setSearch({...search, repairCategoryId: val})
                                    }}>
                                        <Option value="">全部</Option>
                                        {repairCategories.map(item =>
                                            <Option value={item.id} key={item.id}>{item.name}</Option>
                                        )}
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label={"服务顾问"}>
                                    <Input
                                        value={search.employeeName}
                                        readOnly
                                        autoComplete="off"
                                        placeholder={'请选择员工名称'}
                                        suffix={
                                            search.employeeName !== "" ?
                                                <CloseOutlined
                                                    onClick={() => {
                                                        setSearch({...search, employeeId: "", employeeName: ""})
                                                    }}/> : <span/>
                                        }
                                        addonAfter={
                                            <SelectOutlined
                                                onClick={() => {
                                                    setLookupEmployeeVisible(true)
                                                }}/>
                                        }
                                        onKeyPress={() => {
                                            setLookupEmployeeVisible(true)
                                        }}
                                    />
                                </FormItem>
                            </Col>
                            {/*<Col span={6}>*/}
                            {/*    <FormItem label={"维修项目"}>*/}

                            {/*    </FormItem>*/}
                            {/*</Col>*/}
                            <Col span={6}>
                                <FormItem label={"结算日期"} className='label-character-3'>
                                    <RangePicker
                                        value={[
                                            search.clearingAtStart ? moment(search.clearingAtStart) : null,
                                            search.clearingAtEnd ? moment(search.clearingAtEnd) : null
                                        ]}
                                        onChange={(val) => {
                                            val ? setSearch({
                                                ...search,
                                                clearingAtStart: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : null,
                                                clearingAtEnd: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : null,
                                            }) : setSearch({
                                                ...search,
                                                clearingAtStart: "",
                                                clearingAtEnd: "",
                                            })
                                        }}/>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label={"报修日期"}>
                                    <RangePicker
                                        value={[
                                            search.makeAtStart ? moment(search.makeAtStart) : null,
                                            search.makeAtEnd ? moment(search.makeAtEnd) : null
                                        ]}
                                        onChange={(val) => {
                                            val ? setSearch({
                                                ...search,
                                                makeAtStart: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : null,
                                                makeAtEnd: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : null,
                                            }) : setSearch({
                                                ...search,
                                                makeAtStart: "",
                                                makeAtEnd: "",
                                            })
                                        }}/>
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label={"经营主体"}>
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        value={search.ownerIds}
                                        style={{width: '100%'}}
                                        placeholder="请选择经营主体"
                                        onChange={(val => {
                                            if (val.includes(0)) {
                                                search.ownerIds = companies.map(item => item.companyId)
                                                setSearch({...search})
                                            } else {
                                                search.ownerIds = val
                                                setSearch({...search})
                                            }
                                        })}
                                    >
                                        <Option value={0}>全部</Option>
                                        {
                                            companies.map(item => {
                                                return (
                                                    <Option key={item.companyId}
                                                            value={item.companyId}>{item.companyName}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label={"备用VIN"} className='label-character-3'>
                                    <Input placeholder={"请输入备用VIN"} value={search.spareFrameNumber}
                                           onChange={(e) => {
                                               setSearch({...search, spareFrameNumber: e.target.value.trim()})
                                           }}/>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <div className={'search-btns'}>
                                    <FormItem className="inline-search-btns">
                                        <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                                            setQuery(search)
                                            setPagination({...pagination, page: 1})
                                        }}>搜索</Button>
                                        <Button icon={<ReloadOutlined/>} onClick={() => {
                                            setPagination({...pagination, page: 1})
                                            setSearch({...initialSearch, plate: ""})
                                            setQuery(initialSearch)
                                        }}>清空</Button>
                                    </FormItem>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </SearchArea> : ""
            }


            <Table rowSelection={rowSelection}
                   columns={columns}
                   pagination={false}
                   scroll={{x: '100%'}}
                   rowKey={record => record.id}
                   dataSource={repairs}
                   onRow={record => {
                       return {
                           onClick: () => {
                               rowSelection.onChange([record.id], [record])
                           }
                       };
                   }}
            />
            <PageBottom className={"page-bottom-pagination"}
                        pagination={
                            <Pagination
                                pageSizeOptions={['6', '20', '40', '80']}
                                onChange={handlePageChange}
                                onShowSizeChange={handlePageChange}
                                showQuickJumper={true}
                                showTotal={total => `共${total}条`}
                                total={total}
                                defaultCurrent={pagination.page}
                                current={pagination.page}
                                showSizeChanger
                                defaultPageSize={pagination.limit}
                            />
                        }>
            </PageBottom>

            <Tabs defaultActiveKey={showTabPaneArr[0]}>
                {showTabPaneArr.includes("1") &&
                    <TabPane tab="工时费用" key="1">
                        <Table
                            scroll={{x: "100%"}}
                            rowKey={record => record.id}
                            pagination={false}
                            columns={projectColumns}
                            dataSource={projects}
                            summary={(currentData) => {
                                let salePrice = "0.00"
                                let total = "0.00"
                                let cardDiscount = "0.00"
                                let couponDiscount = "0.00"
                                let dispatchTime = "0.00"// 派工工时
                                let manualDiscount = "0.00"
                                currentData.forEach(item => {
                                    salePrice = common.numberHalfUp(new BigNumber(salePrice).plus(item.salePrice).toString(), 2)
                                    dispatchTime = common.numberHalfUp(new BigNumber(dispatchTime).plus(item.dispatchTime).toString(), 2)
                                    total = common.numberHalfUp(new BigNumber(total).plus(item.total).toString(), 2)
                                    cardDiscount = common.numberHalfUp(new BigNumber(cardDiscount).plus(item.cardDiscount).toString(), 2)
                                    couponDiscount = common.numberHalfUp(new BigNumber(couponDiscount).plus(item.couponDiscount).toString(), 2)
                                    manualDiscount = common.numberHalfUp(new BigNumber(manualDiscount).plus(item.manualDiscount).toString(), 2)
                                })
                                return (
                                    <tr>
                                        <th>合计</th>
                                        <td colSpan={3}/>
                                        <td style={{textAlign: "right"}}>{salePrice}</td>
                                        <td style={{textAlign: "right"}}>{dispatchTime}</td>
                                        <td style={{textAlign: "right"}}>{couponDiscount}</td>
                                        <td style={{textAlign: "right"}}>{cardDiscount}</td>
                                        <td style={{textAlign: "right"}}>{manualDiscount}</td>
                                        <td style={{textAlign: "right"}}>{total}</td>
                                        <td colSpan={3}/>
                                    </tr>
                                )
                            }}
                        />
                    </TabPane>}
                {showTabPaneArr.includes("2") &&
                    <TabPane tab="维修材料" key="2">
                        <Table scroll={{x: "100%", y: "400px"}}
                               rowKey={record => record.id}
                               pagination={false}
                               columns={productColumns}
                               dataSource={products}
                               summary={(currentData) => {
                                   let price = "0.00"
                                   let quantity = "0.00"
                                   let total = "0.00"
                                   currentData.forEach(item => {
                                       price = common.numberHalfUp(new BigNumber(price).plus(item.price).toString(), 2)
                                       quantity = common.numberHalfUp(new BigNumber(quantity).plus(item.quantity).toString(), 2)
                                       total = common.numberHalfUp(new BigNumber(total).plus(item.total).toString(), 2)
                                   })
                                   return (
                                       <tr>
                                           <th>合计</th>
                                           <td colSpan={3}/>
                                           <td style={{textAlign: "right"}}>{price}</td>
                                           <td style={{textAlign: "right"}}>{quantity}</td>
                                           <td style={{textAlign: "right"}}>{total}</td>
                                           <td colSpan={3}/>
                                       </tr>
                                   )
                               }}
                        />
                    </TabPane>}
                {showTabPaneArr.includes("3") &&
                    <TabPane tab="协修工单" key="3">
                        <Table scroll={{x: "100%"}}
                               rowKey={record => record.id}
                               pagination={false}
                               columns={otherRepairItemColumns}
                               dataSource={otherRepairItems}
                               summary={(currentData) => {
                                   let quantity = "0.00"
                                   let deliveryPrice = "0.00"
                                   let salePrice = "0.00"
                                   let receiptPrice = "0.00"
                                   let total = "0.00"
                                   currentData.forEach(item => {
                                       quantity = common.numberHalfUp(new BigNumber(quantity).plus(item.quantity).toString(), 2)
                                       deliveryPrice = common.numberHalfUp(new BigNumber(deliveryPrice).plus(item.deliveryPrice).toString(), 2)
                                       salePrice = common.numberHalfUp(new BigNumber(salePrice).plus(item.salePrice).toString(), 2)
                                       receiptPrice = common.numberHalfUp(new BigNumber(receiptPrice).plus(item.receiptPrice).toString(), 2)
                                       total = common.numberHalfUp(new BigNumber(total).plus(item.total).toString(), 2)
                                   })
                                   return (
                                       <tr>
                                           <th>合计</th>
                                           <td style={{textAlign: "right"}}>{quantity}</td>
                                           <td style={{textAlign: "right"}}>{deliveryPrice}</td>
                                           <td style={{textAlign: "right"}}>{salePrice}</td>
                                           <td style={{textAlign: "right"}}>{receiptPrice}</td>
                                           <td style={{textAlign: "right"}}>{total}</td>
                                           <td colSpan={3}/>
                                       </tr>
                                   )
                               }}
                        />
                    </TabPane>}
                {/*<TabPane tab="礼券销售" key="4">*/}
                {/*    <Table scroll={{x: "100%"}}*/}
                {/*           rowKey={record => record.id}*/}
                {/*           pagination={false}*/}
                {/*           columns={couponColumns}*/}
                {/*           dataSource={coupons}*/}
                {/*    />*/}
                {/*</TabPane>*/}
                {showTabPaneArr.includes("5") &&
                    <TabPane tab="其它费用" key="5">
                        <Table scroll={{x: "100%"}}
                               rowKey={record => record.id}
                               pagination={false}
                               columns={otherRepairItemColumns}
                               dataSource={[]}
                        />
                    </TabPane>}
            </Tabs>

            <div style={{display: "flex", justifyContent: "space-between", alignItems: "flex-end", marginTop: "10px"}}>
                <div>
                    <Button icon={<HistoryOutlined/>} onClick={() => {
                        setOldRepairItemListVisible(true)
                    }}>原始维修记录</Button>
                </div>
                <div>
                    <div/>
                </div>
            </div>

            <Modal
                title={'原始维修记录'}
                visible={oldRepairItemListVisible}
                maskClosable={false}
                destroyOnClose={true}
                footer={null} width={1000}
                onCancel={() => setOldRepairItemListVisible(false)}
            >
                <OldRepairItemList plate={plate}/>
            </Modal>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择员工"
                    visible={lookupEmployeeVisible}
                    width={1000}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={() => {
                        setLookupEmployeeVisible(false)
                    }}>

                    <LookupEmployee
                        companyId={common.getUser().company.id}
                        onOk={(value) => {
                            setLookupEmployeeVisible(false)
                            setSearch({...search, employeeId: value.id, employeeName: value.name})
                        }}
                        isMultiple={false}  // 如果想单选，传false
                    />
                </Modal>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    title="修改里程"
                    visible={mileageChangeVisible}
                    width={1000}
                    destroyOnClose={true}
                    onOk={() => {
                        updateMileage()
                    }}
                    onCancel={() => {
                        setMileageChangeVisible(false)
                    }}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <FormItem label={"原始里程"}>
                                <span className="ant-form-text"> {originMileage}</span>
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label={"新的里程"}>
                                <InputNumber precision={0} min={0} value={repair.mileage} onChange={value => {
                                    repairs.forEach(item => {
                                        if (item.id === repair.id) {
                                            repair.mileage = value
                                            item.mileage = value
                                        }
                                    })
                                    setRepair({...repair})
                                    setRepairs([...repairs])
                                }}/>
                            </FormItem>
                        </Col>
                    </Row>
                </Modal>
            </div>

        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RepairHistory)

