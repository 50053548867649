import axios from 'axios';
import qs from 'qs';
import store from "./store";
import {Modal, message} from 'antd';

import {baseApi, artifactId} from "../config-local";
import BigNumber from "bignumber.js";


let common = {}

common.redirectToLogin = function () {
    window.location = '/login'
}

let tokenKey = `${artifactId}-token`

common.setToken = function (token) {
    if (token) {
        window.localStorage.setItem(tokenKey, token)
    } else {
        window.localStorage.removeItem(tokenKey)
    }
}

common.getToken = function () {
    let token = window.localStorage.getItem(tokenKey)
    return token == null ? '' : token
}


let taskKey = `${artifactId}-task`

common.setTasks = function (tasks) {
    if (tasks) {
        window.localStorage.setItem(taskKey, tasks)
    } else {
        window.localStorage.removeItem(taskKey)
    }
}

common.getTasks = function () {
    let tasks = window.localStorage.getItem(taskKey)
    return tasks ? tasks : []
}


/*
nickname: "",
avatar: "",
tenant: {name: ""},
company: {id: "", name: ""},
brand: {id: "", name: ""},
*/
common.getUser = function () {
    return store.getState().user
}

common.getBaseApiUrl = function () {
    return baseApi
}

common.getApiUrl = function (api, param = {}) {

    if (api.startsWith('http')) {
        return api
    }

    let queryArr = []
    for (let k in param) {
        queryArr.push(encodeURIComponent(k) + '=' + encodeURIComponent(param[k]))
    }

    let queryStr = ''
    if (queryArr.length > 0) {
        const mark = api.indexOf('?') >= 0 ? '&' : '?'
        queryStr = mark + queryArr.join('&')
    }

    return common.getBaseApiUrl() + api + queryStr
}

common.ajax = function (method, api, data, config = {}) {

    data = data || {}

    const isGet = method.toLowerCase() === 'get'

    const configDefault = {
        'contentType': 'application/json', // application/x-www-form-urlencoded、multipart/form-data、application/json
        'timeout': 20000,                  // 调用api超时时间为20秒
        'displayError': true,              // 调用api出错时，是否显示错误消息
        'useToken': true,                  // api是否需要使用token。如果需要token而本地没有token时，将重定向到登录页
        'interceptInvalidToken': true,     // api返回token无效时，是否拦截。如果拦截，将重定向到登录页
        'responseType': 'json',             // 返回数据格式 json、text、blob
        'filename': 'temp.xlsx',            // 下载文件名
    }

    config = Object.assign(configDefault, config)
    if (!isGet && config['contentType'].toLowerCase() === 'application/x-www-form-urlencoded') {
        data = qs.stringify(isGet ? null : data)
    }

    let headers = {
        'Content-Type': config['contentType']
    }

    if (config.useToken) {

        headers['token'] = common.getToken()
        if (headers['token'].length === 0) {

            common.redirectToLogin()

            return new Promise((resolve, reject) => {
                reject({code: 'MISS_TOKEN', message: "请登录", data: null})
            })
        }
    }

    return new Promise((resolve, reject) => {
        axios({
            method: method,
            url: common.getApiUrl(api, isGet ? data : {}),
            data: data,
            headers: headers,
            timeout: config.timeout,
            responseType: config['responseType']
        }).then((response) => {

            // 二进制直接下载
            if (config['responseType'] === 'blob') {
                const filename = config['filename']
                const blob = new Blob([response.data], {type: 'application/octet-stream'})
                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    window.navigator.msSaveBlob(blob, decodeURI(filename))
                } else {
                    const url = window.URL.createObjectURL(blob)
                    const a = document.createElement('a')
                    a.style.display = 'none'
                    a.href = url
                    a.setAttribute('download', decodeURI(filename))
                    if (typeof a.download === 'undefined') {
                        a.setAttribute('target', '_blank')
                    }
                    document.body.appendChild(a)
                    a.click()
                    document.body.removeChild(a)
                    window.URL.revokeObjectURL(url)
                }
                resolve(response.data.data)
                return;
            }

            //response.data:  {code:"SUCCESS", message:"xxx", data: "xxx"}
            if (response.data.code === 'SUCCESS') {
                resolve(response.data.data)
                return
            }

            switch (response.data.code) {
                case 'INVALID_TOKEN':
                    if (config.interceptInvalidToken) {

                        common.setToken(null)

                        if (config.displayError) {
                            common.toast('请登录')
                        }

                        common.redirectToLogin()
                    }
                    break

                default:
                    if (config.displayError) {
                        common.alert(response.data.message)
                    }
            }

            reject(response.data)

        }).catch((error) => {
            config.displayError && common.alert("" + error)
            reject({code: 'ERROR', message: '' + error, data: null})
        })

    })
}


common.base64Encode = function (input) {
    let _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    let chr1, chr2, chr3, enc1, enc2, enc3, enc4, i = 0, output = '', utftext = '';
    input = input.replace(/\r\n/g, "\n");
    for (let n = 0; n < input.length; n++) {
        let c = input.charCodeAt(n);
        if (c < 128) {
            utftext += String.fromCharCode(c);
        } else if ((c > 127) && (c < 2048)) {
            utftext += String.fromCharCode((c >> 6) | 192);
            utftext += String.fromCharCode((c & 63) | 128);
        } else {
            utftext += String.fromCharCode((c >> 12) | 224);
            utftext += String.fromCharCode(((c >> 6) & 63) | 128);
            utftext += String.fromCharCode((c & 63) | 128);
        }

    }
    while (i < utftext.length) {
        chr1 = utftext.charCodeAt(i++);
        chr2 = utftext.charCodeAt(i++);
        chr3 = utftext.charCodeAt(i++);
        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;
        if (isNaN(chr2)) {
            enc3 = enc4 = 64;
        } else if (isNaN(chr3)) {
            enc4 = 64;
        }
        output = output +
            _keyStr.charAt(enc1) + _keyStr.charAt(enc2) +
            _keyStr.charAt(enc3) + _keyStr.charAt(enc4);
    }
    return output;
}

common.isWechat = function () {
    var ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('micromessenger') >= 0;
}


common.alert = function (content, callback, title) {
    title = title || '提示'
    callback = callback || function () {
    }

    Modal.info({
        title: title,
        content: content,
        onOk() {
            callback()
        },
    });

    // window.popup.alert(content, function () {
    //     callback()
    // });

}

common.toast = function (content, callback, duration) {
    duration = duration || 2 // 单位：秒

    callback = callback || function () {
    }

    message.info(content, duration);

    setTimeout(() => {
        callback()
    }, duration * 1000);

    // const modal = Modal.success({
    //     // title: 'This is a notification message',
    //     content: content,
    // });

    // window.popup.cute(content, duration * 1000, callback);
}

// 格式化手机号
common.maskPhone = function (phone) {
    if (!phone || phone.length !== 11) {
        return "";
    }
    return phone.slice(0, 3) + '****' + phone.slice(7);
}

common.loadingStart = function (message) {
    message = message || '加载中...'

    // window.popup.loading(true);

    let mask = document.createElement("div")
    mask.setAttribute("id", "myloadingmask")
    mask.style.position = "absolute"
    // mask.style.backgroundColor = "#eee"
    mask.style.width = "100%"
    mask.style.height = "100%"
    mask.style.zIndex = 999
    mask.style.top = "0px"
    mask.style.left = "0px"
    document.getElementsByTagName("body")[0].appendChild(mask)

    let container = document.createElement("div")
    container.setAttribute("id", "myloading")
    container.setAttribute("class", "container")

    let loading = document.createElement("div")
    loading.setAttribute("class", "loading")
    container.appendChild(loading)

    let style = document.createElement("style")
    style.innerHTML = `
     .container {
        z-index: 10000;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .loading {
        width: 60px;
        height: 60px;
        border-radius: 100%;
        border: 5px #aaa solid;
        border-right-color: #1890ff;
        animation: loading 1s linear infinite;
    }

    @keyframes loading {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }`

    container.appendChild(style)
    document.getElementsByTagName("body")[0].appendChild(container)

}

common.loadingStop = function () {
    // window.popup.loading(false);

    let myloading = document.getElementById("myloading")
    if (myloading) {
        document.getElementsByTagName("body")[0].removeChild(myloading)
    }

    let myloadingmask = document.getElementById("myloadingmask")
    if (myloadingmask) {
        document.getElementsByTagName("body")[0].removeChild(myloadingmask)
    }
}

common.confirm = function (message, okCallback, cancelCallback, title) {
    title = title || '提示'

    okCallback = okCallback || function () {
    }
    cancelCallback = cancelCallback || function () {
    }

    Modal.confirm({
        title: title,
        content: message,
        onOk() {
            okCallback()
        },
        onCancel() {
            cancelCallback()
        },
    });

    // window.popup.confirm(message, okCallback, cancelCallback);
}


common.print = function (content) {
    const printWindow = window.document.createElement('iframe');
    window.document.getElementsByTagName("body")[0].appendChild(printWindow)
    printWindow.style.display = "none"
    printWindow.contentDocument.body.innerHTML = content

    //等图片载加完毕 todo 需要优化
    setTimeout(() => {
        printWindow.contentWindow.print();
        window.document.getElementsByTagName("body")[0].removeChild(printWindow)
    }, 1000)
}


/**
 * 填充数组到指定长度
 */
common.arrayPad = (arr, rows, empty = {}) => {
    if (arr.length >= rows) {
        return arr
    }
    let num = rows - arr.length
    for (let i = 0; i < num; i++) {
        arr.push({...empty, id: -1 - i}) //空数据，id为负数，方便识别
    }
    return arr
}

/**
 * 保留指定精度的小数位，超出部份直接舍去
 * @param {string} v
 * @param {int} scale
 * @return string
 */
common.numberCut = function (v, scale) {
    if (scale < 0) {
        throw new Error('scale invalid');
    }

    v = String(v);
    var dot = v.indexOf('.')

    // 确保小数点后有足够位数的"0"
    var append = ''
    for (var i = 0; i < scale; i++) {
        append += '0'
    }

    if (dot === -1) {          // "123" => "123.00"
        dot = v.length;
        v = v + '.' + append;
    } else {                   // "0.123" => "0.12300"
        v = v + append;
    }

    if (scale === 0) {
        return v.substr(0, dot)
    }

    return v.substr(0, dot + 1 + scale);
}

/**
 * 四舍五入保留2位小数
 * @param {string} v 金额
 * @param {int} scale  小数点后位数
 * @return string
 */
common.numberHalfUp = function (v, scale) {
    if (scale < 0) {
        throw new Error('scale invalid');
    }

    //按四舍五入精确到小数点后2位的值
    let fmt = {
        prefix: '',
        decimalSeparator: '.',
        groupSeparator: '',
        groupSize: 3,
        secondaryGroupSize: 0,
        fractionGroupSeparator: ' ',
        fractionGroupSize: 0,
        suffix: ''
    }

    //返回 格式化之后的数字(String类型)
    return new BigNumber(v).toFormat(scale, BigNumber.ROUND_HALF_UP, fmt); // 4.四舍五入
}

/**
 * 金额保留两位小数，并加上千位分隔符（25888.18 => 25,888.18）
 *
 * @param {string} number
 * @returns string
 */
common.numberFormat = function (number) {
    if (number === undefined || number === null || number === '') {
        return "0.00"
    }
    if (isNaN(number)) {
        return "0.00"
    }
    // 保留两位小数
    number = common.numberCut(number, 2);

    // 加上千位分隔符
    number = number.toString().replace(/\d+/, function (n) {
        return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
            return $1 + ",";
        });
    });

    return number;
}

common.consoleLog = function () {
    if (window.location.hostname === '127.0.0.1' || window.location.hostname === 'localhost') {
        let list = Array.prototype.slice.call(arguments);
        console.log(...list);
    }
}

// 检查是否有某个权限
common.can = function (task) {
    return store.getState().user.type === 'tenant'
        || store.getState().permission.tasks.includes(task)
        || (common.getTasks().length > 0 && common.getTasks().split(',').includes(task))
}

common.isShowTenant = function () {

    if (store.getState().user.type === 'tenant') {
        return true
    }

    if (common.getTasks().length === 0) {
        return false
    }

    let arr = common.getTasks().split(',')
    try {
        arr.forEach((item) => {
            if (item.substring(0, 6) === 'admin.') {
                throw new Error("ending");//报错，就跳出循环
            }
        })
    } catch (e) {
        return true
    }

    return false;
}

common.moneyToChinese = function (money) {
    //负数处理
    var negative = "";
    if (money < 0) {
        money = Math.abs(money);
        negative = "负";
    }
    //汉字的数字
    var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');
    //基本单位
    var cnIntRadice = new Array('', '拾', '佰', '仟');
    //对应整数部分扩展单位
    var cnIntUnits = new Array('', '万', '亿', '兆');
    //对应小数部分单位
    var cnDecUnits = new Array('角', '分', '毫', '厘');
    //整数金额时后面跟的字符
    var cnInteger = '整';
    //整型完以后的单位
    var cnIntLast = '元';
    //最大处理的数字
    var maxNum = 999999999999999.9999;
    //金额整数部分
    var integerNum;
    //金额小数部分
    var decimalNum;
    //输出的中文金额字符串
    var chineseStr = '';
    //分离金额后用的数组，预定义
    var parts;
    if (money == '') {
        return '';
    }
    money = parseFloat(money);
    if (money >= maxNum) {
        //超出最大处理数字
        return '';
    }
    if (money == 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
    }
    //转换为字符串
    money = money.toString();
    if (money.indexOf('.') == -1) {
        integerNum = money;
        decimalNum = '';
    } else {
        parts = money.split('.');
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
    }
    //获取整型部分转换
    if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0;
        var IntLen = integerNum.length;
        for (var i = 0; i < IntLen; i++) {
            var n = integerNum.substr(i, 1);
            var p = IntLen - i - 1;
            var q = p / 4;
            var m = p % 4;
            if (n == '0') {
                zeroCount++;
            } else {
                if (zeroCount > 0) {
                    chineseStr += cnNums[0];
                }
                //归零
                zeroCount = 0;
                chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
            }
            if (m == 0 && zeroCount < 4) {
                chineseStr += cnIntUnits[q];
            }
        }
        chineseStr += cnIntLast;
    }
    //小数部分
    if (decimalNum != '') {
        var decLen = decimalNum.length;
        for (var i = 0; i < decLen; i++) {
            var n = decimalNum.substr(i, 1);
            if (n != '0') {
                chineseStr += cnNums[Number(n)] + cnDecUnits[i];
            }
        }
    }
    if (chineseStr == '') {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
    } else if (decimalNum == '') {
        chineseStr += cnInteger;
    }
    return negative + chineseStr;

}


// 分组 返回的是对象
common.groupBy = (list, fn) => {
    const groups = {};
    list.forEach(function (o) {
        const group = JSON.stringify(fn(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
    });
    return groups;
}

common.trim = function (str) {
    return str.replace(/(^\s*)|(\s*$)/g, "");
}

export default common
