import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from "react-router-dom";
import {
    CheckOutlined,
    CheckSquareOutlined,
    CloseOutlined,
    CrownOutlined,
    EditOutlined, HistoryOutlined,
    PrinterOutlined,
    QuestionCircleOutlined,
    RollbackOutlined,
    SelectOutlined,
    CopyOutlined
} from '@ant-design/icons';
import {Button, Col, Form, Input, InputNumber, Modal, Row, Select, Table, Tabs, Tag, Tooltip,} from 'antd';
import common from "../../../utils/common";
import BigNumber from "bignumber.js";
import PageTop from "../../layout/PageTop";
import CouponPlan from "../../coupon/LookupPlan";
import TicketRecover from "../../coupon/TicketRecover";
import InsuranceClaim from "../ClaimDiscountForm";
import FactoryClaim from "../ClaimDiscountForm";
import CustomerClaim from "../ClaimDiscountForm";
import LookupCustomerRecord from "../../passport/LookupCustomerRecord";
import CardDiscountDetail from "../CardDiscountDetail";
import ReviewForm from "../ReviewDiscountForm";
import PrintPrepay from "../PrintPrepay";
import Search from "../../../utils/search";
import {SupportImages, Repair, SupportSetting} from "../config";
import RemindersModal from "../RepairForm/remindersModal";
import "./index.css";
import {CustomizationConstant} from "../../Customization/config";

const FormItem = Form.Item
const {TabPane} = Tabs
const {Option} = Select
const category1Name = {1: '销售礼券-预收券', 2: '优惠/折扣券-明折', 3: '增费礼券-增加费用'}
const category2Name = {1: '通用券', 2: '工时券', 3: '材料券(含配件及用品)', 4: '代办业务抵扣券', 5: '用品券'}
//  1.工单编辑   2.等待派工  3.维修中（配件出库，取消出库）4.已竣工（取消竣工）
//  5.订单预结（取消预结） 6.订单结算（取消结算）7.订单收款 8.已退单
const REPAIR_STATUS_CREATING = 1;
const REPAIR_STATUS_WAITING = 2;
const REPAIR_STATUS_SUPPORTING = 3;
const REPAIR_STATUS_SUPPORTED = 4;
const REPAIR_STATUS_PREPAID = 5;
const REPAIR_STATUS_SETTLED = 6;
const REPAIR_STATUS_RECEIVED = 7;
const REPAIR_STATUS_BACK = 8;

let repairStatusTypeAlias = {
    [REPAIR_STATUS_CREATING]: '工单编辑',
    [REPAIR_STATUS_WAITING]: '等待派工',
    [REPAIR_STATUS_SUPPORTING]: '维修中',
    [REPAIR_STATUS_SUPPORTED]: '已竣工',
    [REPAIR_STATUS_PREPAID]: '订单预结',
    [REPAIR_STATUS_SETTLED]: '订单结算',
    [REPAIR_STATUS_RECEIVED]: '订单收款',
    [REPAIR_STATUS_BACK]: '已退单',
}

// 收费区分 1:客户自费、2:保险维修、3:厂家索赔、4:委托费用
const CHARGE_TYPE_CUSTOMER = 1
const CHARGE_TYPE_INSURE = 2
const CHARGE_TYPE_FACTORY = 3

let chargeTypeAlias = {
    [CHARGE_TYPE_CUSTOMER]: '客户自费',
    [CHARGE_TYPE_INSURE]: '保险维修',
    [CHARGE_TYPE_FACTORY]: '厂家索赔',
}

//  工单类型
const REPAIR_TYPE_NORMAL = "1" // 普通工单
const REPAIR_TYPE_DELEGATE = "2" // 内协工单
const repairTypeAlias = {
    [REPAIR_TYPE_NORMAL]: '普通工单',
    [REPAIR_TYPE_DELEGATE]: '内协工单'
}

//  维修明细类型type： 1.工时费用 2.预估材料 3.维修材料 4.礼券 5.委外维修项目 6.委外维修材料 7.委内维修项目 8.委内维修材料
const REPAIR_ITEM_TYPE_PROJECT = '1';
const REPAIR_ITEM_TYPE_PREDICT_PRODUCT = '2';
const REPAIR_ITEM_TYPE_PRODUCT = '3';
const REPAIR_ITEM_TYPE_COUPON = '4';
const REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT = '5';
const REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT = '6';
const REPAIR_ITEM_TYPE_DELEGATE_PROJECT = "7";
const REPAIR_ITEM_TYPE_DELEGATE_PRODUCT = "8";
const repairItemTypeAlias = {
    [REPAIR_ITEM_TYPE_PROJECT]: '工时费用',
    [REPAIR_ITEM_TYPE_PREDICT_PRODUCT]: '预估材料',
    [REPAIR_ITEM_TYPE_PRODUCT]: '维修材料',
    [REPAIR_ITEM_TYPE_COUPON]: '礼券',
    [REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT]: '委外维修-工时',
    [REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT]: '委外维修-材料',
    [REPAIR_ITEM_TYPE_DELEGATE_PROJECT]: '委内维修-工时',
    [REPAIR_ITEM_TYPE_DELEGATE_PRODUCT]: '委内维修-材料',
}

const CLAIM_TYPE_INSURANCE = 1 // 理赔单
const CLAIM_TYPE_FACTORY = 2 // 索赔单
const CLAIM_TYPE_CUSTOMER = 3 // 费用确认（客户的手动折扣+挂帐）


//  维修明细付费类型  1.客户自费   2.保险理赔-保险公司 3.保险理赔-第三方保险公司  4.保险理赔-客户自费部分 5.厂商理赔 6.委外方 7.委内方 8.挂帐
const PAY_TYPE_CUSTOMER = 1;
const PAY_TYPE_INSURANCE_COMPANY = 2;
// const PAY_TYPE_INSURANCE_OTHER = 3;
const PAY_TYPE_INSURANCE_CUSTOMER = 4;
const PAY_TYPE_FACTORY = 5;
const PAY_TYPE_OUTSOURCE = 6;
const PAY_TYPE_DELEGATE = 7;
const PAY_TYPE_ACCOUNT = 8;

// 1.未付款 2.已付款
//  const PREPAY_STATUS_WAITING = 1;
//  const PREPAY_STATUS_PAID = 2;

// 1.启用 2.禁用
const STATUS_ENABLE = 1

//  预结算的时候可以修改工时费 0.否  1.是
const UPDATE_PROJECT_SALE_PRICE_AT_PREPAY_NO = "0";
const UPDATE_PROJECT_SALE_PRICE_AT_PREPAY_YES = "1";

// 预结算时采用（库房出库价）做为客户自费的销售单价 0.否  1.是
// const DELIVERY_PRICE_IS_SALE_PRICE_BY_CUSTOMER_NO = "0";
const DELIVERY_PRICE_IS_SALE_PRICE_BY_CUSTOMER_YES = "1";

// 预结算时采用（库房出库价）做为厂家索赔的销售单价 0.否  1.是
const DELIVERY_PRICE_IS_SALE_PRICE_BY_FACTORY_NO = "0";
const DELIVERY_PRICE_IS_SALE_PRICE_BY_FACTORY_YES = "1";

// 预结算时采用（库房出库价）做为保险理赔的销售单价 0.否  1.是
// const DELIVERY_PRICE_IS_SALE_PRICE_BY_INSURANCE_NO = "0";
const DELIVERY_PRICE_IS_SALE_PRICE_BY_INSURANCE_YES = "1";

const initFinance = {
    code: "",
}

function PrepayForm(props) {

    // repairId 工单id
    // scene(String类型) 场景 look:查看, prepay:预结算操作
    let {repairId, scene = "look"} = props
    let history = useHistory()
    const [form] = Form.useForm();
    const emptyInput = useRef();// 空标签

    // 初始化保险理赔单
    let initInsuranceClaim = {
        id: "",
        repairId: "",
        type: "",
        partnerId: "",
        partnerId2: "",
        partner1: "",
        partner2: "",
        partnerEmployee: "",
        receivableProjectMoney: 0,
        receivableProductMoney: 0,
        receivableTotal: 0,
        actualProjectMoney: 0,
        actualProductMoney: 0,
        discountProjectMoney: 0,
        discountProjectRate: 0,
        discountProductMoney: 0,
        discountProductRate: 0,
        discountTotalMoney: 0,
        discountTotalRate: 0,
        actualTotal: 0,
        customerPayRate: 0,
        customerPayTotal: 0,
        partnerPayTotal: 0,
        partnerPayTotal2: 0,
        dms: "",
        status: 1,
        spec: "",
        accountPartnerMoney: "0.00", //挂帐金额
        accountPartnerMoneyRate: "0.00", //挂帐金额比例
        categoryId: "", //挂帐类别
        partnerName: "",
        name: "",
        phone: "",
        agreementCode: "",
        startAt: "",
        endAt: "",
    }

    // 初始化厂家索赔单
    let initFactoryClaim = {
        id: "",
        repairId: "",
        type: "",
        partnerId: "",
        partnerId2: "",
        partner1: "",
        partner2: "",
        partnerEmployee: "",
        receivableProjectMoney: 0,
        receivableProductMoney: 0,
        receivableTotal: 0,
        actualProjectMoney: 0,
        actualProductMoney: 0,
        discountProjectMoney: 0,
        discountProjectRate: 0,
        discountProductMoney: 0,
        discountProductRate: 0,
        discountTotalMoney: 0,
        discountTotalRate: 0,
        actualTotal: 0,
        customerPayRate: 0,
        customerPayTotal: 0,
        partnerPayTotal: 0,
        partnerPayTotal2: 0,
        dms: "",
        status: 1,
        spec: "",
        accountPartnerMoney: "0.00", //挂帐金额
        accountPartnerMoneyRate: "0.00", //挂帐金额比例
        categoryId: "", //挂帐类别
        partnerName: "",
        name: "",
        phone: "",
        agreementCode: "",
        startAt: "",
        endAt: "",
    }

    //客户费用确认单
    let initCustomerClaim = {
        id: "",
        repairId: "",
        customerName: "",
        employeeName: "",
        type: "",
        partnerId: "",
        receivableProjectMoney: 0,
        receivableProductMoney: 0,
        receivableTotal: 0,
        actualProjectMoney: 0,
        actualProductMoney: 0,
        discountProjectMoney: 0,
        discountProjectRate: 0,
        discountProductMoney: 0,
        discountProductRate: 0,
        discountTotalMoney: 0,
        discountTotalRate: 0,
        actualTotal: 0,
        customerPayRate: 0,
        customerPayTotal: 0,
        partnerPayTotal: 0,
        partnerPayTotal2: 0,
        accountPartnerMoney: "0.00", //挂帐金额
        accountPartnerMoneyRate: "0.00", //挂帐金额比例
        categoryId: "", //挂帐类别
        status: 1,
        spec: "",
        partner1: "",
        partner2: "",
        partnerName: "",
        name: "",
        phone: "",
        agreementCode: "",
        startAt: "",
        endAt: "",
    }

    // 初始化维修工单
    let initRepair = {
        id: "",
        customerId: "",
        customerName: "",
        clearCreator: "",
        repairItems: [],
        consignorCompanyId: "",
        consignorCompanyName: "",
        partnerId: "",
        partnerName: "",
        factoryId: "",
        factoryName: "",
        vehicleSpec: "",
        vehicleSelfCode: "",
        vehicleValidUntil: "",
        vehicleIsWarranty: "",
        vehicleContractCompanyName: "",
    }

    // 初始化会员等级
    let initialVipLevel = {
        id: "",
        chargeFirst: "",  // 首次充值
        chargeNext: "", // 下次充值
        companyIds: [], // 适用公司
        companyNames: "", // 适用公司
        discountProduct: "",     // 材料折扣
        discountProject: "",      // 工时折扣
        isProduceOfCustomer: 1,    // 自费付费是否产生消费积分 1产生 2不产生
        isProduceOfFactory: 2,    // 索赔付费是否产生消费积分 1产生 2不产生
        isProduceOfInsure: 2, // 保险付费是否产生消费积分 1产生 2不产生
        manyExchangeMoney: "",    // 每 1 积分抵多少元现金
        manyExchangePoint: "",   // 每消费 1 元产生多少积分
        name: "",     // 等级名称
        spec: "",      // 备注
        status: 1,    // 状态 1.启用 2.禁用
        isProduceOfPointType: ['isProduceOfCustomer'],
    }

    //初始化是否可以修改工时费
    const initialTaxonomy = {
        ownerId: common.getUser().company.id,
        taxonomy: "updateProjectSalePriceAtPrepay",
        name: "预结算修改工时费",
        flag: UPDATE_PROJECT_SALE_PRICE_AT_PREPAY_NO,
    }

    // 初始化结算表单
    let initSettleForm = {
        insuranceName: "",  // 保险公司的名称
        factoryName: "",  // 索赔公司的名称
    }

    let [businessKinds, setBusinessKinds] = useState([]) // 获取业务类别
    let [repair, setRepair] = useState(initRepair) // 维修工单
    let [canReminder, setCanReminder] = useState(false)// 维修工单自动弹出维修提醒
    let [remindersModal, setRemindersModal] = useState(false) // 维修提醒模态框 是否显示
    let [account, setAccount] = useState() // 挂帐

    let [projectRepairItems, setProjectRepairItems] = useState([]) // 工时费用
    let [productRepairItems, setProductRepairItems] = useState([]) // 维修材料
    let [otherRepairItems, setOtherRepairItems] = useState([]) // 委外项目
    let [couponPlans, setCouponPlans] = useState([]) // 礼券销售

    let [insuranceProjects, setInsuranceProjects] = useState([])    // 保险projects
    let [insuranceProducts, setInsuranceProducts] = useState([])    // 保险products
    let [factoryProjects, setFactoryProjects] = useState([])    // 厂家索赔projects
    let [factoryProducts, setFactoryProducts] = useState([])    // 厂家索赔products
    let [customerProjects, setCustomerProjects] = useState([])  // 客户自费projects
    let [customerProducts, setCustomerProducts] = useState([])  // 客户自费products

    let [customerTotal, setCustomerTotal] = useState("0.00") // 客户自费
    let [insuranceTotal, setInsuranceTotal] = useState("0.00") // 维修保险
    let [factoryTotal, setFactoryTotal] = useState("0.00") // 厂家索赔
    let [delegateTotal, setDelegateTotal] = useState("0.00") // 委托费用  委内+委外费用

    let [customerDiscountMoney, setCustomerDiscountMoney] = useState("0.00") // 客户折扣
    let [insuranceDiscountMoney, setInsuranceDiscountMoney] = useState("0.00") // 保险折扣
    let [factoryDiscountMoney, setFactoryDiscountMoney] = useState("0.00")  // 厂家索赔折扣
    let [cardDiscountMoney, setCardDiscountMoney] = useState("0.00") // 会员折扣
    let [customerOfInsuranceMoney, setCustomerOfInsuranceMoney] = useState("0.00")  // 自费保险
    let [accountPartnerMoney, setAccountPartnerMoney] = useState("0.00")  // 挂帐金额

    let [customerProjectMoney, setCustomerProjectMoney] = useState("0.00")  // 客户自费工时费用
    let [customerProductMoney, setCustomerProductMoney] = useState("0.00")  // 客户自费材料费用
    let [insuranceProjectMoney, setInsuranceProjectMoney] = useState("0.00")    // 客户自费材料费用
    let [insuranceProductMoney, setInsuranceProductMoney] = useState("0.00")    // 客户自费材料费用
    let [factoryProjectMoney, setFactoryProjectMoney] = useState("0.00")    // 客户自费材料费用
    let [factoryProductMoney, setFactoryProductMoney] = useState("0.00")    // 客户自费材料费用

    let [customerPayTotal, setCustomerPayTotal] = useState("0.00")  // 自费小计
    let [insurancePayTotal, setInsurancePayTotal] = useState("0.00")    // 保险小计
    let [factoryPayTotal, setFactoryPayTotal] = useState("0.00")    // 索赔小计
    let [delegatePayTotal, setDelegatePayTotal] = useState("0.00")    // 委托小计
    let [couponPayTotal, setCouponPayTotal] = useState("0.00") // 购买礼券的价格
    let [discountTotal, setDiscountTotal] = useState("0.00")    // 累计折扣

    let [ticketRecoverVisible, setTicketRecoverVisible] = useState(false) //  卡券回收
    let [recoveredTickets, setRecoveredTickets] = useState([])// 礼券回收
    let [insuranceClaimVisible, setInsuranceClaimVisible] = useState(false)    // 理赔单弹框
    let [factoryClaimVisible, setFactoryClaimVisible] = useState(false)    // 索赔单弹框
    let [customerClaimVisible, setCustomerClaimVisible] = useState(false)    // 客户费用确认单弹框

    let [insurancePrepay, setInsurancePrepay] = useState({})// 保险理赔单预结算d
    let [customerOfInsurancePrepay, setCustomerOfInsurancePrepay] = useState({})// 保险-客户自费部分理赔单预结算d
    let [factoryPrepay, setFactoryPrepay] = useState({})// 厂家索赔单预结算
    let [customerPrepay, setCustomerPrepay] = useState({}) // 客户自费预结算
    let [accountPrepay, setAccountPrepay] = useState({}) // 挂帐预结算
    let [prepays, setPrepays] = useState([])// 预结算列表

    let [insuranceClaim, setInsuranceClaim] = useState(initInsuranceClaim) // 理赔单
    let [factoryClaim, setFactoryClaim] = useState(initFactoryClaim) // 索赔单
    let [customerClaim, setCustomerClaim] = useState(initCustomerClaim) // 索赔单

    let [ticketIds, setTicketIds] = useState([]) // 回收的礼券id
    let [lookupCouponPlanVisible, setLookupCouponPlanVisible] = useState(false)  // 礼券方案弹框
    let [originCustomerProjects, setOriginCustomerProjects] = useState([]) // 原始的维修项目数据
    let [originCustomerProducts, setOriginCustomerProducts] = useState([]) // 原始的维修材料数据

    let [repairCategoryObj, setRepairCategoryObj] = useState({}) // 工单种类

    let [finance, setFinance] = useState(initFinance) // 财务信息
    let [customerPrepayId, setCustomerPrepayId] = useState("") // 客户自费预结算id

    let [prepaySpec, setPrepaySpec] = useState("") // 预结算备注
    let [vip, setVip] = useState(false) // 是否是vip
    let [vipLevelName, setVipLevelName] = useState("普通客户") // 会员等级名称
    let [vipLevel, setVipLevel] = useState(initialVipLevel) // 会员等级名称
    let [discountProject, setDiscountProject] = useState("1") // 会员卡工时折扣
    let [discountProduct, setDiscountProduct] = useState("1")// 会员卡材料折扣
    let [customer, setCustomer] = useState({
        provinceName: "",
        cityName: "",
        areaName: "",
        address: "",
    })// 打印的时候需要客户信息
    let [companyObj, setCompanyObj] = useState({
        provinceName: "",
        cityName: "",
        areaName: "",
        address: "",
    })// 打印的时候 需要公司信息
    let [taxonomy, setTaxonomy] = useState(initialTaxonomy) // 分类
    let [lookupCustomerRecordVisible, setLookupCustomerRecordVisible] = useState(false) // 客户往来详情弹框
    let [cardDiscountDetailVisible, setCardDiscountDetailVisible] = useState(false) //
    let [reviewFormVisible, setReviewFormVisible] = useState(false) //  审核弹框
    let [imageUrl, setImageUrl] = useState(null);
    let [projectSubtotal, setProjectSubtotal] = useState({
        quantity: "0.00",
        receiptTotal: "0.00",
        saleTotal: "0.00",
        total: "0.00",
        dispatchTime: "0.00",
        couponDiscount: "0.00",
        cardDiscount: "0.00",
        manualDiscount: "0.00",
    })
    let [productSubtotal, setProductSubtotal] = useState({
        quantity: "0.00",
        receiptTotal: "0.00",
        saleTotal: "0.00",
        total: "0.00",
        couponDiscount: "0.00",
        cardDiscount: "0.00",
        manualDiscount: "0.00",
    })
    let [otherSubtotal, setOtherSubtotal] = useState({
        quantity: "0.00",
        receiptTotal: "0.00",
        saleTotal: "0.00",
        total: "0.00",
        couponDiscount: "0.00",
        cardDiscount: "0.00",
        manualDiscount: "0.00",
    })
    let [couponSubtotal, setCouponSubtotal] = useState({
        quantity: "0.00",
        receiptTotal: "0.00",
        saleTotal: "0.00",
        total: "0.00",
        couponDiscount: "0.00",
        cardDiscount: "0.00",
        manualDiscount: "0.00",
    })
    let [deliveryPriceIsSalePriceByCustomer, setDeliveryPriceIsSalePriceByCustomer] = useState(null) // 预结算时采用（库房出库价）做为客户自费的销售单价 false.否 true.是
    let [deliveryPriceIsSalePriceByFactory, setDeliveryPriceIsSalePriceByFactory] = useState(null) // 预结算时采用（库房出库价）做为厂家索赔的销售单价  false.否 true.是
    let [deliveryPriceIsSalePriceByInsurance, setDeliveryPriceIsSalePriceByInsurance] = useState(null) // 预结算时采用（库房出库价）做为保险理赔的销售单价  false.否 true.是


    // 工时费用列
    const projectColumns = [
        {
            title: '序号',
            dataIndex: 'id',
            key: 'id',
            width: 80,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return index + 1
            }
        },
        {
            title: '项目名称',
            dataIndex: 'projectName',
            width: 250,
            ellipsis: true,
        },
        {
            title: '维修工段',
            dataIndex: 'sectionName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '业务类别',
            dataIndex: 'businessKind',
            ellipsis: true,
            width: 200,
            render: (text, record, index) => (
                <div style={{display: "flex", justifyContent: "flex-start"}}>
                    <Select
                        disabled={repair.status >= Repair.REPAIR_STATUS_PREPAID}
                        style={{width: 100, marginRight: "10px"}}
                        value={record.businessKind}
                        onChange={(value) => {
                            // 订单预结
                            if (repair.status >= Repair.REPAIR_STATUS_PREPAID) {
                                return;
                            }
                            projectRepairItems.forEach(item => {
                                if (item.id === record.id) {
                                    item.couponDiscount = 0 // 设置礼券抵扣为0
                                    item.cardDiscount = 0 // 设置礼券抵扣为0
                                    item.manualDiscount = 0 // 设置手动抵扣
                                    item.manualRate = 0 // 设置折扣比例为0
                                    businessKinds.forEach(businessKind => {
                                        if (value === "") {
                                            item.chargeType = ""
                                            item.businessKind = ""
                                        }
                                        if (value === businessKind.id) {
                                            item.chargeType = businessKind.chargeType
                                            item.businessKind = businessKind.id
                                            // 设置会员卡优惠
                                            if (vip && item.chargeType === CHARGE_TYPE_CUSTOMER && repair.type === REPAIR_TYPE_NORMAL) {
                                                item.cardDiscount = common.numberHalfUp(new BigNumber(item.salePrice)
                                                    .multipliedBy(item.quantity).multipliedBy(discountProject).toString(), 2)
                                            } else {
                                                item.cardDiscount = 0 // 设置会员卡抵扣
                                            }
                                        }
                                    })
                                    // 设置实付金额
                                    item.total = common.numberHalfUp(new BigNumber(item.salePrice)
                                        .multipliedBy(item.quantity)
                                        .minus(item.couponDiscount)
                                        .minus(item.cardDiscount)
                                        .minus(item.manualDiscount)
                                        .toString(), 2)
                                    // 设置实际单价
                                    item.price = common.numberHalfUp(new BigNumber(item.total).dividedBy(item.quantity).toString(), 2)
                                }
                            })
                            setProjectRepairItems([...projectRepairItems])
                        }}
                    >
                        <Option value="">请选择</Option>
                        {businessKinds.map(item => (
                            <Option
                                value={item.id}
                                key={item.id}>
                                {item.name}
                            </Option>
                        ))}
                    </Select>
                    <Button icon={<CheckSquareOutlined/>}
                            disabled={repair.status >= Repair.REPAIR_STATUS_PREPAID}
                            hidden={!(index === 0 && record.businessKind !== "")}
                            onClick={() => {
                                // 订单预结
                                if (repair.status >= Repair.REPAIR_STATUS_PREPAID) {
                                    return;
                                }
                                projectRepairItems.forEach(item => {
                                    item.businessKind = record.businessKind
                                    item.chargeType = record.chargeType
                                    item.couponDiscount = 0 // 设置礼券抵扣为0
                                    item.manualDiscount = 0 // 设置手动抵扣
                                    item.manualRate = 0 // 设置手动折扣比例为0
                                    // 设置会员卡优惠
                                    if (vip && item.chargeType === CHARGE_TYPE_CUSTOMER && repair.type === REPAIR_TYPE_NORMAL) {
                                        item.cardDiscount = common.numberHalfUp(new BigNumber(item.salePrice)
                                            .multipliedBy(item.quantity).multipliedBy(discountProject).toString(), 2)
                                    } else {
                                        item.cardDiscount = 0 // 设置会员卡抵扣
                                    }
                                    // 设置实付金额
                                    item.total = common.numberHalfUp(new BigNumber(item.salePrice)
                                        .multipliedBy(item.quantity)
                                        .minus(item.couponDiscount)
                                        .minus(item.cardDiscount)
                                        .minus(item.manualDiscount)
                                        .toString(), 2)
                                    // 设置实际单价
                                    item.price = common.numberHalfUp(new BigNumber(item.total).dividedBy(item.quantity).toString(), 2)
                                })
                                setProjectRepairItems([...projectRepairItems])
                            }}>批量
                    </Button>
                </div>)
        },
        {
            title: '工时费',
            dataIndex: 'salePrice',
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                return taxonomy.flag === UPDATE_PROJECT_SALE_PRICE_AT_PREPAY_YES ?
                    (
                        <InputNumber
                            disabled={repair.status >= Repair.REPAIR_STATUS_PREPAID}
                            precision={2}
                            min={0}
                            value={record.salePrice}
                            onChange={value => {
                                // 订单预结
                                if (repair.status >= Repair.REPAIR_STATUS_PREPAID) {
                                    return;
                                }
                                projectRepairItems.forEach(item => {
                                    if (item.id === record.id) {
                                        item.salePrice = value
                                        item.deliveryPrice = value
                                        item.price = common.numberHalfUp(new BigNumber(item.salePrice)
                                            .minus(item.couponDiscount)
                                            .minus(item.manualDiscount)
                                            .minus(item.cardDiscount).toString(), 2)
                                        item.total = item.price
                                        if (item.total < 0) {
                                            item.salePrice = common.numberHalfUp(new BigNumber(item.couponDiscount)
                                                .plus(item.manualDiscount)
                                                .plus(item.cardDiscount).toString(), 2)
                                            item.deliveryPrice = item.salePrice
                                            item.price = item.salePrice
                                        }
                                    }
                                })
                                setProjectRepairItems([...projectRepairItems])
                            }}
                        />
                    )
                    :
                    text
            }
        },
        {
            title: '派工工时',
            dataIndex: 'dispatchTime',
            width: 100,
            ellipsis: true,
            align: "right",
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '礼券抵扣',
            dataIndex: 'couponDiscount',
            width: 100,
            ellipsis: true,
            align: "right",
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '会员卡折扣',
            dataIndex: 'cardDiscount',
            width: 120,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '',
            width: 50,
            dataIndex: '',
            ellipsis: true,
        },
        {
            title: '手动折扣',
            dataIndex: 'manualDiscount',
            width: 280,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div>
                        <InputNumber
                            style={{marginRight: 10}}
                            precision={2}
                            disabled
                            value={record.manualDiscount}
                        />
                        {/*百分比折扣*/}
                        <InputNumber
                            value={record.manualRate || 0} style={{marginRight: 10}}
                            disabled
                            formatter={value => value + '%'}
                            precision={2}
                        />
                    </div>
                )
            }
        },
        {
            title: '实付金额',
            dataIndex: 'total',
            ellipsis: true,
            align: "right",
            width: 120,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '',
            width: 50,
            dataIndex: '',
            ellipsis: true,
        },
        {
            title: '付费类型',
            dataIndex: 'chargeType',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return (<div>{chargeTypeAlias[text]}</div>)
            }
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 150,
            ellipsis: true,
        },
    ]

    // 维修材料 业务类别变更
    let productBusinessChange = (value, record) => {
        productRepairItems.forEach(item => {
            if (item.id === record.id) {
                item.couponDiscount = 0 // 设置礼券抵扣为0
                item.manualDiscount = 0 // 设置手动抵扣
                item.manualRate = 0 // 设置折扣比例为0
                businessKinds.forEach(businessKind => {
                    if (value === "") {
                        item.chargeType = ""
                        item.businessKind = ""
                    }
                    if (value === businessKind.id) {
                        item.chargeType = businessKind.chargeType
                        item.businessKind = businessKind.id
                        // 查看分类
                        if ((businessKind.chargeType === CHARGE_TYPE_CUSTOMER && (deliveryPriceIsSalePriceByCustomer === null ? false : deliveryPriceIsSalePriceByCustomer)) ||
                            (businessKind.chargeType === CHARGE_TYPE_FACTORY && (deliveryPriceIsSalePriceByFactory === null ? false : deliveryPriceIsSalePriceByFactory)) ||
                            (businessKind.chargeType === CHARGE_TYPE_INSURE && (deliveryPriceIsSalePriceByInsurance === null ? false : deliveryPriceIsSalePriceByInsurance))) {
                            if (!item.originSalePrice) {
                                item.originSalePrice = item.salePrice
                            }
                            item.salePrice = item.deliveryPrice
                        } else {
                            // 还原销售单价
                            if (item.originSalePrice) {
                                item.salePrice = item.originSalePrice
                            }
                            // 根据业务类型设置材料价格
                            handleProductGeneral(item);
                        }
                    }
                })
                // 设置会员卡折扣
                if (vip && item.chargeType === CHARGE_TYPE_CUSTOMER && repair.type === REPAIR_TYPE_NORMAL) {
                    item.cardDiscount = common.numberHalfUp(new BigNumber(item.salePrice)
                        .multipliedBy(item.quantity).multipliedBy(discountProduct).toString(), 2)
                } else {
                    item.cardDiscount = 0 // 设置会员卡抵扣
                }
                // 设置实付金额
                item.total = common.numberHalfUp(new BigNumber(item.salePrice)
                    .multipliedBy(item.quantity)
                    .minus(item.couponDiscount)
                    .minus(item.cardDiscount)
                    .minus(item.manualDiscount)
                    .toString(), 2)
                // 设置实际单价变成出厂价
                item.price = common.numberHalfUp(new BigNumber(item.total).dividedBy(item.quantity).toString(), 2)
            }
        })
        setProductRepairItems([...productRepairItems])
    }


    //  维修材料列
    const productColumns = [
        {
            title: '序号',
            dataIndex: 'id',
            key: 'id',
            width: 80,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return index + 1
            }
        },
        {
            title: '配件编号',
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true,
        },
        {
            title: '材料名称',
            dataIndex: 'productName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 100,
            align: "right",
            ellipsis: true,
        },
        {
            title: '出库单价',
            dataIndex: 'deliveryPrice',
            width: 100,
            align: "right",
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '出库总价',
            width: 100,
            align: "right",
            render: (text, record) => {
                let deliverTotal = common.numberHalfUp(new BigNumber(record.deliveryPrice).multipliedBy(record.quantity).toString(), 2)
                return common.numberFormat(deliverTotal)
            }
        },
        {
            title: '销售单价',
            dataIndex: 'salePrice',
            width: 100,
            align: "right",
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '总金额',
            width: 100,
            align: "right",
            ellipsis: true,
            render: ((text, record) => {
                let SaleTotal = common.numberHalfUp(new BigNumber(record.salePrice).multipliedBy(record.quantity).toString(), 2)
                return common.numberFormat(SaleTotal)
            })
        },
        {
            title: '',
            width: 50,
            ellipsis: true,
        },
        {
            title: '业务类别',
            dataIndex: 'businessKind',
            width: 200,
            ellipsis: true,
            render: (text, record, index) => (
                <div style={{display: "flex", justifyContent: "flex-start"}}>
                    <Select
                        disabled={repair.status >= Repair.REPAIR_STATUS_PREPAID}
                        style={{width: 100, marginRight: "10px"}} value={record.businessKind}
                        onChange={(value) => {
                            // 预结算
                            if (repair.status >= Repair.REPAIR_STATUS_PREPAID) {
                                return
                            }
                            productBusinessChange(value, record)
                        }}>
                        <Option value="">请选择</Option>
                        {businessKinds.map(item => (
                            <Option
                                value={item.id}
                                key={item.id}>
                                {item.name}</Option>
                        ))}
                    </Select>
                    <Button icon={<CheckSquareOutlined/>}
                            disabled={repair.status >= Repair.REPAIR_STATUS_PREPAID}
                            hidden={!(index === 0 && record.businessKind !== "")}
                            onClick={() => {
                                if (repair.status >= Repair.REPAIR_STATUS_PREPAID) {
                                    return;
                                }
                                productRepairItems.forEach(item => {
                                    item.businessKind = record.businessKind
                                    item.chargeType = record.chargeType
                                    item.couponDiscount = 0 // 设置礼券抵扣为0
                                    item.manualDiscount = 0 // 设置手动抵扣
                                    item.manualRate = 0 // 设置手动折扣比例为0
                                    // 设置分类，检查是否出库价等于销售价
                                    if ((record.chargeType === CHARGE_TYPE_CUSTOMER && (deliveryPriceIsSalePriceByCustomer === null ? false : deliveryPriceIsSalePriceByCustomer)) ||
                                        (record.chargeType === CHARGE_TYPE_FACTORY && (deliveryPriceIsSalePriceByFactory === null ? false : deliveryPriceIsSalePriceByFactory)) ||
                                        (record.chargeType === CHARGE_TYPE_INSURE && (deliveryPriceIsSalePriceByInsurance === null ? false : deliveryPriceIsSalePriceByInsurance))) {
                                        if (!item.originSalePrice) {
                                            item.originSalePrice = item.salePrice
                                        }
                                        item.salePrice = item.deliveryPrice
                                        if (vip && item.chargeType === CHARGE_TYPE_CUSTOMER && repair.type === REPAIR_TYPE_NORMAL) {
                                            item.cardDiscount = common.numberHalfUp(new BigNumber(item.salePrice)
                                                .multipliedBy(item.quantity).multipliedBy(discountProduct).toString(), 2)
                                        } else {
                                            item.cardDiscount = 0 // 设置会员卡抵扣
                                        }
                                        // 设置实付金额
                                        item.total = common.numberHalfUp(new BigNumber(item.salePrice)
                                            .multipliedBy(item.quantity)
                                            .minus(item.couponDiscount)
                                            .minus(item.cardDiscount)
                                            .minus(item.manualDiscount)
                                            .toString(), 2)
                                        // 设置实际单价
                                        item.price = common.numberHalfUp(new BigNumber(item.total).dividedBy(item.quantity).toString(), 2)
                                    } else {
                                        if (item.originSalePrice) {
                                            item.salePrice = item.originSalePrice
                                        }
                                        // 根据业务类型设置材料价格
                                        handleProductGeneral(item);
                                    }
                                })
                                setProductRepairItems([...productRepairItems])  // 批量设置加价率
                            }}>批量
                    </Button>
                </div>
            )
        },
        {
            title: '礼券抵扣',
            dataIndex: 'couponDiscount',
            width: 100,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '会员卡折扣',
            dataIndex: 'cardDiscount',
            width: 100,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '',
            width: 50,
            dataIndex: '',
            ellipsis: true,
        },
        {
            title: '手动折扣(总)',
            dataIndex: 'manualDiscount',
            width: 280,
            ellipsis: true,
            render: (text, record) => (
                <div>
                    <InputNumber
                        style={{marginRight: 10}}
                        precision={2}
                        value={record.manualDiscount}
                        disabled
                    />
                    {/*百分比折扣*/}
                    <InputNumber
                        value={record.manualRate || 0} style={{marginRight: 10}}
                        formatter={value => value + '%'}
                        precision={2}
                        disabled
                    />
                </div>
            )
        },
        {
            title: '实际单价',
            dataIndex: 'price',
            width: 100,
            align: "right",
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '实付金额',
            dataIndex: 'total',
            width: 100,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(new BigNumber(text).toString())
            }
        },
        {
            title: '',
            dataIndex: '',
            width: 50,
            ellipsis: true,
        },
        {
            title: '付费类型',
            dataIndex: 'chargeType',
            width: 100,
            ellipsis: true,
            render: (text) => {
                return chargeTypeAlias[text]
            }
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 150,
            ellipsis: true,
        },
    ]


    // 协修工单列
    const otherColumns = [
        {
            title: '费用名称',
            dataIndex: 'name',
            width: 150,
            ellipsis: true,
        },
        {
            title: '项目类别',
            dataIndex: 'type',
            width: 120,
            render: text => (
                <div style={{color: "#EF4566"}}>
                    {repairItemTypeAlias[text]}
                </div>
            )
        },
        {
            title: '业务类别',
            dataIndex: 'businessKind',
            ellipsis: true,
            width: 200,
            render: (text, record, index) =>
                <div style={{display: "flex", justifyContent: "flex-start"}}>
                    <Select
                        style={{width: 100, marginRight: "10px"}}
                        value={record.businessKind}
                        disabled={repair.status >= Repair.REPAIR_STATUS_PREPAID}
                        onChange={(value) => {
                            if (repair.status >= Repair.REPAIR_STATUS_PREPAID) {
                                return
                            }
                            otherRepairItems.forEach(item => {
                                if (item.id === record.id) {
                                    item.couponDiscount = 0 // 设置礼券抵扣为0
                                    item.cardDiscount = 0 // 设置礼券抵扣为0
                                    item.manualDiscount = 0 // 设置手动抵扣
                                    item.manualRate = 0 // 设置折扣比例为0
                                    businessKinds.forEach(businessKind => {
                                        if (value === "") {
                                            item.chargeType = ""
                                            item.businessKind = ""
                                        }
                                        if (value === businessKind.id) {
                                            item.chargeType = businessKind.chargeType
                                            item.businessKind = businessKind.id
                                        }
                                    })
                                    // 设置实付金额
                                    item.total = common.numberHalfUp(new BigNumber(item.salePrice)
                                        .multipliedBy(item.quantity)
                                        .minus(item.couponDiscount)
                                        .minus(item.cardDiscount)
                                        .minus(item.manualDiscount)
                                        .toString(), 2)
                                    // 设置实际单价
                                    item.price = common.numberHalfUp(new BigNumber(item.total).dividedBy(item.quantity).toString(), 2)
                                }
                            })
                            setOtherRepairItems([...otherRepairItems])
                        }}>
                        <Option value="">请选择</Option>
                        {businessKinds.map(item => (
                            <Option
                                value={item.id}
                                key={item.id}>
                                {item.name}</Option>
                        ))}
                    </Select>
                    <Button icon={<CheckSquareOutlined/>}
                            disabled={repair.status >= Repair.REPAIR_STATUS_PREPAID}
                            hidden={!(index === 0 && record.businessKind !== "")}
                            onClick={() => {
                                otherRepairItems.forEach(item => {
                                    item.chargeType = record.chargeType
                                    item.businessKind = record.businessKind
                                })
                                setOtherRepairItems([...otherRepairItems])
                            }}>批量
                    </Button>
                </div>
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 100,
            align: "right",
            ellipsis: true,
        },
        {
            title: '销售单价',
            dataIndex: 'salePrice',
            width: 100,
            align: "right",
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '总金额',
            width: 100,
            align: "right",
            ellipsis: true,
            render: (text, record) => {
                let saleTotal = common.numberHalfUp(new BigNumber(record.salePrice).multipliedBy(record.quantity).toString(), 2)
                return common.numberFormat(saleTotal)
            }
        },
        {
            title: '受托方结算金额',
            width: 120,
            ellipsis: true,
            align: "right",
            render: (text, record) => {
                let receiptTotal = common.numberHalfUp(new BigNumber(record.receiptPrice).multipliedBy(record.quantity).toString(), 2)
                return common.numberFormat(receiptTotal)
            }
        },
        {
            title: "",
            width: 30,
            dataIndex: "",
            ellipsis: true,
        },
        {
            title: '付费类型',
            dataIndex: 'chargeType',
            width: 80,
            ellipsis: true,
            render: (text) => {
                return chargeTypeAlias[text]
            }
        },
        {
            title: '礼券折扣',
            dataIndex: 'couponDiscount',
            width: 80,
            align: "right",
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        // {
        //     title: '会员卡折扣',
        //     dataIndex: 'cardDiscount',
        //     width: 100,
        //     align: "right",
        //     ellipsis: true,
        //     render: (text) => {
        //         return common.numberFormat(text)
        //     }
        // },
        {
            title: '手动折扣',
            dataIndex: "manualDiscount",
            width: 80,
            align: "right",
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '客户成交价',
            dataIndex: 'total',
            width: 100,
            ellipsis: true,
            align: "right",
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '',
            dataIndex: '',
            width: 50,
            ellipsis: true,
        },
        {
            title: "受托方",
            width: 200,
            dataIndex: "fiduciaryCompanyName",
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 150,
            ellipsis: true,
        },
    ]


    // 礼券销售方案列
    const couponPlanColumns = [
        {
            title: '礼券名称',
            dataIndex: 'name',
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (!record.isEmptyRecord) {
                    return (
                        <Input style={{width: 200}} value={text}/>
                    )
                }
                return (
                    <Input
                        ref={emptyInput}
                        autoComplete="off"
                        style={{width: 200}}
                        readOnly
                        placeholder="选择礼券方案"
                        addonAfter={
                            <SelectOutlined
                                onClick={() => {
                                    setLookupCouponPlanVisible(true)
                                }}/>
                        }
                    />
                )
            }
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 120,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <div>
                        <InputNumber
                            value={record.quantity}
                            min={0}
                            onChange={(val) => {
                                for (let i = 0; i < couponPlans.length; i++) {
                                    if (record.id === couponPlans[i].id) {
                                        couponPlans[i].quantity = val
                                        couponPlans[i].total = common.numberHalfUp(new BigNumber(couponPlans[i].price)
                                            .multipliedBy(couponPlans[i].quantity).toString(), 2)
                                    }
                                }
                                // 设置礼券小计
                                setCouponPlans([...couponPlans])
                            }}/>
                    </div>
                )
            }
        },
        {
            title: '销售单价',
            dataIndex: 'price',
            width: 120,
            ellipsis: true,
        },
        {
            title: '销售金额',
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                let total = common.numberHalfUp(new BigNumber(record.price).multipliedBy(record.quantity).toString(), 2)
                return common.numberFormat(total)
            }
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 150,
            ellipsis: true,
        },
        {
            title: '操作',
            key: 'operation',
            align: 'center',
            width: 100,
            fixed: 'right',
            ellipsis: true,
            render: (text, record) =>
                <div className={'action-btns'}>
                    {record.isEmptyRecord ? '' :
                        <Button type={'link'} onClick={() => {
                            let data = [...couponPlans]
                            data = data.filter(item => item.id !== record.id)
                            setCouponPlans([...data])
                        }}>删除</Button>
                    }
                </div>
        },
    ]


    // 礼券回收列
    const recoveredTicketsColumns = [
        {
            title: '礼券名称',
            dataIndex: ['couponDto', 'name'],
            width: 220,
            render: (text, record) => {
                if (!record.isEmptyRecord) {
                    return (
                        <Input style={{width: 200}} value={text}/>
                    )
                }
                return (
                    <Input
                        disabled={repair.status >= Repair.REPAIR_STATUS_PREPAID}
                        ref={emptyInput}
                        autoComplete="off"
                        style={{width: 200}}
                        readOnly
                        placeholder="选择礼券回收"
                        addonAfter={
                            <SelectOutlined
                                onClick={() => {
                                    if (repair.status >= Repair.REPAIR_STATUS_PREPAID) {
                                        return
                                    }
                                    originCustomerProjects = []
                                    originCustomerProducts = []
                                    for (let i = 0; i < customerProjects.length; i++) {
                                        originCustomerProjects.push(JSON.parse(JSON.stringify(customerProjects[i])))
                                    }
                                    for (let i = 0; i < customerProducts.length; i++) {
                                        originCustomerProducts.push(JSON.parse(JSON.stringify(customerProducts[i])))
                                    }
                                    setOriginCustomerProjects([...originCustomerProjects])
                                    setOriginCustomerProducts([...originCustomerProducts])
                                    // 恢复原价
                                    for (let i = 0; i < customerProjects.length; i++) {
                                        customerDiscountMoney = common.numberHalfUp(new BigNumber(customerDiscountMoney).minus(customerProjects[i].couponDiscount).toString(), 2)
                                        customerProjects[i].price = common.numberHalfUp(new BigNumber(customerProjects[i].salePrice).toString(), 2)
                                        customerProjects[i].total = common.numberHalfUp(new BigNumber(customerProjects[i].salePrice).multipliedBy(customerProjects[i].quantity).toString(), 2)
                                        customerProjects[i].finalMoney = customerProjects[i].total
                                        customerProjects[i].deductedMoney = 0
                                        customerProjects[i].couponDiscount = 0
                                        customerProjects[i].businessType = "2"  //(业务类型:工时) 给礼券回收用的
                                    }
                                    for (let i = 0; i < customerProducts.length; i++) {
                                        customerDiscountMoney = common.numberHalfUp(new BigNumber(customerDiscountMoney).minus(customerProducts[i].couponDiscount).toString(), 2)
                                        customerProducts[i].price = customerProducts[i].salePrice
                                        customerProducts[i].total = common.numberHalfUp(new BigNumber(customerProducts[i].salePrice).multipliedBy(customerProducts[i].quantity).toString(), 2)
                                        customerProducts[i].finalMoney = customerProducts[i].total
                                        customerProducts[i].deductedMoney = 0
                                        customerProducts[i].couponDiscount = 0
                                        customerProducts[i].businessType = "3" // (业务类型::材料) 给礼券回收用的
                                    }
                                    // 恢复卡券折扣（客户折扣）
                                    setCustomerDiscountMoney(customerDiscountMoney)
                                    setCustomerProjects([...customerProjects])
                                    setCustomerProducts([...customerProducts])
                                    setTicketRecoverVisible(true)
                                }}/>
                        }
                    />
                )
            }
        },
        {
            title: '礼券编号',
            dataIndex: 'code',
            width: 120,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (text)
            }
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 80,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (text)
            }
        },
        {
            title: '最大抵扣金额',
            dataIndex: ['couponDto', 'reduceMax'],
            width: 120,
            align: "right",
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return common.numberFormat(text)
            }
        },
        // {
        //     title: '实际抵扣金额',
        //     width: 120,
        //     align: "right",
        //     dataIndex: "deductedMoney",
        //     render: (text, record) => {
        //         if (record.isEmptyRecord) {
        //             return ''
        //         }
        //         return text
        //     }
        // },
        {
            title: '',
            width: 50,
            dataIndex: '',
        },
        {
            title: '礼券业务类型',
            dataIndex: ['couponDto', 'category2'],
            width: 120,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (category2Name[text])
            }
        },
        {
            title: '礼券财务类型',
            dataIndex: ['couponDto', 'category1'],
            width: 120,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (category1Name[text])
            }
        },
        {
            title: '备注',
            dataIndex: ['couponDto', 'spec'],
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (text)
            }
        },
    ]

    function getSetting(taxonomy) {
        common.loadingStart()
        common.ajax('get', '/support/setting', {
            taxonomy: taxonomy,
            ownerId: common.getUser().company.id,
        }).then((data) => {
            if (data !== null) {
                if (taxonomy === SupportSetting.TAXONOMY_REPAIR_AUTOMATIC_REMINDER) {// 维修工单自动弹出维修提醒 默认否
                    setCanReminder(data.flag === SupportSetting.YES)
                }
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        // 维修工单自动弹出维修提醒 默认否
        getSetting(SupportSetting.TAXONOMY_REPAIR_AUTOMATIC_REMINDER)
    }, [])

    // 获取分类项
    useEffect(() => {
        let params = {
            taxonomy: "updateProjectSalePriceAtPrepay",
            ownerId: ""
        }
        common.loadingStart()
        common.ajax('get', '/support/setting', params)
            .then(res => {
                taxonomy = res || initialTaxonomy
                setTaxonomy(taxonomy)
            })
            .finally(common.loadingStop)
    }, [])

    // 获取维修的业务类别
    useEffect(() => {
        common.loadingStart()
        let params = {
            ownerId: "",
            status: 1, // 维修业务状态：1.启用
        }
        common.ajax('get', '/support/businessKind/list', params).then(res => {
            setBusinessKinds(res.businessKinds)
        }).finally(() => {
            common.loadingStop()
        })
    }, [])


    // 获取工单类型
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/support/term/findByTaxonomy', {taxonomy: 'repairCategory'})
            .then((data) => {
                data.forEach(item => {
                    repairCategoryObj[item.id] = item.name
                })
                setRepairCategoryObj({...repairCategoryObj})
            })
            .finally(() => {
                common.loadingStop()
            })
    }, [])


    // 预结算时采用（库房出库价）做为客户自费的销售单价
    let getDeliveryPriceIsSalePriceByCustomerTaxonomy = () => {
        let params = {
            taxonomy: "deliveryPriceIsSalePriceByCustomer",
            ownerId: common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/support/setting', params)
            .then(res => {
                if (res) {
                    setDeliveryPriceIsSalePriceByCustomer((res.flag || DELIVERY_PRICE_IS_SALE_PRICE_BY_FACTORY_NO) === DELIVERY_PRICE_IS_SALE_PRICE_BY_CUSTOMER_YES)
                } else {
                    setDeliveryPriceIsSalePriceByCustomer(false)
                }
            })
            .finally(common.loadingStop)
    }


    // 预结算时采用（库房出库价）做为厂家索赔的销售单价
    let getDeliveryPriceIsSalePriceByFactoryTaxonomy = () => {
        let params = {
            taxonomy: "deliveryPriceIsSalePriceByFactory",
            ownerId: common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/support/setting', params)
            .then(res => {
                if (res) {
                    setDeliveryPriceIsSalePriceByFactory((res.flag || DELIVERY_PRICE_IS_SALE_PRICE_BY_FACTORY_NO) === DELIVERY_PRICE_IS_SALE_PRICE_BY_FACTORY_YES)
                } else {
                    setDeliveryPriceIsSalePriceByFactory(false)
                }
            })
            .finally(common.loadingStop)
    }


    // 预结算时采用（库房出库价）做为保险理赔的销售单价
    let getDeliveryPriceIsSalePriceByInsuranceTaxonomy = () => {
        let params = {
            taxonomy: "deliveryPriceIsSalePriceByInsurance",
            ownerId: common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/support/setting', params)
            .then(res => {
                if (res) {
                    setDeliveryPriceIsSalePriceByInsurance((res.flag || DELIVERY_PRICE_IS_SALE_PRICE_BY_FACTORY_NO) === DELIVERY_PRICE_IS_SALE_PRICE_BY_INSURANCE_YES)
                } else {
                    setDeliveryPriceIsSalePriceByInsurance(false)
                }
            })
            .finally(common.loadingStop)
    }

    useEffect(() => {
        getDeliveryPriceIsSalePriceByCustomerTaxonomy()
        getDeliveryPriceIsSalePriceByFactoryTaxonomy()
        getDeliveryPriceIsSalePriceByInsuranceTaxonomy()
    }, [])


    // 处理维修材料的加价率
    let handleProductGeneral = (record) => {
        let params = {
            id: record.productId,
            ownerId: common.getUser().company.id,
        }
        let supplierId = ""
        common.loadingStart()
        common.ajax('get', '/wms/product/findGeneralById', params)
            .then(data => {
                supplierId = data.supplierId
                productRepairItems.forEach(item => {
                    if (item.id === record.id) {
                        data.rates.forEach(val => {
                            if (val.chargeType.toString() === record.chargeType.toString()) {
                                item.salePrice = val.price
                                item.priceRate = val.priceRate
                                item.businessKind = record.businessKind
                                item.chargeType = record.chargeType
                                item.couponDiscount = 0 // 设置礼券抵扣为0
                                item.manualDiscount = 0 // 设置手动抵扣
                                item.manualRate = 0 // 设置手动折扣比例为0
                                if (vip && item.chargeType === CHARGE_TYPE_CUSTOMER && repair.type === REPAIR_TYPE_NORMAL) {
                                    item.cardDiscount = common.numberHalfUp(new BigNumber(item.salePrice).multipliedBy(item.quantity).multipliedBy(discountProduct).toString(), 2)
                                } else {
                                    item.cardDiscount = 0 // 设置会员卡抵扣
                                }
                                // 设置实付金额
                                item.total = common.numberHalfUp(new BigNumber(item.salePrice)
                                    .multipliedBy(item.quantity)
                                    .minus(item.couponDiscount)
                                    .minus(item.cardDiscount)
                                    .minus(item.manualDiscount)
                                    .toString(), 2)
                                // 设置实际单价
                                item.price = common.numberHalfUp(new BigNumber(item.total).dividedBy(item.quantity).toString(), 2)
                            }
                        })
                    }
                })
                if (supplierId !== "") {
                    common.loadingStart()
                    common.ajax("get", "/passport/partner/findById?id=" + supplierId)
                        .then(res => {
                                if (res) {
                                    productRepairItems.forEach(item => {
                                        if (record.id === item.id) {
                                            item.supplier = res.name
                                        }
                                    })
                                }
                            }
                        )
                        .finally(common.loadingStop)
                }
                setProductRepairItems([...productRepairItems])
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    function copyToClipboard(textToCopy) {
        if (document.execCommand('copy')) {
            // 创建textarea
            var textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            // 使textarea不在viewport，同时设置不可见
            textArea.style.position = "fixed";
            textArea.style.opacity = 0;
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // 执行复制命令并移除文本框
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            });
        } else if (navigator.clipboard && typeof navigator.clipboard.writeText === 'function') {
            // navigator clipboard 向剪贴板写文本
            return navigator.clipboard.writeText(textToCopy).then(
                (res) => {
                    common.toast("复制成功")
                }).catch((err) => {
                common.toast("复制失败")
            })
        }
    }


    // 设置维修费用
    useEffect(() => {
        insuranceProjects = [] // 设置 保险理赔维修项目 为空
        factoryProjects = [] // 设置 厂家索赔维修项目 为空
        customerProjects = [] // 设置 客户自费维修项目 为空
        insuranceProducts = [] // 设置 保险理赔维修材料 为空
        factoryProducts = [] // 设置 厂家索赔维修材料 为空
        customerProducts = [] // 设置 客户自费维修材料 为空
        let repairItems = [...projectRepairItems, ...productRepairItems, ...otherRepairItems]
        repairItems.forEach(item => {
            // 维修工时
            if (item.type === REPAIR_ITEM_TYPE_PROJECT || item.type === REPAIR_ITEM_TYPE_DELEGATE_PROJECT || item.type === REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT) {
                // 如果维修项目的业务类别 为 客户自费 设置 客户小计 、 客户自费折扣
                if (item.chargeType === CHARGE_TYPE_CUSTOMER) {
                    customerProjects.push(item)
                }
                // 如果维修项目的业务类别 为 保险理赔 设置 保险总金额、保险折扣
                if (item.chargeType === CHARGE_TYPE_INSURE) {
                    insuranceProjects.push(item)
                }
                // 如果维修项目的业务类别 为 厂家索赔  设置 厂家索赔总金额、厂家索赔折扣
                if (item.chargeType === CHARGE_TYPE_FACTORY) {
                    factoryProjects.push(item)
                }
            }
            // 维修材料
            if (item.type === REPAIR_ITEM_TYPE_PRODUCT || item.type === REPAIR_ITEM_TYPE_DELEGATE_PRODUCT || item.type === REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT) {
                // 如果维修材料的业务类别 为 客户自费 设置客户自费总金额、客户自费折扣
                if (item.chargeType === CHARGE_TYPE_CUSTOMER) {
                    customerProducts.push(item)
                }
                // 如果维修材料的业务类别 为 保险理赔 设置保险理赔总金额
                if (item.chargeType === CHARGE_TYPE_INSURE) {
                    insuranceProducts.push(item)
                }
                // 如果维修材料的业务类别 为 厂家索赔 设置厂家索赔总金额
                if (item.chargeType === CHARGE_TYPE_FACTORY) {
                    factoryProducts.push(item)
                }
            }
        })
        setCustomerProjects(customerProjects) // 设置客户自费维修项目
        setCustomerProducts(customerProducts) // 设置客户自费维修材料
        setInsuranceProjects(insuranceProjects) // 设置保险理赔维修项目
        setInsuranceProducts(insuranceProducts) // 设置保险理赔维修材料
        setFactoryProjects(factoryProjects) // 设置工厂索赔维修项目
        setFactoryProducts(factoryProducts) // 设置工厂索赔维修材料
    }, [projectRepairItems, productRepairItems, otherRepairItems])


    //只要客户自费工时、客户自费材料、保险自费、挂帐金额发生变化就设置客户自费的统计信息
    useEffect(() => {
        // 合并客户自费工时和客户自费材料,如果客户自费明细不为空则设置保险信息
        let customerRepairItems = [...customerProjects, ...customerProducts]
        //重置客户统计的信息
        customerTotal = "0.00" //客户自费
        customerDiscountMoney = "0.00" //客户折扣
        customerPayTotal = "0.00" //自费小计
        customerProjectMoney = "0.00" //客户工时费
        customerProductMoney = "0.00" //客户材料费
        let customerActualTotal = "0.00"  //客户实际总费用 =  自费小计 +  自费保险
        customerRepairItems.forEach(item => {
            // 客户自费 = 销售单价 * 数量 （salePrice * quantity）
            customerTotal = common.numberHalfUp(new BigNumber(customerTotal).plus(new BigNumber(item.salePrice).multipliedBy(item.quantity)).toString(), 2)
            // 客户自费实际总费用 = 折后总价 （total）
            customerActualTotal = common.numberHalfUp(new BigNumber(customerActualTotal).plus(item.total).toString(), 2)
            //客户折扣 = 手动折扣 + 会员卡折扣 + 礼券折扣
            customerDiscountMoney = common.numberHalfUp(new BigNumber(customerDiscountMoney).plus(item.manualDiscount).plus(item.cardDiscount).plus(item.couponDiscount).toString(), 2)
            if (item.type === REPAIR_ITEM_TYPE_PROJECT) {
                //客户工时费
                customerProjectMoney = common.numberHalfUp(new BigNumber(customerProjectMoney).plus(item.total).toString(), 2)
            }
            if (item.type === REPAIR_ITEM_TYPE_PRODUCT) {
                //客户材料费
                customerProductMoney = common.numberHalfUp(new BigNumber(customerProductMoney).plus(item.total).toString(), 2)
            }
        })

        //设置自费保险部分的工时费和材料费
        let insuranceRepairItems = [...insuranceProjects, ...insuranceProducts]
        let insuranceActualTotal = common.numberHalfUp(new BigNumber(insuranceTotal).minus(insuranceDiscountMoney).toString(), 2)
        let beforeTotal = "0.00" // 用来记录最后一个之前的所有价格总和
        insuranceRepairItems.forEach((item, index) => {
            let money
            if (index === insuranceRepairItems.length - 1) {
                money = common.numberHalfUp(new BigNumber(customerOfInsuranceMoney).minus(beforeTotal).toString(), 2)
            } else {
                money = common.numberHalfUp(new BigNumber(customerOfInsuranceMoney).multipliedBy(item.total).dividedBy(insuranceActualTotal).toString(), 2)
                beforeTotal = common.numberHalfUp(new BigNumber(beforeTotal).plus(money).toString(), 2)
            }
            if (item.type === REPAIR_ITEM_TYPE_PROJECT) {
                //工时费
                customerProjectMoney = common.numberHalfUp(new BigNumber(customerProjectMoney).plus(money).toString(), 2)
            }
            if (item.type === REPAIR_ITEM_TYPE_PRODUCT) {
                //材料费
                customerProductMoney = common.numberHalfUp(new BigNumber(customerProductMoney).plus(money).toString(), 2)
            }
        })

        //设置客户折扣
        setCustomerDiscountMoney(customerDiscountMoney)
        //设置客户自费
        setCustomerTotal(customerTotal)
        //设置自费小计 = 客户自费 - 客户折扣   + 自费保险 - 挂帐金额
        customerPayTotal = common.numberHalfUp(new BigNumber(customerTotal).minus(customerDiscountMoney).plus(customerOfInsuranceMoney).minus(accountPartnerMoney).toString(), 2)
        setCustomerPayTotal(customerPayTotal)
        setCustomerProjectMoney(customerProjectMoney)
        setCustomerProductMoney(customerProductMoney)
    }, [customerProjects, customerProducts, customerOfInsuranceMoney, accountPartnerMoney])


    //只要保险工时、保险材料、自费保险发生变化就设置保险的统计信息
    useEffect(() => {
        // 合并保险工时和保险材料,如果保险明细不为空则设置保险信息
        let insuranceRepairItems = [...insuranceProjects, ...insuranceProducts]
        //重置保险统计的信息
        insuranceTotal = "0.00" //维修保险
        insuranceDiscountMoney = "0.00" //保险折扣
        insurancePayTotal = "0.00" //保险小计
        let insuranceActualTotal = "0.00"  //保险实际总费用 =  保险小计 +  自费保险
        insuranceProjectMoney = "0.00" //保险工时费
        insuranceProductMoney = "0.00" //保险材料费
        insuranceRepairItems.forEach(item => {
            // 维修保险 = 销售单价 * 数量 （salePrice * quantity）
            insuranceTotal = common.numberHalfUp(new BigNumber(insuranceTotal).plus(new BigNumber(item.salePrice).multipliedBy(item.quantity)).toString(), 2)
            // 保险实际总费用 = 折后总价 （total）
            insuranceActualTotal = common.numberHalfUp(new BigNumber(insuranceActualTotal).plus(item.total).toString(), 2)
        })
        //设置保险折扣 = 维修保险 - 保险实际总费用
        insuranceDiscountMoney = common.numberHalfUp(new BigNumber(insuranceTotal).minus(insuranceActualTotal).toString(), 2)
        setInsuranceDiscountMoney(insuranceDiscountMoney)
        //设置保险维修
        setInsuranceTotal(insuranceTotal)
        //设置保险小计 = 维修保险 - 保险折扣 - 自费保险
        insurancePayTotal = common.numberHalfUp(new BigNumber(insuranceTotal).minus(insuranceDiscountMoney).minus(customerOfInsuranceMoney).toString(), 2)
        setInsurancePayTotal(insurancePayTotal)

        //设置保险部分的工时费和材料费(减去客户保险自费部分)
        let beforeTotal = "0.00" // 用来记录最后一个之前的所有价格总和
        insuranceRepairItems.forEach((item, index) => {
            let customerOfInsuranceItemMoney
            let insuranceItemMoney
            if (index === insuranceRepairItems.length - 1) {
                customerOfInsuranceItemMoney = common.numberHalfUp(new BigNumber(customerOfInsuranceMoney).minus(beforeTotal).toString(), 2)
                insuranceItemMoney = common.numberHalfUp(new BigNumber(item.total).minus(customerOfInsuranceItemMoney).toString(), 2)
            } else {
                customerOfInsuranceItemMoney = common.numberHalfUp(new BigNumber(customerOfInsuranceMoney).multipliedBy(item.total).dividedBy(insuranceActualTotal).toString(), 2)
                beforeTotal = common.numberHalfUp(new BigNumber(beforeTotal).plus(customerOfInsuranceItemMoney).toString(), 2)
                insuranceItemMoney = common.numberHalfUp(new BigNumber(item.total).minus(customerOfInsuranceItemMoney).toString(), 2)
            }
            if (item.type === REPAIR_ITEM_TYPE_PROJECT) {
                //工时费
                insuranceProjectMoney = common.numberHalfUp(new BigNumber(insuranceProjectMoney).plus(insuranceItemMoney).toString(), 2)
            }
            if (item.type === REPAIR_ITEM_TYPE_PRODUCT) {
                //材料费
                insuranceProductMoney = common.numberHalfUp(new BigNumber(insuranceProductMoney).plus(insuranceItemMoney).toString(), 2)
            }
        })
        setInsuranceProjectMoney(insuranceProjectMoney)
        setInsuranceProductMoney(insuranceProductMoney)
    }, [insuranceProjects, insuranceProducts, customerOfInsuranceMoney])


    //只要索赔工时、索赔材料发生变化就设置设置索赔的统计信息
    useEffect(() => {
        // 合并索赔工时和索赔材料,如果索赔明细不为空则设置索赔信息
        let factoryRepairItems = [...factoryProjects, ...factoryProducts]
        if (factoryRepairItems) {
            //重置索赔统计的信息
            factoryTotal = "0.00" //厂商索赔
            factoryDiscountMoney = "0.00" //索赔折扣
            factoryPayTotal = "0.00" //索赔小计
            factoryProjectMoney = "0.00" //索赔工时费
            factoryProductMoney = "0.00" //索赔材料费
            factoryRepairItems.forEach(item => {
                // 厂商索赔 = 销售单价 * 数量 （salePrice * quantity）
                factoryTotal = common.numberHalfUp(new BigNumber(factoryTotal).plus(new BigNumber(item.salePrice).multipliedBy(item.quantity)).toString(), 2)
                // 索赔小计 = 折后总价 （total）
                factoryPayTotal = common.numberHalfUp(new BigNumber(factoryPayTotal).plus(item.total).toString(), 2)
                if (item.type === REPAIR_ITEM_TYPE_PROJECT) {
                    //索赔工时费
                    factoryProjectMoney = common.numberHalfUp(new BigNumber(factoryProjectMoney).plus(item.total).toString(), 2)
                }
                if (item.type === REPAIR_ITEM_TYPE_PRODUCT) {
                    //索赔材料费
                    factoryProductMoney = common.numberHalfUp(new BigNumber(factoryProductMoney).plus(item.total).toString(), 2)
                }
            })
            //设置索赔折扣 = 厂商索赔 - 索赔小计
            factoryDiscountMoney = common.numberHalfUp(new BigNumber(factoryTotal).minus(factoryPayTotal).toString(), 2)
            setFactoryDiscountMoney(factoryDiscountMoney)
            //设置厂商索赔
            setFactoryTotal(factoryTotal)
            //设置索赔小计
            setFactoryPayTotal(factoryPayTotal)
            setFactoryProjectMoney(factoryProjectMoney)
            setFactoryProductMoney(factoryProductMoney)
        }
    }, [factoryProjects, factoryProducts])


    //设置委托费用和委托小计
    useEffect(() => {
        if (otherRepairItems) {
            delegateTotal = "0.00"
            delegatePayTotal = "0.00"
            otherRepairItems.forEach(item => {
                delegateTotal = common.numberHalfUp(new BigNumber(delegateTotal).plus(new BigNumber(item.receiptPrice).multipliedBy(item.quantity)).toString(), 2)
                delegatePayTotal = common.numberHalfUp(new BigNumber(delegatePayTotal).plus(item.total).toString(), 2)
            })
            setDelegateTotal(delegateTotal)
            setDelegatePayTotal(delegatePayTotal)
        }
    }, [otherRepairItems])

    // 设置购买礼券
    useEffect(() => {
        couponPayTotal = 0;
        couponPlans.forEach(item => {
            // 设置每个礼券实际支付金额 礼券实际支付总金额 =  礼券单价 * 数量
            item.total = common.numberHalfUp(new BigNumber(item.price).multipliedBy(item.quantity).toString(), 2)
            // 设置礼券实际支付总金额 礼券实际支付总金额 +=  每条礼券的实际支付金额
            couponPayTotal = common.numberHalfUp(new BigNumber(couponPayTotal).plus(item.total).toString(), 2)
        })
        setCouponPayTotal(couponPayTotal)// 设置购买礼券的价格
    }, [couponPlans])


    // 只要工单id发生变化获取维修工单数据
    // 过滤预估材料并组装数据
    useEffect(() => {
            if (repairId
                && businessKinds.length > 0
                && deliveryPriceIsSalePriceByInsurance !== null
                && deliveryPriceIsSalePriceByFactory !== null
                && deliveryPriceIsSalePriceByCustomer !== null
            ) {
                // projectRepairItems = []
                // productRepairItems = []
                // otherRepairItems = []
                common.loadingStart()
                common.ajax('get', '/support/repair/findById?id=' + repairId)
                    .then(res => {
                            // 获取客户可以使用的卡券
                            getTickets(res.code)
                            // 获取预估材料的 业务类别 key:预估材料productID value:业务类别
                            let predictProductObj = res.repairItems
                                .filter(item => item.type === REPAIR_ITEM_TYPE_PREDICT_PRODUCT)
                                .reduce((obj, item) => {
                                    obj[item.productId] = item.businessKind;
                                    return obj
                                }, {})
                            // 过滤预估材料
                            res.repairItems = res.repairItems.filter(item => item.type !== REPAIR_ITEM_TYPE_PREDICT_PRODUCT)
                            // 组装数据
                            res.repairItems.forEach(item => {
                                // 设置手动比例
                                // item.manualRate = parseInt(item.manualDiscount / (item.salePrice * item.quantity) * 100)
                                item.manualRate = common.numberCut(new BigNumber(item.manualDiscount).multipliedBy(100).dividedBy(item.salePrice).dividedBy(item.quantity).toString(), 0)
                                // 设置维修明细的名字
                                item.name = item.projectName === "" ? item.productName : item.projectName
                                item.productName = item.projectName === "" ? item.productName : item.projectName
                                switch (item.type) {
                                    // 如果维修明细的类型是维修项目则添加到维修项目列表中
                                    case REPAIR_ITEM_TYPE_PROJECT:
                                        projectRepairItems.push(item)
                                        break
                                    // 如果维修明细的类型是维修材料则添加到维修材料列表中
                                    case REPAIR_ITEM_TYPE_PRODUCT:
                                        productRepairItems.push(item)
                                        break
                                    // 委外费用(如果维修明细的类型等于委外维修项目就存进其他费用
                                    case REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT:
                                        otherRepairItems.push(item)
                                        break
                                    // 如果维修明细的类型等于委外维修材料就存进其他费用
                                    case REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT:
                                        otherRepairItems.push(item)
                                        break
                                    // 如果维修明细的类型等于委内维修项目就存进其他费用
                                    case REPAIR_ITEM_TYPE_DELEGATE_PROJECT:
                                        otherRepairItems.push(item)
                                        break
                                    // 如果维修明细的类型等于委内维修材料就存进委外费用
                                    case REPAIR_ITEM_TYPE_DELEGATE_PRODUCT:
                                        otherRepairItems.push(item)
                                        break
                                    default:
                                        break
                                }
                            })
                            setProjectRepairItems([...projectRepairItems])
                            setProductRepairItems(productRepairItems.map(productRepairItem => {
                                // 设置预估材料的 默认业务类别(业务类别为空的时候)
                                if ((productRepairItem.businessKind === null || productRepairItem.businessKind === '') && predictProductObj.hasOwnProperty(productRepairItem.productId)) {
                                    productRepairItem.businessKind = predictProductObj[productRepairItem.productId]
                                    productRepairItem.isDefault = true
                                }
                                return productRepairItem
                            }))
                            // 变更的业务类别 重新计算总金额
                            for (let productRepairItem of productRepairItems) {
                                if (productRepairItem.isDefault !== undefined && productRepairItem.isDefault) {
                                    productBusinessChange(productRepairItem.businessKind, productRepairItem)
                                }
                            }
                            setOtherRepairItems([...otherRepairItems])
                            // 设置礼券费用礼券维修明细
                            setCouponRepairItems(res.plans)
                            // 设置维修工单
                            setRepair(res)
                        }
                    ).finally(() => {
                    common.loadingStop()
                })
            }
        }
        ,
        [repairId, businessKinds, deliveryPriceIsSalePriceByCustomer, deliveryPriceIsSalePriceByFactory, deliveryPriceIsSalePriceByInsurance]
    )


    // 只要工单id发生变化获取工单的预结算
    // 如果预结算不为空，设置预结算备注,检查是否存在付费类型为自费保险，有就设置自费保险因为自费保险只能通过预结算获取
    // 如果预结算为空、工单状态为已竣工、索赔公司不为空、 则设置索赔单的索赔厂商名称
    // 如果预结算为空、工单状态为已竣工、保险公司不为空、 则设置理赔单的保险公司名称
    useEffect(() => {
        // 存在工单id则获取预结算
        if (repair.id) {
            common.loadingStart()
            common.ajax('get', '/support/prepay/findListByRepairId?repairId=' + repairId)
                .then(res => {
                    prepays = res || []
                    setPrepays([...prepays]) // 设置预结算列表

                    // 如果预结算为空 则设置索赔单、理赔单
                    if (prepays.length === 0) {
                        if (repair.status === REPAIR_STATUS_SUPPORTED) {
                            // 如果索赔公司不为空设置索赔单
                            if (repair.factoryName !== "" && repair.factoryId !== "") {
                                // 设置索赔公司名称
                                form.setFieldsValue({factoryName: repair.factoryName || ""})
                                factoryClaim = {
                                    ...factoryClaim,
                                    partnerId: repair.factoryId,
                                    partnerName: repair.factoryName
                                }
                                setFactoryClaim(factoryClaim)
                            }

                            // 如果保险公司不为空设置理赔单
                            if (repair.partnerId !== "" && repair.partnerName !== "") {
                                // 设置保险公司的名称
                                form.setFieldsValue({insuranceName: repair.partnerName})
                                insuranceClaim = {
                                    ...insuranceClaim,
                                    partnerId: repair.partnerId,
                                    partnerName: repair.partnerName
                                }
                                setInsuranceClaim(insuranceClaim)
                            }
                        }
                    }
                    //如果预结算不为空
                    else {
                        //设置预结算备注和客户自费id
                        for (let i = 0; i < res.length; i++) {
                            if (res[i].payType === PAY_TYPE_CUSTOMER) {
                                setPrepaySpec(res[i].spec || "")
                                setCustomerPrepayId(res[i].id)
                                break
                            }
                        }

                        // 检查是否存在付费类型为保险，有就设置理赔单
                        prepays.forEach(item => {
                            // 如果付费类型为自费保险，有就设置自费保险因为自费保险只能通过预结算获取
                            if (item.payType === PAY_TYPE_INSURANCE_CUSTOMER) {
                                customerOfInsuranceMoney = item.total
                                insuranceClaim = {
                                    ...insuranceClaim,
                                    customerPayTotal: item.total,
                                }
                            }
                            // 设置理赔单的公司名称和理赔单
                            if (item.payType === PAY_TYPE_INSURANCE_COMPANY) {
                                // 设置保险公司的名称
                                form.setFieldsValue({insuranceName: item.partnerName})
                                insuranceClaim = {
                                    ...insuranceClaim,
                                    partnerId: item.partnerId,
                                    partnerName: item.partnerName,
                                }
                            }

                            // 设置理赔索赔单的公司名称和索赔单
                            if (item.payType === PAY_TYPE_FACTORY) {
                                // 设置索赔厂商公司的名称
                                form.setFieldsValue({factoryName: item.partnerName})
                                factoryClaim = {
                                    ...factoryClaim,
                                    partnerId: item.partnerId,
                                    partnerName: item.partnerName,
                                }
                            }
                        })
                        setCustomerOfInsuranceMoney(customerOfInsuranceMoney) //设置自费保险
                        setInsuranceClaim(insuranceClaim) // 设置理赔单
                        setFactoryClaim(factoryClaim) // 设置索赔单
                    }
                })
                .finally(common.loadingStop)
        }
    }, [repair])


    // 获取挂帐单
    let findAccount = () => {
        common.loadingStart()
        common.ajax('get', '/support/account/findByRepairId?repairId=' + repairId)
            .then((res => {
                if (res) {
                    account = res
                    setAccount(account)
                    //设置费用确认
                    form.setFieldsValue({customerAccount: account.payerName + " ￥" + account.money + " 挂帐"})
                    //设置挂帐金额
                    setAccountPartnerMoney(account.money)
                }
            }))
            .finally(common.loadingStop)
    }
    useEffect(() => {
        if (repairId) {
            findAccount()
        }
    }, [repairId])


    // 获取财务信息
    useEffect(() => {
        if (repair.status > REPAIR_STATUS_PREPAID && customerPrepayId !== "" && new BigNumber(customerPayTotal).isGreaterThan(0)) {
            common.loadingStart()
            common.ajax('get', '/finance/receivable/findByTypeBusinessId', {
                businessId: customerPrepayId,
                type: 2
            }).then((res) => {
                setFinance(res || initFinance)
            }).finally(common.loadingStop)
        }
    }, [customerPrepayId, customerPayTotal, repair])

    //设置客户的费用确认单
    useEffect(() => {
        if (account) {
            //设置费用确认单
            customerClaim = {
                ...customerClaim,
                customerName: repair.customerName,
                employeeName: repair.employeeName,
                partnerId: account.payerId,
                partnerName: account.payerName,
                name: account.name,
                phone: account.phone,
                agreementCode: account.agreementCode,
                startAt: account.startAt,
                endAt: account.endAt,
                categoryId: account.categoryId,
                accountPartnerMoney: account.money,
                accountPartnerMoneyRate: account.rate,
                spec: account.spec,
            }
            setCustomerClaim(customerClaim)
        } else {
            customerClaim = {
                ...customerClaim,
                customerName: repair.customerName,
                employeeName: repair.employeeName,
            }
            setCustomerClaim(customerClaim)
        }
    }, [repair, account])


    /**
     * 获取卡券回收
     * @param businessCode  工单编号
     */
    let getTickets = (businessCode) => {
        let params = {
            businessCode: businessCode,
            ownerId: common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/coupon/ticket/findByBusinessCode', params)
            .then((res) => {
                res.forEach(item => {
                    item.quantity = 1
                })
                setRecoveredTickets([...res])
            })
            .finally(() => {
                common.loadingStop()
            })
    }


    // 设置礼券费用礼券维修明细
    let setCouponRepairItems = (plans) => {
        couponPlans = [] // 设置礼券维修明细 为空
        plans.forEach(item => {
            // 设置每个礼券实际支付金额 礼券实际支付总金额 =  礼券单价 * 数量
            item.total = common.numberHalfUp(new BigNumber(item.price).multipliedBy(item.quantity).toString(), 2)
            // 设置礼券实际支付总金额 礼券实际支付总金额 +=  每条礼券的实际支付金额
            couponPayTotal = common.numberHalfUp(new BigNumber(couponPayTotal).plus(item.total).toString(), 2)
            couponPlans.push(item)
        })
        setCouponPayTotal(couponPayTotal)// 设置购买礼券的价格
        setCouponPlans([...couponPlans]) // 设置礼券明细
    }


    // 设置累计折扣
    useEffect(() => {
        discountTotal = common.numberHalfUp(new BigNumber(customerDiscountMoney).plus(factoryDiscountMoney).plus(insuranceDiscountMoney).toString(), 2)
        setDiscountTotal(discountTotal)
    }, [customerDiscountMoney, factoryDiscountMoney, insuranceDiscountMoney])

    // 确认预结
    let onFinish = () => {
        common.confirm("确认预结算吗?", () => {
            let businessKindSelected = true
            repair.repairItems.forEach(item => {
                if (item.businessKind === "") {
                    businessKindSelected = false
                }
            })
            if (businessKindSelected) {
                let isZero = false
                otherRepairItems.forEach(item => {
                    if (item.price === "0.00") {
                        isZero = true;
                    }
                })
                if (isZero) {
                    common.confirm("其他费用的销售价格为0,确定提交?", () => {
                        handlePrepays()
                    })
                } else {
                    handlePrepays()
                }
            } else {
                common.alert("业务类别不能为空")
            }
        })
    }


    // 处理客户费用确认单
    let handleCustomerClaim = (claim) => {
        setCustomerClaim(claim)// 设置理赔工单
        if (new BigNumber(claim.accountPartnerMoney).isGreaterThan(0)) {
            // 设置费用确认
            form.setFieldsValue({customerAccount: "￥" + claim.accountPartnerMoney + " 挂帐于" + claim.partnerName})
        }
        // 设置挂帐金额
        accountPartnerMoney = claim.accountPartnerMoney || "0.00"
        setAccountPartnerMoney(accountPartnerMoney)
        // 设置客户折扣 = 手动折扣 + 会员卡折扣 + 礼券折扣
        customerDiscountMoney = common.numberHalfUp(new BigNumber(claim.discountTotalMoney).plus(cardDiscountMoney).toString(), 2)
        setCustomerDiscountMoney(customerDiscountMoney)
        // 设置自费小计
        customerPayTotal = common.numberHalfUp(new BigNumber(customerTotal).minus(customerDiscountMoney).minus(accountPartnerMoney).toString(), 2)
        setCustomerPayTotal(customerPayTotal)

        setCustomerClaimVisible(false)
    }


    // 处理理赔单
    let handleInsuranceClaim = (claim) => {
        setInsuranceClaim(claim)// 设置理赔工单
        setInsuranceDiscountMoney(common.numberHalfUp(new BigNumber(claim.discountTotalMoney).toString(), 2)) // 设置保险折扣
        setCustomerOfInsuranceMoney(common.numberHalfUp(new BigNumber(claim.customerPayTotal).toString(), 2)) // 设置自费保险
        setInsurancePayTotal(common.numberHalfUp(claim.partnerPayTotal, 2))// 设置保险小计
        form.setFieldsValue({insuranceName: claim.partnerName}) // 设置保险公司的名称
        setInsuranceClaimVisible(false)
    }


    // 处理索赔单
    let handleFactoryClaim = (claim) => {
        setFactoryClaim(claim)// 设置索赔工单
        form.setFieldsValue({factoryName: claim.partnerName}) // 设置索赔公司的名称
        setFactoryDiscountMoney(common.numberHalfUp(new BigNumber(claim.discountTotalMoney).toString(), 2)) // 设置厂家索赔折扣
        setFactoryPayTotal(common.numberHalfUp(new BigNumber(claim.actualTotal).toString(), 2))// 设置厂家索赔小计
        setFactoryClaimVisible(false)
    }

    // 分组
    const groupBy = (list, fn) => {
        const groups = {};
        list.forEach(function (o) {
            const group = JSON.stringify(fn(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);
        });
        return groups;
    }


    // 设置工时费用合计
    useEffect(() => {
        let quantity = "0.00"
        let receiptTotal = "0.00"
        let saleTotal = "0.00"
        let total = "0.00"
        let dispatchTime = "0.00"// 派工工时
        let couponDiscount = "0.00"
        let cardDiscount = "0.00"
        let manualDiscount = "0.00"
        projectRepairItems.forEach(item => {
            // 派工工时
            if (item.dispatchTime) {
                dispatchTime = common.numberHalfUp(new BigNumber(item.dispatchTime).plus(dispatchTime).toString(), 2)
            }
            quantity = common.numberHalfUp(new BigNumber(item.quantity).plus(quantity).toString(), 2)
            receiptTotal = common.numberHalfUp(new BigNumber(receiptTotal).plus(new BigNumber(item.receiptPrice).multipliedBy(item.quantity)).toString(), 2)
            saleTotal = common.numberHalfUp(new BigNumber(saleTotal).plus(new BigNumber(item.salePrice).multipliedBy(item.quantity)).toString(), 2)
            total = common.numberHalfUp(new BigNumber(total).plus(item.total).toString(), 2)
            couponDiscount = common.numberHalfUp(new BigNumber(couponDiscount).plus(item.couponDiscount).toString(), 2)
            cardDiscount = common.numberHalfUp(new BigNumber(cardDiscount).plus(item.cardDiscount).toString(), 2)
            manualDiscount = common.numberHalfUp(new BigNumber(manualDiscount).plus(item.manualDiscount).toString(), 2)
        })
        let projectSubtotalObj = {
            quantity: quantity,
            receiptTotal: receiptTotal,
            saleTotal: saleTotal,
            total: total,
            dispatchTime: dispatchTime,
            couponDiscount: couponDiscount,
            cardDiscount: cardDiscount,
            manualDiscount: manualDiscount,
        }
        setProjectSubtotal(projectSubtotalObj)
    }, [projectRepairItems, customerProjects, insuranceProjects, factoryProjects])


    // 设置材料费用合计
    useEffect(() => {
        let quantity = "0.00"
        let receiptTotal = "0.00"
        let deliveryTotal = "0.00"
        let saleTotal = "0.00"
        let total = "0.00"
        let couponDiscount = "0.00"
        let cardDiscount = "0.00"
        let manualDiscount = "0.00"
        productRepairItems.forEach(item => {
            quantity = common.numberHalfUp(new BigNumber(item.quantity).plus(quantity).toString(), 2)
            receiptTotal = common.numberHalfUp(new BigNumber(receiptTotal).plus(new BigNumber(item.receiptPrice).multipliedBy(item.quantity)).toString(), 2)
            saleTotal = common.numberHalfUp(new BigNumber(saleTotal).plus(new BigNumber(item.salePrice).multipliedBy(item.quantity)).toString(), 2)
            deliveryTotal = common.numberHalfUp(new BigNumber(deliveryTotal).plus(new BigNumber(item.deliveryPrice).multipliedBy(item.quantity)).toString(), 2)
            total = common.numberHalfUp(new BigNumber(total).plus(item.total).toString(), 2)
            couponDiscount = common.numberHalfUp(new BigNumber(couponDiscount).plus(item.couponDiscount).toString(), 2)
            cardDiscount = common.numberHalfUp(new BigNumber(cardDiscount).plus(item.cardDiscount).toString(), 2)
            manualDiscount = common.numberHalfUp(new BigNumber(manualDiscount).plus(item.manualDiscount).toString(), 2)
        })
        productSubtotal = {
            quantity: quantity,
            receiptTotal: receiptTotal,
            deliveryTotal: deliveryTotal,
            saleTotal: saleTotal,
            total: total,
            couponDiscount: couponDiscount,
            cardDiscount: cardDiscount,
            manualDiscount: manualDiscount,
        }
        setProductSubtotal({...productSubtotal})
    }, [productRepairItems, customerProducts, insuranceProducts, factoryProducts])


    // 设置协修工单费用合计
    useEffect(() => {
        let quantity = "0.00"
        let receiptTotal = "0.00"
        let saleTotal = "0.00"
        let total = "0.00"
        let couponDiscount = "0.00"
        let cardDiscount = "0.00"
        let manualDiscount = "0.00"
        otherRepairItems.forEach(item => {
            quantity = common.numberHalfUp(new BigNumber(quantity).plus(item.quantity).toString(), 2)
            receiptTotal = common.numberHalfUp(new BigNumber(receiptTotal).plus(new BigNumber(item.receiptPrice).multipliedBy(item.quantity)).toString(), 2)
            saleTotal = common.numberHalfUp(new BigNumber(saleTotal).plus(new BigNumber(item.salePrice).multipliedBy(item.quantity)).toString(), 2)
            total = common.numberHalfUp(new BigNumber(total).plus(item.total).toString(), 2)
            couponDiscount = common.numberHalfUp(new BigNumber(couponDiscount).plus(item.couponDiscount).toString(), 2)
            cardDiscount = common.numberHalfUp(new BigNumber(cardDiscount).plus(item.cardDiscount).toString(), 2)
            manualDiscount = common.numberHalfUp(new BigNumber(manualDiscount).plus(item.manualDiscount).toString(), 2)
        })
        otherSubtotal = {
            quantity: quantity,
            receiptTotal: receiptTotal,
            saleTotal: saleTotal,
            total: total,
            couponDiscount: couponDiscount,
            cardDiscount: cardDiscount,
            manualDiscount: manualDiscount,
        }
        setOtherSubtotal({...otherSubtotal})
    }, [otherRepairItems, customerProjects, customerProducts, insuranceProjects, insuranceProducts, factoryProjects, factoryProducts])

    // 设置礼券费用合计
    useEffect(() => {
        let quantity = "0.00"
        let price = "0.00"
        let total = "0.00"
        couponPlans.forEach(item => {
            quantity = common.numberHalfUp(new BigNumber(quantity).plus(item.quantity).toString(), 2)
            price = common.numberHalfUp(new BigNumber(price).plus(item.price).toString(), 2)
            total = common.numberHalfUp(new BigNumber(total).plus(new BigNumber(item.price).multipliedBy(item.quantity)).toString(), 2)
        })
        couponSubtotal = {
            quantity: quantity,
            total: total,
            price: price,
        }
        setCouponSubtotal(couponSubtotal)
    }, [couponPlans])


    /**
     * 设置客户自费和挂帐（挂帐信息）的预结算
     * @returns {*[]}
     * 返回 客户自费的预结算
     */
    let handleCustomerPrepay = () => {
        let prepays = []
        // 设置客户自费预结算明细
        let customerPrepayItems = [] // 设置客户自费的预结算明细

        // 过滤掉价格为 0 的 客户维修项目
        customerProjects = customerProjects.filter(customerProject => customerProject.total !== 0 ? customerProject : null)

        // 设置客户的维修项目的预结算明细
        customerProjects.forEach(item => {
            customerPrepayItems.push({
                repairItemId: item.id,
                type: REPAIR_ITEM_TYPE_PROJECT,
                money: item.total,
                name: item.projectName,
                status: STATUS_ENABLE,
            })
        })
        // 过滤掉价格为 0 的 客户维修材料
        customerProducts = customerProducts.filter(customerProduct => customerProduct.total !== 0 ? customerProduct : null)
        // 设置客户的维修材料的预结算明细
        customerProducts.forEach(item => {
            customerPrepayItems.push({
                repairItemId: item.id,
                type: REPAIR_ITEM_TYPE_PRODUCT,
                money: item.total,
                name: item.productName,
                status: STATUS_ENABLE,
            })
        })

        // 设置挂帐预结算
        if (new BigNumber(accountPartnerMoney).isGreaterThan(0)) {
            let accountPrepayItems = JSON.parse(JSON.stringify(customerPrepayItems))
            //客户实际结算金额 = 客户自费 - 客户折扣
            let customerActualTotal = common.numberHalfUp(new BigNumber(customerTotal).minus(customerDiscountMoney).toString(), 2)
            let beforeTotal = "0.00" // 用来记录最后一个之前的所有价格总和
            accountPrepayItems.forEach((item, index) => {
                if (index === accountPrepayItems.length - 1) {
                    item.money = common.numberHalfUp(new BigNumber(accountPartnerMoney).minus(beforeTotal).toString(), 2)
                } else {
                    item.money = common.numberHalfUp(new BigNumber(accountPartnerMoney).multipliedBy(item.money).dividedBy(customerActualTotal).toString(), 2)
                    beforeTotal = common.numberHalfUp(new BigNumber(beforeTotal).plus(item.money).toString(), 2)
                }
            })
            accountPrepay = {
                ownerId: common.getUser().company.id,
                repairId: repairId,
                partnerId: customerClaim.partnerId,
                partnerName: customerClaim.partnerName,
                partnerEmployee: "",
                dms: "",
                total: accountPartnerMoney,
                status: STATUS_ENABLE,
                payType: PAY_TYPE_ACCOUNT,
                prepayItems: accountPrepayItems,
                spec: "",
            }
            setAccountPrepay(accountPrepay)
            prepays = [{...accountPrepay}]

            // 设置客户自费预结算明细
            customerPrepayItems.forEach(item => {
                accountPrepayItems.forEach(accountPrepayItem => {
                    if (item.repairItemId === accountPrepayItem.repairItemId) {
                        item.money = common.numberHalfUp(new BigNumber(item.money).minus(accountPrepayItem.money).toString(), 2)
                    }
                })
            })

            //设置挂帐信息
            account = {
                ownerId: common.getUser().company.id,
                repairId: repair.id,
                payerId: customerClaim.partnerId,
                payerName: customerClaim.partnerName,
                name: customerClaim.name,
                phone: customerClaim.phone,
                agreementCode: customerClaim.agreementCode,
                startAt: customerClaim.startAt,
                endAt: customerClaim.endAt,
                categoryId: customerClaim.categoryId,
                money: customerClaim.accountPartnerMoney,
                spec: customerClaim.spec,
            }
            setAccount(account)
        }

        // 过滤掉价格为 0 的 客户礼券
        couponPlans = couponPlans.filter(coupon => coupon.total !== 0 ? coupon : null)

        // 设置客户的礼券的预结算明细
        couponPlans.forEach(item => {
            customerPrepayItems.push({
                repairItemId: item.id,
                type: REPAIR_ITEM_TYPE_COUPON,
                money: item.total,
                name: item.name,
                status: STATUS_ENABLE,
            })
        })

        // 客户自费预结算
        customerPrepay = {
            // 设置客户自费支付总金额 客户自费支付总金额 = 客户小计自费(客户实际支付总金额) + 礼券支付总金额 - 保险理赔-客户自费部分金额
            total: common.numberHalfUp(new BigNumber(customerPayTotal).plus(couponPayTotal).minus(customerOfInsuranceMoney).toString(), 2),
            ownerId: common.getUser().company.id,
            repairId: repairId,
            partnerId: repair.type === REPAIR_TYPE_DELEGATE ? repair.consignorCompanyId : repair.customerId,
            partnerName: repair.type === REPAIR_TYPE_DELEGATE ? repair.name : repair.customerName,
            partnerEmployee: "",
            dms: "",
            status: STATUS_ENABLE,
            payType: PAY_TYPE_CUSTOMER,
            prepayItems: customerPrepayItems, // 设置客户自费预结算明细
            spec: prepaySpec, // 预结算备注
        }
        setCustomerPrepay({...customerPrepay})

        return [...prepays, {...customerPrepay}]
    }


    /**
     * 设置委外预结算
     * @returns
     * {{total: number, partnerEmployee: string, payType, partnerName, repairId, partnerId,
     * dms: string, ownerId: string, prepayItems, spec: string, status}}
     * 返回 委外预结算
     */
    let setOutsourcePrepay = (outsourceItems) => {
        let outsourcePrepays = []
        // 按受托方进行分组
        let groupData = groupBy(outsourceItems, (outsourceItems) => {
            return outsourceItems.fiduciaryCompanyId
        })
        Object.keys(groupData).forEach(key => {
            let outsourcePrepayItems = []
            let total = 0
            groupData[key].forEach(repairItem => {
                // 如果维修明细的业务类型 是 委外维修项目 或者 委外维修材料
                total = common.numberHalfUp(new BigNumber(total)
                    .plus(new BigNumber(repairItem.receiptPrice)
                        .multipliedBy(repairItem.quantity)).toString(), 2)

                outsourcePrepayItems.push({
                    repairItemId: repairItem.id,
                    type: repairItem.type,
                    money: common.numberHalfUp(new BigNumber(repairItem.receiptPrice)
                        .multipliedBy(repairItem.quantity)
                        .toString(), 2),
                    name: repairItem.name,
                    status: STATUS_ENABLE,
                })
            })

            // 设置委外的预结算
            outsourcePrepays.push(
                {
                    total: total,
                    ownerId: common.getUser().company.id,
                    repairId: repairId,
                    partnerId: groupData[key][0].fiduciaryCompanyId,
                    partnerName: groupData[key][0].fiduciaryCompanyName,
                    partnerEmployee: "",
                    dms: "",
                    status: STATUS_ENABLE,
                    payType: PAY_TYPE_OUTSOURCE,
                    prepayItems: outsourcePrepayItems,
                    spec: "",
                }
            )
        })

        return outsourcePrepays
    }


    /**
     * 设置委内预结算
     * @returns
     * {{total: number, partnerEmployee: string, payType, partnerName, repairId,
     * partnerId, dms: string, ownerId: string, prepayItems, spec: string, status}}
     * 返回 委内预结算
     */
    let setDelegatePrepay = () => {
        let delegatePrepayItems = [] // 设置委内预结算明细
        let total = 0
        otherRepairItems.forEach(repairItem => {
            // 如果维修明细的业务类型 为 委内维修项目 或者 委内维修材料
            if (repairItem.type === REPAIR_ITEM_TYPE_DELEGATE_PROJECT
                || repairItem.type === REPAIR_ITEM_TYPE_DELEGATE_PRODUCT) {
                total = common.numberHalfUp(new BigNumber(total)
                    .plus(new BigNumber(repairItem.receiptPrice)
                        .multipliedBy(repairItem.quantity)).toString(), 2)
                delegatePrepayItems.push({
                    repairItemId: repairItem.id,
                    type: repairItem.type,
                    money: common.numberHalfUp(new BigNumber(repairItem.receiptPrice)
                        .multipliedBy(repairItem.quantity).toString(), 2),
                    name: repairItem.name,
                    status: STATUS_ENABLE,
                })
            }
        })
        // 设置委内的预结算
        return {
            total: total,
            ownerId: common.getUser().company.id,
            repairId: repairId,
            partnerId: repair.fiduciaryCompanyId,
            partnerName: repair.fiduciaryCompanyName,
            partnerEmployee: "",
            dms: "",
            status: STATUS_ENABLE,
            payType: PAY_TYPE_DELEGATE, // 委内
            prepayItems: delegatePrepayItems,
            spec: "",
        }
    }

    // 设置索赔厂商的预结算
    let handleFactoryPrepay = () => {
        // 初始化的预结算明细
        let prepayItems = []
        factoryProjects.forEach(item => {
            prepayItems.push({
                repairItemId: item.id,
                type: REPAIR_ITEM_TYPE_PROJECT,
                money: item.total,
                name: item.projectName,
                status: STATUS_ENABLE,
            })
        })
        factoryProducts.forEach(item => {
            prepayItems.push({
                repairItemId: item.id,
                type: REPAIR_ITEM_TYPE_PRODUCT,
                name: item.productName,
                money: item.total,
                status: STATUS_ENABLE,
            })
        })

        // 索赔厂商的预结算
        factoryPrepay = {
            ownerId: common.getUser().company.id,
            repairId: repairId,
            partnerId: factoryClaim.partnerId,
            partnerName: factoryClaim.partnerName,
            partnerEmployee: "",
            dms: "",
            total: factoryPayTotal, // 索赔小计
            status: STATUS_ENABLE,
            prepayItems: prepayItems,
            payType: PAY_TYPE_FACTORY,
            spec: "",
        }
        setFactoryPrepay({...factoryPrepay})
    }

    // 设置保险公司的预结算
    let handleInsurancePrepay = () => {
        // 初始化的预结算明细
        let prepayItems = []
        insuranceProjects.forEach(item => {
            prepayItems.push({
                repairItemId: item.id,
                type: REPAIR_ITEM_TYPE_PROJECT,
                money: item.total,
                name: item.projectName,
                status: STATUS_ENABLE,
            })
        })
        insuranceProducts.forEach(item => {
            prepayItems.push({
                repairItemId: item.id,
                type: REPAIR_ITEM_TYPE_PRODUCT,
                name: item.productName,
                money: item.total,
                status: STATUS_ENABLE,
            })
        })

        // 保险客户-自费部分预结算
        let customerPrepayItems = JSON.parse(JSON.stringify(prepayItems))
        //保险实际支付金额
        let insuranceActualTotal = common.numberHalfUp(new BigNumber(insuranceTotal).minus(insuranceDiscountMoney).toString(), 2)
        let beforeTotal = "0.00" // 用来记录最后一个之前的所有价格总和
        customerPrepayItems.forEach((item, index) => {
            if (index === customerPrepayItems.length - 1) {
                item.money = common.numberHalfUp(new BigNumber(customerOfInsuranceMoney).minus(beforeTotal).toString(), 2)
            } else {
                item.money = common.numberHalfUp(new BigNumber(customerOfInsuranceMoney).multipliedBy(item.money).dividedBy(insuranceActualTotal).toString(), 2)
                beforeTotal = common.numberHalfUp(new BigNumber(beforeTotal).plus(item.money).toString(), 2)
            }
        })

        // 设置保险公司预结算明细
        prepayItems.forEach(item => {
            customerPrepayItems.forEach(customerPrepayItem => {
                if (item.repairItemId === customerPrepayItem.repairItemId) {
                    item.money = common.numberHalfUp(new BigNumber(item.money).minus(customerPrepayItem.money).toString(), 2)
                }
            })
        })

        // 保险公司的预结算
        insurancePrepay = {
            ownerId: common.getUser().company.id,
            repairId: repairId,
            partnerId: insuranceClaim.partnerId,
            partnerName: insuranceClaim.partnerName,
            partnerEmployee: "",
            dms: "",
            total: insurancePayTotal, // 索赔小计
            status: STATUS_ENABLE,
            prepayItems: prepayItems,
            payType: PAY_TYPE_INSURANCE_COMPANY,
            spec: "",
        }
        setInsurancePrepay(insurancePrepay)
    }


    //设置保险-客户自费部分预结算
    let handleCustomerOfInsurancePrepay = () => {
        // 初始化的预结算明细
        let prepayItems = []
        insuranceProjects.forEach(item => {
            prepayItems.push({
                repairItemId: item.id,
                type: REPAIR_ITEM_TYPE_PROJECT,
                money: item.total,
                name: item.projectName,
                status: STATUS_ENABLE,
            })
        })
        insuranceProducts.forEach(item => {
            prepayItems.push({
                repairItemId: item.id,
                type: REPAIR_ITEM_TYPE_PRODUCT,
                name: item.productName,
                money: item.total,
                status: STATUS_ENABLE,
            })
        })

        // 保险客户-自费部分预结算
        let customerPrepayItems = JSON.parse(JSON.stringify(prepayItems))
        //保险实际支付金额
        let insuranceActualTotal = common.numberHalfUp(new BigNumber(insuranceTotal).minus(insuranceDiscountMoney).toString(), 2)
        let beforeTotal = "0.00" // 用来记录最后一个之前的所有价格总和
        customerPrepayItems.forEach((item, index) => {
            if (index === customerPrepayItems.length - 1) {
                item.money = common.numberHalfUp(new BigNumber(customerOfInsuranceMoney).minus(beforeTotal).toString(), 2)
            } else {
                item.money = common.numberHalfUp(new BigNumber(customerOfInsuranceMoney).multipliedBy(item.money).dividedBy(insuranceActualTotal).toString(), 2)
                beforeTotal = common.numberHalfUp(new BigNumber(beforeTotal).plus(item.money).toString(), 2)
            }
        })
        customerOfInsurancePrepay = {
            ownerId: common.getUser().company.id,
            repairId: repairId,
            partnerId: repair.customerId,
            partnerName: repair.customerName,
            partnerEmployee: "",
            dms: "",
            total: customerOfInsuranceMoney,
            status: STATUS_ENABLE,
            payType: PAY_TYPE_INSURANCE_CUSTOMER,
            prepayItems: customerPrepayItems,
            spec: '',
        }

        setCustomerOfInsurancePrepay({...customerOfInsurancePrepay})
    }

    // 处理预结算
    let handlePrepays = () => {
        // 设置预结算数据为空
        prepays = []
        // 设置客户预结算
        let customerPrepays = handleCustomerPrepay()
        prepays = [...prepays, ...customerPrepays]

        // 如果委外维修记录不为空 设置 委外预结算
        let outsourceItems = otherRepairItems.filter(item =>
            item.type === REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT ||
            item.type === REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT
        )

        if (outsourceItems.length !== 0) {
            let outsourcePrepays = setOutsourcePrepay(outsourceItems)
            prepays = [...prepays, ...outsourcePrepays]
        }

        // 如果委内Id不为空 设置 委内预结算
        if (repair.delegateId !== "") {
            let delegatePrepay = setDelegatePrepay()
            prepays = [...prepays, {...delegatePrepay}]
        }

        // 如果索赔小计不为空 设置 索赔预结算
        if (factoryPayTotal !== "0.00") {
            handleFactoryPrepay()
            prepays = [...prepays, {...factoryPrepay}]
        }

        //如果客户自费保险不为0 设置客户自费保险部分预结算
        if (new BigNumber(customerOfInsuranceMoney).isGreaterThan(0)) {
            handleCustomerOfInsurancePrepay()
            prepays = [...prepays, {...customerOfInsurancePrepay}]
        }

        // 如果保险小计不为0 设置 保险预结算
        if (insurancePayTotal !== "0.00") {
            handleInsurancePrepay()
            prepays = [...prepays, {...insurancePrepay}]
        }

        setPrepays([...prepays])

        // 设置新增预结算
        createPrepay()
    }

    /**
     * 获取礼券方案
     * 返回 plans [{id:"xxx", quantity:1}]
     */
    let getCouponPlans = () => {
        let plans = [];
        couponPlans.forEach(item => {
            plans.push({id: item.id, quantity: item.quantity})
        })
        return plans
    }

    // 新增预结算
    let createPrepay = () => {
        common.loadingStart()
        common.ajax(
            'post',
            '/support/prepay/create',
            {
                repairId: repair.id,
                ownerId: common.getUser().company.id,
                prepayList: prepays,
                repairItems: [...projectRepairItems, ...productRepairItems, ...otherRepairItems],
                ticketIds: ticketIds,
                account: account,
                plans: getCouponPlans(),
            }).then(() => {
            common.toast("预结算成功")
            setRepair({...repair, status: REPAIR_STATUS_PREPAID}) // 设置工单状态为预结算
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 取消预结算
    let cancelPrepays = () => {
        // 修改订单状态
        repair.status = REPAIR_STATUS_SUPPORTED // 订单状态修改为已竣工
        common.loadingStart()
        common.ajax('post', '/support/repair/cancel', {
            ownerId: common.getUser().company.id,
            id: repairId,
            cancelType: "prepay",
        })
            .then(() => {
                    common.toast("取消预结算成功")
                    history.goBack()
                }
            ).finally(() => {
            common.loadingStop()
        })
    }

    // 设置礼券方案信息
    let handleCouponPlans = (value) => {
        setLookupCouponPlanVisible(false) // 关闭弹窗
        // 列表为空时，直接赋值
        if (couponPlans === []) {
            for (let i = 0; i < value.length; i++) {
                value[i].quantity = 1
                value[i].total = new BigNumber(value[i].quantity).multipliedBy(value[i].price).toString()
                couponPlans.push(value[i])
            }
            setCouponPlans([...couponPlans])
        } else {
            // 过滤重复的礼券方案
            let couponPlanIds = couponPlans.map(couponPlan => couponPlan.id)
            for (let i = 0; i < value.length; i++) {
                if (!couponPlanIds.includes(value[i].id)) {
                    value[i].quantity = 1
                    value[i].total = new BigNumber(value[i].quantity)
                        .multipliedBy(value[i].price).toString()
                    couponPlans.push(value[i])
                }
            }
        }
        // 设置维修工单的礼券信息
        repair.plans = couponPlans
        setRepair({...repair})
        // 设置礼券小计
        setCouponPlans([...couponPlans])
    }

    // 获取客户会员类型
    useEffect(() => {
        // 客户id不为空且工单是普通工单
        if (repair.customerId !== "" && repair.type === REPAIR_TYPE_NORMAL) {
            common.loadingStart()
            let params = {
                ownerId: common.getUser().company.id,
                customerId: repair.customerId,
            }
            common.ajax('get', '/passport/vip/findVipUser', params)
                .then(res => {
                    if (res.vip === true) {
                        cardDiscountMoney = "0.00"
                        vip = true
                        discountProject = res.vipLevel.discountProject
                        discountProduct = res.vipLevel.discountProduct
                        vipLevelName = res.vipLevel.name
                        vipLevel = res.vipLevel
                        repair.repairItems.forEach(item => {
                            if (item.type === REPAIR_ITEM_TYPE_PROJECT && item.chargeType === CHARGE_TYPE_CUSTOMER) {
                                // 会员卡折扣
                                item.cardDiscount = common.numberHalfUp(new BigNumber(item.salePrice)
                                    .multipliedBy(item.quantity).multipliedBy(discountProject).toString(), 2)
                                // 累加会员折扣
                                cardDiscountMoney = common.numberHalfUp(new BigNumber(cardDiscountMoney).plus(item.cardDiscount).toString(), 2)
                                // 实际支付总金额
                                item.total = common.numberHalfUp(new BigNumber(item.salePrice)
                                    .multipliedBy(item.quantity).minus(item.cardDiscount).minus(item.couponDiscount)
                                    .minus(item.manualDiscount).toString(), 2)
                                // 实际支付单价
                                item.price = common.numberHalfUp(new BigNumber(item.total)
                                    .dividedBy(item.quantity).toString(), 2)
                            }
                            if (item.type === REPAIR_ITEM_TYPE_PRODUCT && item.chargeType === CHARGE_TYPE_CUSTOMER) {
                                // 会员卡折扣
                                item.cardDiscount = common.numberHalfUp(new BigNumber(item.salePrice)
                                    .multipliedBy(item.quantity).multipliedBy(discountProduct).toString(), 2)
                                // 累加会员折扣
                                cardDiscountMoney = common.numberHalfUp(new BigNumber(cardDiscountMoney).plus(item.cardDiscount).toString(), 2)
                                // 实际支付总金额
                                item.total = common.numberHalfUp(new BigNumber(item.salePrice)
                                    .multipliedBy(item.quantity).minus(item.cardDiscount).minus(item.couponDiscount)
                                    .minus(item.manualDiscount).toString(), 2)
                                // 实际支付单价
                                item.price = common.numberHalfUp(new BigNumber(item.total)
                                    .dividedBy(item.quantity).toString(), 2)
                            }
                        })
                        // 设置会员卡折扣
                        setCardDiscountMoney(cardDiscountMoney)
                        setVipLevel({...vipLevel})
                        setRepair({...repair})
                        setVip(vip)
                        setVipLevelName(vipLevelName)
                        setDiscountProject(discountProject)
                        setDiscountProduct(discountProduct)
                    }
                })
                .finally(common.loadingStop)
        }
    }, [repair.customerId])

    useEffect(() => {
        // 客户id不为空且工单是普通工单
        if (repair.customerId !== "") {
            common.loadingStart()
            common.ajax('get', '/passport/customer/findById?id=' + repair.customerId)
                .then(data => {
                    setCustomer({
                        provinceName: data.provinceName || "",
                        cityName: data.cityName || "",
                        areaName: data.areaName || "",
                        address: data.address || "",
                    })
                })
                .finally(common.loadingStop)
        }
    }, [repair.customerId])

    useEffect(() => {
        // 公司ID
        if (repair.ownerId !== undefined && repair.ownerId !== "") {
            common.loadingStart()
            common.ajax('get', '/passport/company/findById?id=' + repair.ownerId)
                .then(data => {
                    setCompanyObj({
                        provinceName: data.provinceName || "",
                        cityName: data.cityName || "",
                        areaName: data.areaName || "",
                        address: data.address || "",
                    })
                })
                .finally(common.loadingStop)
        }
    }, [repair.ownerId])

    // 处理折扣录入
    let saveDiscountReason = (discountReasons) => {
        if (discountReasons === null) {
            setReviewFormVisible(false)
            return
        }
        common.loadingStart()
        // 判断订单状态是否为预结算,是则可以录入折扣,不是则提示
        let params = {
            ownerId: common.getUser().company.id,
            repairId: repairId,
            discountReasons: discountReasons || []
        }
        common.ajax('post', '/support/discountReason/set', params)
            .then(() => {
                common.toast("折扣录入成功！")
                setReviewFormVisible(false)
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    // 获取打印logo
    let getPrintLogo = () => {
        common.loadingStart()
        common.ajax('get', '/support/images/list', {
            ownerId: common.getUser().company.id,
            type: SupportImages.TYPE_PRINT_LOGO
        })
            .then(res => {
                if (res.length > 0 && res[0].url !== "") {
                    setImageUrl(res[0].url)
                }
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    useEffect(() => {
        getPrintLogo()
    }, [])

    // 打印
    let print = () => {
        // 组装数据
        let data = {
            "code": "repair_prepay",
            "owner_id": common.getUser().company.id,
            "data": {
                logo: imageUrl || null,
                ...repair,
                companyName: common.getUser().company.name,
                projectRepairItems: projectRepairItems,
                productRepairItems: productRepairItems,
                total1: projectSubtotal.saleTotal,
                total2: productSubtotal.saleTotal,
                customerAddress: customer.provinceName + customer.cityName + customer.areaName + customer.address,// 客户地址
                companyAddress: companyObj.provinceName + companyObj.cityName + companyObj.areaName + companyObj.address,// 公司地址
                companySaleHotline: companyObj.saleHotline,
                companySupportHotline: companyObj.supportHotline,
                settlementDate: prepays.length > 0 ? prepays[0].createdAt : "", // 预结算日期,
                clearCreator: prepays.length > 0 ? prepays[0].creatorId : "", // 预结算人,
                salePrice: projectSubtotal.saleTotal,
                saleTotal: projectSubtotal.total,
                saleDiscount: projectSubtotal.manualDiscount,
                productPrice: productSubtotal.saleTotal,
                productTotal: productSubtotal.total,
                productDiscount: productSubtotal.manualDiscount,
                customerPayTotal: customerPayTotal
            }
        }
        common.loadingStart()
        common.ajax("post", "/ext/print/template/parse", data).then((res) => {
            common.print(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 定义一个截取字符串的函数
    const truncateString = (str, maxLength) => {
        if (str.length > maxLength) {
            return str.substring(0, maxLength - 3) + '...';
        }
        return str;
    };

    return (
        <React.Fragment>
            <PageTop title={'维修工单预结算'}>
                {CustomizationConstant.SUPPORT_STORE_TENANT_ID.includes(common.getUser().tenant.id) &&
                    <>
                    <span>
                        <Tooltip>
                            <Tag color="red"
                                 style={{
                                     maxWidth: '225px',
                                     whiteSpace: 'nowrap',
                                     display: 'inline-block' // 确保宽度生效
                                 }}>
                                {"车辆备注：" + truncateString(repair.vehicleSpec, 15)}
                            </Tag>
                        </Tooltip>
                    </span>
                        <span>
                        <Tag color="red">{"自编号：" + repair.vehicleSelfCode}</Tag>
                    </span>
                        <span>
                        <Tag color="red">{"是否包修：" + repair.vehicleIsWarranty}</Tag>
                    </span>
                        <span>
                        <Tag color="red">{"有效期至：" + (repair.vehicleValidUntil || "")}</Tag>
                    </span>
                        <span>
                        <Tag color="red">{"签约单位：" + repair.vehicleContractCompanyName}</Tag>
                    </span>
                    </>}
                <Button icon={<RollbackOutlined/>} onClick={() => {
                    Search.back()
                }}>返回
                </Button>
                {canReminder &&
                    <Button icon={<HistoryOutlined/>}
                            hidden={repair.vin === undefined || repair.vin === null || repair.vin === ""}
                            onClick={() => {
                                setRemindersModal(true)
                            }}>维修提醒</Button>}
                {repair.status >= REPAIR_STATUS_PREPAID && common.can('support.repair.prepay.print') &&
                    <Button icon={<PrinterOutlined/>} onClick={print}>打印</Button>}
                <span
                    hidden={!(repair.status >= REPAIR_STATUS_PREPAID && !common.can('support.repair.prepay.print'))}>
                           <PrintPrepay repair={repair} prepays={prepays} account={account} finance={finance}/>
                </span>

                <Button icon={<CloseOutlined/>}
                        hidden={repair.status !== REPAIR_STATUS_PREPAID || scene === "look"}
                        type="primary"
                        onClick={() => {
                            cancelPrepays()
                        }}>取消预结
                </Button>
                <Button icon={<CheckOutlined/>}
                        hidden={repair.status !== REPAIR_STATUS_SUPPORTED || scene === "look"}
                        type="primary"
                        onClick={() => {
                            form.submit()
                        }}>预结算
                </Button>
            </PageTop>

            <Form form={form} onFinish={onFinish} id={"prepay-warp"} initialValues={initSettleForm}>
                <Row gutter={24}>
                    <Col span={6}>
                        <Row>
                            <Col span={24}>
                                <Row>
                                    <Col span={24}>
                                        <FormItem label={"客户名称"}>
                                            <span className="ant-form-text"> {repair.customerName}</span>
                                            <Tooltip title="客户往来详情">
                                                <QuestionCircleOutlined
                                                    style={{color: "#1890ff"}}
                                                    onClick={() => {
                                                        setLookupCustomerRecordVisible(true)
                                                    }}/>
                                            </Tooltip>
                                        </FormItem>
                                    </Col>
                                    <Col span={24}>
                                        <FormItem label={"送修人员"}>
                                            <span className="ant-form-text"> {repair.name}</span>
                                        </FormItem>
                                    </Col>
                                    <Col span={24}>
                                        <FormItem label={"联系电话"}>
                                            <span className="ant-form-text"> {repair.phone}</span>
                                        </FormItem>
                                    </Col>
                                    <Col span={24}>
                                        <FormItem label={"车主名称"}>
                                            <span className="ant-form-text"> {repair.vehicleName}</span>
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={6}>
                        <Row>
                            <Col span={24}>
                                <FormItem label={"车牌号码"}>
                                    <span className="ant-form-text">
                                        <b>{repair.plate}</b>
                                    </span>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"进厂里程"}>
                                    <span className="ant-form-text"> {repair.mileage} KM</span>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"维修车系"}>
                                    <span className="ant-form-text"> {repair.seriesName}</span>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"车架号码"}>
                                    <div className="ant-form-text">
                                        {repair.vin}
                                    </div>
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={6}>
                        <Col span={24}>
                            <Col span={24}>
                                <FormItem label={"工单编号"}>
                                    <span className="ant-form-text"> {repair.code}</span>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"服务顾问"}>
                                    <span className="ant-form-text">
                                        {repair.employeeName}
                                    </span>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"结算人员"}>
                                    <span className="ant-form-text">
                                        {repair.clearCreator === "" ? common.getUser().nickname : repair.clearCreator}
                                    </span>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"工单类型"}>
                                    <span className="ant-form-text">
                                        {
                                            (repair.type === REPAIR_TYPE_DELEGATE ? repairTypeAlias[REPAIR_TYPE_DELEGATE] : repairTypeAlias[REPAIR_TYPE_NORMAL]) + " " + (repair.repairCategoryName || "")
                                        }
                                    </span>
                                </FormItem>
                            </Col>
                        </Col>
                    </Col>
                    <Col span={6}>
                        <Row>
                            <Col span={24}>
                                <FormItem label={"报修日期"}>
                                    <span className="ant-form-text"> {repair.makeAt}</span>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"派工日期"}>
                                    <span className="ant-form-text"> {repair.dispatchAt}</span>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"竣工日期"}>
                                    <span className="ant-form-text"> {repair.finishAt}</span>
                                </FormItem>
                            </Col>
                            {
                                repair.type === REPAIR_TYPE_DELEGATE ?
                                    <Col span={24}>
                                        <FormItem label={"委托单位"}>
                                            <span className="ant-form-text">
                                                {
                                                    repair.type === REPAIR_TYPE_DELEGATE ? repair.consignorCompanyName : ''
                                                }
                                            </span>
                                        </FormItem>
                                    </Col> :
                                    <Col span={24}>
                                        <FormItem
                                            label={"工单状态"}>{repairStatusTypeAlias[repair.status]}</FormItem>
                                    </Col>
                            }

                        </Row>
                    </Col>
                    <Col span={6}>
                        <FormItem label="发动机号" className="input-group-container">
                            <Input.Group compact>
                                <Input
                                    value={repair.engineNum}
                                    disabled={true}
                                />
                                <Tooltip title="点击复制">
                                    <Button
                                        icon={<CopyOutlined/>}
                                        onClick={() => {
                                            copyToClipboard(repair.engineNum).then(res => {
                                                common.toast("复制成功");
                                            }).catch(err => {
                                                common.toast("复制失败");
                                            });
                                        }}
                                    />
                                </Tooltip>
                            </Input.Group>
                        </FormItem>
                    </Col>
                    <Col span={18}>
                        <FormItem label={"预结备注"}>
                            <Input.TextArea
                                rows={1}
                                placeholder={'请输入预结算备注...'}
                                disabled={repair.status !== REPAIR_STATUS_SUPPORTED}
                                value={prepaySpec} onChange={e => {
                                setPrepaySpec(e.target.value.trim())
                            }}/>
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col>
                                <FormItem>
                                    <Tabs defaultActiveKey="1">
                                        <TabPane tab="工时费用" key="1">
                                            <Table
                                                rowKey={record => record.id}
                                                scroll={{x: '100%'}}
                                                pagination={false}
                                                columns={projectColumns}
                                                dataSource={projectRepairItems}
                                                summary={() => {
                                                    return (
                                                        <tr>
                                                            <th colSpan={4}>合计</th>
                                                            <td>￥{common.numberFormat(projectSubtotal.saleTotal)}</td>
                                                            <td align={"right"}>{common.numberFormat(projectSubtotal.dispatchTime)}</td>
                                                            <td align={"right"}>￥{common.numberFormat(projectSubtotal.couponDiscount)}</td>
                                                            <td align={"right"}>￥{common.numberFormat(projectSubtotal.cardDiscount)}</td>
                                                            <td/>
                                                            <td>￥{common.numberFormat(projectSubtotal.manualDiscount)}</td>
                                                            <td align={"right"}>￥{common.numberFormat(projectSubtotal.total)}</td>
                                                        </tr>
                                                    )
                                                }}
                                            />
                                        </TabPane>
                                        <TabPane tab="材料费用" key="2">
                                            <Table scroll={{x: '100%'}}
                                                   rowKey={record => record.id}
                                                   pagination={false}
                                                   columns={productColumns}
                                                   dataSource={productRepairItems}
                                                   summary={() => {
                                                       return (
                                                           <tr>
                                                               <th colSpan={3}>合计</th>
                                                               <td align={"right"}>{common.numberFormat(productSubtotal.quantity)}</td>
                                                               <td/>
                                                               <td align={"right"}>￥{common.numberFormat(productSubtotal.deliveryTotal)}</td>
                                                               <td/>
                                                               <td align={"right"}>￥{common.numberFormat(productSubtotal.saleTotal)}</td>
                                                               <td colSpan={2}/>
                                                               <td align={"right"}>￥{common.numberFormat(productSubtotal.couponDiscount)}</td>
                                                               <td align={"right"}>￥{common.numberFormat(productSubtotal.cardDiscount)}</td>
                                                               <td/>
                                                               <td>￥{common.numberFormat(productSubtotal.manualDiscount)}</td>
                                                               <td/>
                                                               <td align={"right"}>￥{common.numberFormat(productSubtotal.total)}</td>
                                                           </tr>
                                                       )
                                                   }}
                                            />
                                        </TabPane>
                                        <TabPane tab="协修工单" key="3">
                                            <Table
                                                scroll={{x: '100%'}}
                                                rowKey={record => record.id}
                                                pagination={false}
                                                columns={otherColumns}
                                                dataSource={otherRepairItems}
                                                summary={() => {
                                                    return (
                                                        <tr>
                                                            <th colSpan={3}>合计</th>
                                                            <td align={"right"}>{common.numberFormat(otherSubtotal.quantity)}</td>
                                                            <td/>
                                                            <td align={"right"}>￥{common.numberFormat(otherSubtotal.saleTotal)}</td>
                                                            <td align={"right"}>￥{common.numberFormat(otherSubtotal.receiptTotal)}</td>
                                                            <td colSpan={2}/>
                                                            <td align={"right"}>￥{common.numberFormat(otherSubtotal.couponDiscount)}</td>
                                                            {/*<td align={"right"}>￥{common.numberFormat(otherSubtotal.cardDiscount)}</td>*/}
                                                            <td align={"right"}>￥{common.numberFormat(otherSubtotal.manualDiscount)}</td>
                                                            <td align={"right"}>￥{common.numberFormat(otherSubtotal.total)}</td>
                                                        </tr>
                                                    )
                                                }}
                                            />
                                        </TabPane>
                                        {/*<TabPane tab="其它费用" key="7">*/}
                                        {/*    <Table scroll={{x: "100%"}}*/}
                                        {/*           rowKey={record => record.id}*/}
                                        {/*           pagination={false}*/}
                                        {/*           columns={otherColumns}*/}
                                        {/*           dataSource={[]}*/}
                                        {/*    />*/}
                                        {/*</TabPane>*/}
                                        {/*<TabPane tab="礼券销售" key="5">*/}
                                        {/*    <Table scroll={{x: '100%'}}*/}
                                        {/*           rowKey={record => record.id}*/}
                                        {/*           pagination={false}*/}
                                        {/*           columns={couponPlanColumns}*/}
                                        {/*           dataSource={[...couponPlans, {id: '', isEmptyRecord: true}]}*/}
                                        {/*           summary={() => {*/}
                                        {/*               return (*/}
                                        {/*                   <tr>*/}
                                        {/*                       <th>合计</th>*/}
                                        {/*                       <td>{common.numberFormat(couponSubtotal.quantity)}</td>*/}
                                        {/*                       <td>￥{common.numberFormat(couponSubtotal.price)}</td>*/}
                                        {/*                       <td>￥{common.numberFormat(couponSubtotal.total)}</td>*/}
                                        {/*                   </tr>*/}
                                        {/*               )*/}
                                        {/*           }}*/}
                                        {/*    />*/}
                                        {/*</TabPane>*/}
                                        <TabPane tab="礼券回收" key="6">
                                            <Table scroll={{x: '100%'}}
                                                   rowKey={record => record.id}
                                                   pagination={false}
                                                   columns={recoveredTicketsColumns}
                                                   dataSource={[...recoveredTickets, {id: '', isEmptyRecord: true}]}
                                            />
                                        </TabPane>

                                    </Tabs>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={6}>
                                <FormItem label={"客户自费"}>
                                    <span className="ant-form-text"> {common.numberFormat(customerTotal)}</span>
                                </FormItem>
                                <FormItem label={"保险维修"}>
                                    <span className="ant-form-text"> {common.numberFormat(insuranceTotal)}</span>
                                </FormItem>
                                <FormItem label={"厂家索赔"}>
                                    <span className="ant-form-text"> {common.numberFormat(factoryTotal)}</span>
                                </FormItem>
                                <FormItem label={"其中,委托费用"}>
                                    <span className="ant-form-text">{common.numberFormat(delegateTotal)}</span>
                                </FormItem>
                                <FormItem label={"费用合计"}>
                                    <span className="ant-form-text">
                                        {common.numberFormat(customerPayTotal)}
                                        + {common.numberFormat(insurancePayTotal)}
                                        + {common.numberFormat(factoryPayTotal)}
                                        + {common.numberFormat(accountPartnerMoney)}= {
                                        common.numberFormat(common.numberHalfUp(new BigNumber(customerPayTotal).plus(insurancePayTotal).plus(factoryPayTotal).plus(accountPartnerMoney).toString(), 2))}
                                    </span>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <Row>
                                    <Col span={12}>
                                        <FormItem label={"客户折扣"}>
                                            <span
                                                className="ant-form-text"> {common.numberFormat(customerDiscountMoney)}</span>
                                        </FormItem>
                                    </Col>

                                    <Col span={12}>
                                        <FormItem label={"自费保险"}>
                                            <span
                                                className="ant-form-text"> {common.numberFormat(customerOfInsuranceMoney)}</span>
                                        </FormItem>
                                    </Col>
                                </Row>

                                <FormItem label={"保险折扣"}>
                                    <span
                                        className="ant-form-text"> {common.numberFormat(insuranceDiscountMoney)}</span>
                                </FormItem>
                                <FormItem label={"索赔折扣"}>
                                    <span
                                        className="ant-form-text"> {common.numberFormat(factoryDiscountMoney)}</span>
                                </FormItem>
                                <FormItem label={"会员折扣"}>
                                    <span className="ant-form-text">{common.numberFormat(cardDiscountMoney)}</span>
                                </FormItem>
                                <FormItem label={"累计折扣"}>
                                    {/*<span className="ant-form-text">{common.numberFormat(discountTotal)}</span>*/}
                                    <Input readOnly
                                        // disabled={repair.status >= Repair.REPAIR_STATUS_PREPAID}
                                           value={discountTotal}
                                           addonAfter={
                                               <EditOutlined
                                                   onClick={() => {
                                                       // if (repair.status >= Repair.REPAIR_STATUS_PREPAID) {
                                                       //     return
                                                       // }
                                                       setReviewFormVisible(true)
                                                   }}/>
                                           }/>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label={"费用确认"} name="customerAccount">
                                    <Input readOnly
                                           disabled={repair.status >= Repair.REPAIR_STATUS_PREPAID}
                                           addonAfter={
                                               <EditOutlined
                                                   onClick={() => {
                                                       if (repair.status >= Repair.REPAIR_STATUS_PREPAID) {
                                                           return;
                                                       }
                                                       setCustomerClaimVisible(true)
                                                   }}/>
                                           }/>
                                </FormItem>
                                <FormItem label={"定损录入"} name="insuranceName" rules={[{
                                    validator: async (rule, value) => {
                                        if ((!value) && common.numberHalfUp(new BigNumber(insurancePayTotal).toString(), 2) !== "0.00") {
                                            throw new Error('理赔单不能为空');
                                        }
                                    }
                                }]}>
                                    <Input readOnly
                                           disabled={repair.status >= Repair.REPAIR_STATUS_PREPAID}
                                           addonAfter={
                                               <EditOutlined
                                                   onClick={() => {
                                                       if (repair.status >= Repair.REPAIR_STATUS_PREPAID) {
                                                           return;
                                                       }
                                                       setInsuranceClaimVisible(true)
                                                   }}/>
                                           }/>
                                </FormItem>
                                <FormItem label={"索赔工单"} name="factoryName" rules={[{
                                    validator: async (rule, value) => {
                                        if ((!value) && common.numberHalfUp(new BigNumber(factoryPayTotal).toString(), 2) !== "0.00") {
                                            throw new Error('索赔单不能为空');
                                        }
                                    }
                                }]}>
                                    <Input readOnly
                                           disabled={repair.status >= Repair.REPAIR_STATUS_PREPAID}
                                           value={factoryClaim.partnerName}
                                           addonAfter={
                                               <EditOutlined
                                                   onClick={() => {
                                                       if (repair.status >= Repair.REPAIR_STATUS_PREPAID) {
                                                           return;
                                                       }
                                                       setFactoryClaimVisible(true)
                                                   }}/>
                                           }/>
                                </FormItem>
                                <FormItem label={"会员类型"}>
                                    <Input readOnly
                                           disabled={repair.status >= Repair.REPAIR_STATUS_PREPAID}
                                           value={vipLevelName}
                                           addonAfter={
                                               <CrownOutlined
                                                   onClick={() => {
                                                       if (vip) {
                                                           setCardDiscountDetailVisible(true)
                                                       }
                                                   }}/>
                                           }/>
                                </FormItem>
                                <FormItem label={"购买礼券"}>
                                    <span className="ant-form-text"> {common.numberFormat(couponPayTotal)}</span>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label={"自费小计"}>
                                    <span style={{width: "100px", paddingRight: "50px"}}
                                          className="ant-form-text"> {`${common.numberFormat(customerPayTotal)}`}</span>
                                    <Tooltip
                                        title={`工时:${common.numberFormat(customerProjectMoney)} 材料:${common.numberFormat(customerProductMoney)}`}>
                                        <QuestionCircleOutlined style={{color: "#1890ff"}}/>
                                    </Tooltip>
                                </FormItem>
                                <FormItem label={"保险小计"}>
                                    <span style={{width: "100px", paddingRight: "50px"}}
                                          className="ant-form-text"> {`${common.numberFormat(insurancePayTotal)}`}</span>
                                    <Tooltip
                                        title={`工时:${common.numberFormat(insuranceProjectMoney)} 材料:${common.numberFormat(insuranceProductMoney)}`}>
                                        <QuestionCircleOutlined style={{color: "#1890ff"}}/>
                                    </Tooltip>
                                </FormItem>
                                <FormItem label={"索赔小计"}>
                                    <span style={{width: "100px", paddingRight: "50px"}}
                                          className="ant-form-text"> {`${common.numberFormat(factoryPayTotal)}`}</span>
                                    <Tooltip
                                        title={`工时:${common.numberFormat(factoryProjectMoney)} 材料:${common.numberFormat(factoryProductMoney)}`}>
                                        <QuestionCircleOutlined style={{color: "#1890ff"}}/>
                                    </Tooltip>
                                </FormItem>
                                <FormItem label={"委托小计"}>
                                    {/*<span className="ant-form-text"> {common.numberFormat(delegatePayTotal)}</span>*/}
                                    <span className="ant-form-text"> {common.numberFormat(delegateTotal)}</span>
                                </FormItem>
                                <FormItem label={"客户应付"}>
                                    <span className="ant-form-text">
                                        {common.numberFormat(customerPayTotal)} + {common.numberFormat(couponPayTotal)}
                                        = {
                                        common.numberFormat(common.numberHalfUp(new BigNumber(customerPayTotal).plus(couponPayTotal).toString(), 2))}
                                    </span>
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>

            {remindersModal &&
                <RemindersModal
                    vehicleId={repair.vehicleId}
                    onOk={() => {
                        setRemindersModal(false)
                    }}
                    vin={repair.vin}
                    canReminder={canReminder}
                />}

            <Modal
                maskClosable={false}
                destroyOnClose={true}
                title={'回收卡券'}
                visible={ticketRecoverVisible}
                footer={null} width={1000}
                onCancel={() => {
                    customerProjects = originCustomerProjects
                    customerProducts = originCustomerProducts
                    // 重新组装客户的维修项目
                    projectRepairItems.forEach(project => {
                        customerProjects.forEach(item => {
                            if (project.id === item.id) {
                                project.couponDiscount = common.numberHalfUp(new BigNumber(item.couponDiscount).toString(), 2)
                                project.total = common.numberHalfUp(new BigNumber(item.total).toString(), 2)
                                project.price = common.numberHalfUp(new BigNumber(item.price).toString(), 2)
                            }
                        })
                    })
                    // 重新组装客户的维修材料
                    productRepairItems.forEach(product => {
                        customerProducts.forEach(item => {
                            if (product.id === item.id) {
                                product.couponDiscount = common.numberHalfUp(new BigNumber(item.couponDiscount).toString(), 2)
                                product.total = common.numberHalfUp(new BigNumber(item.total).toString(), 2)
                                product.price = common.numberHalfUp(new BigNumber(item.price).toString(), 2)
                            }
                        })
                    })
                    // 重新组委托的维修明细
                    otherRepairItems.forEach(repairItem => {
                        customerProjects.forEach(item => {
                            if (repairItem.id === item.id) {
                                repairItem.couponDiscount = common.numberHalfUp(new BigNumber(item.couponDiscount).toString(), 2)
                                repairItem.total = common.numberHalfUp(new BigNumber(item.total).toString(), 2)
                                repairItem.price = common.numberHalfUp(new BigNumber(item.price).toString(), 2)
                            }
                        })
                        customerProducts.forEach(item => {
                            if (repairItem.id === item.id) {
                                repairItem.couponDiscount = common.numberHalfUp(new BigNumber(item.couponDiscount).toString(), 2)
                                repairItem.total = common.numberHalfUp(new BigNumber(item.total).toString(), 2)
                                repairItem.price = common.numberHalfUp(new BigNumber(item.price).toString(), 2)
                            }
                        })
                    })
                    setProductRepairItems([...productRepairItems])
                    setProjectRepairItems([...projectRepairItems])
                    setOtherRepairItems([...otherRepairItems])
                    setCustomerProjects([...customerProjects])
                    setCustomerProducts([...customerProducts])
                    setTicketRecoverVisible(false)
                }}
            >
                <TicketRecover
                    defaultValue={{
                        vehicleName: repair.vehicleName,
                        phone: repair.vehiclePhone,
                        plate: repair.plate,
                        vin: repair.vin,
                        vehicleId: repair.vehicleId
                    }}
                    businessCode={repair.code}
                    isMultiple={true} customerId={repair.customerId}
                    ticketIds={ticketIds}
                    items={[...customerProjects, ...customerProducts]}
                    onOk={(items, tickets) => {
                        ticketIds = []
                        tickets.forEach(ticket => {
                            ticket.quantity = 1
                            ticketIds.push(ticket.id)
                        })
                        setRecoveredTickets(tickets)// 设置礼券回收
                        setTicketIds([...ticketIds])
                        // 重新组装客户的维修项目
                        projectRepairItems.forEach(project => {
                            items.forEach(item => {
                                if (project.id === item.id) {
                                    project.couponDiscount = item.deductedMoney === undefined ? 0 : item.deductedMoney
                                    project.total = common.numberHalfUp(new BigNumber(project.total)
                                        .minus(project.couponDiscount)
                                        .minus(project.cardDiscount)
                                        .minus(project.manualDiscount)
                                        .toString(), 2)
                                    project.price = common.numberHalfUp(new BigNumber(project.total)
                                        .dividedBy(project.quantity).toString(), 2)
                                }
                            })
                        })
                        // 重新组装客户的维修材料
                        productRepairItems.forEach(product => {
                            items.forEach(item => {
                                if (product.id === item.id) {
                                    product.couponDiscount = item.deductedMoney === undefined
                                        ? 0
                                        : new BigNumber(item.deductedMoney).toString()
                                    product.total = common.numberHalfUp(new BigNumber(product.total)
                                        .minus(product.couponDiscount)
                                        .minus(product.cardDiscount)
                                        .minus(product.manualDiscount)
                                        .toString(), 2)
                                    product.price = common.numberHalfUp(new BigNumber(product.total)
                                        .dividedBy(product.quantity).toString(), 2)
                                }
                            })
                        })
                        // 重新组委托的维修明细
                        otherRepairItems.forEach(repairItem => {
                            items.forEach(item => {
                                if (repairItem.id === item.id) {
                                    repairItem.couponDiscount = item.deductedMoney === undefined
                                        ? 0
                                        : new BigNumber(item.deductedMoney).toString()
                                    repairItem.total = common.numberHalfUp(new BigNumber(repairItem.total)
                                        .minus(repairItem.couponDiscount)
                                        .minus(repairItem.cardDiscount)
                                        .minus(repairItem.manualDiscount)
                                        .toString(), 2)
                                    repairItem.price = common.numberHalfUp(new BigNumber(repairItem.total)
                                        .dividedBy(repairItem.quantity).toString(), 2)
                                }
                            })
                        })

                        // 设置客户自费的维修项目 、 客户自费的维修材料
                        customerProjects = []
                        customerProducts = []
                        items.forEach(item => {
                            if (item.type === REPAIR_ITEM_TYPE_PROJECT) {
                                customerProjects.push(item)
                            } else {
                                customerProducts.push(item)
                            }
                        })
                        setCustomerProjects([...customerProjects])
                        setCustomerProducts([...customerProducts])
                        setProductRepairItems([...productRepairItems])
                        setProjectRepairItems([...projectRepairItems])
                        setOtherRepairItems([...otherRepairItems])
                        setTicketRecoverVisible(false)
                    }}/>
            </Modal>

            {/*销售方案列表*/}
            <Modal
                maskClosable={false}
                title={'选择礼券方案'}
                visible={lookupCouponPlanVisible}
                footer={null}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setLookupCouponPlanVisible(false)
                }}
            >
                <CouponPlan isMultiple={true} onOk={handleCouponPlans} department={"2"}/>
            </Modal>

            <Modal title={"理赔单"}
                   maskClosable={false}
                   getContainer={false}
                   visible={insuranceClaimVisible}
                   footer={null}
                   width={1000}
                   destroyOnClose={true}
                   onCancel={() => {
                       setInsuranceClaimVisible(false)
                   }}>
                <InsuranceClaim
                    claim={insuranceClaim}
                    customerId={repair.customerId}
                    customerName={repair.customerName}
                    repairId={repairId}
                    type={CLAIM_TYPE_INSURANCE}
                    projects={insuranceProjects}
                    setProjects={setInsuranceProjects}
                    products={insuranceProducts}
                    setProducts={setInsuranceProducts}
                    setProducets={setInsuranceProducts}
                    onOk={handleInsuranceClaim}/>
            </Modal>

            <Modal title={"索赔单"}
                   maskClosable={false}
                   visible={factoryClaimVisible}
                   footer={null}
                   width={1000}
                   destroyOnClose={true}
                   onCancel={() => {
                       setFactoryClaimVisible(false)
                   }}>
                <FactoryClaim
                    claim={factoryClaim}
                    repairId={repairId}
                    type={CLAIM_TYPE_FACTORY}
                    projects={factoryProjects}
                    setProjects={setFactoryProjects}
                    products={factoryProducts}
                    setProducts={setFactoryProducts}
                    onOk={handleFactoryClaim}/>
            </Modal>

            <Modal title={"维修费用确认"}
                   maskClosable={false}
                   visible={customerClaimVisible}
                   footer={null}
                   width={1000}
                   destroyOnClose={true}
                   onCancel={() => {
                       setCustomerClaimVisible(false)
                   }}>
                <CustomerClaim
                    claim={customerClaim}
                    setClaim={setCustomerClaim}
                    repairId={repairId}
                    type={CLAIM_TYPE_CUSTOMER}
                    projects={customerProjects}
                    setProjects={setCustomerProjects}
                    products={customerProducts}
                    setProducts={setCustomerProducts}
                    onOk={handleCustomerClaim}/>
            </Modal>

            {/*客户来往*/}
            <Modal title={'客户往来详情'}
                   maskClosable={false}
                   visible={lookupCustomerRecordVisible}
                   footer={null}
                   width={1000}
                   destroyOnClose={true}
                   onCancel={() => {
                       setLookupCustomerRecordVisible(false)
                   }}>
                <LookupCustomerRecord customerId={repair.customerId}/>
            </Modal>

            {/*会员抵扣详情*/}
            <Modal title={'会员抵扣详情'}
                   maskClosable={false}
                   visible={cardDiscountDetailVisible}
                   footer={null}
                   width={1000}
                   destroyOnClose={true}
                   onCancel={() => {
                       setCardDiscountDetailVisible(false)
                   }}>
                <CardDiscountDetail vipLevel={vipLevel}/>
            </Modal>

            <Modal title={'工单折扣录入'}
                   maskClosable={false}
                   visible={reviewFormVisible}
                   footer={null}
                   width={1000}
                   destroyOnClose={true}
                   onCancel={() => {
                       setReviewFormVisible(false)
                   }}>
                <ReviewForm
                    projects={customerProjects}
                    products={customerProducts}
                    repairId={repairId}
                    groupByChargeTypeData={
                        [{
                            chargeType: 2,
                            salePrice: common.numberFormat(insuranceTotal),
                            price: common.numberFormat(insuranceTotal),
                            quantity: "1.00",
                            cardDiscount: 0,
                            manualDiscount: 0,
                            couponDiscount: 0,
                            manualDiscountTotal: 0,
                        }, {
                            chargeType: 3,
                            salePrice: common.numberFormat(factoryTotal),
                            price: common.numberFormat(factoryTotal),
                            quantity: "1.00",
                            cardDiscount: 0,
                            manualDiscount: 0,
                            couponDiscount: 0,
                            manualDiscountTotal: 0,
                        }]}
                    onOk={saveDiscountReason}/>
            </Modal>

        </React.Fragment>
    )
}

export default PrepayForm