import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Link, withRouter} from "react-router-dom"

import {
    BarChartOutlined,
    CarOutlined,
    ContainerOutlined,
    ControlOutlined,
    CrownOutlined,
    DashboardOutlined,
    HddOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    MoneyCollectOutlined,
    SettingOutlined,
    SnippetsOutlined,
    ToolOutlined,
    UsergroupAddOutlined,
    UserSwitchOutlined,
    WalletOutlined,
    BarcodeOutlined,
    FilterOutlined
} from '@ant-design/icons';

import {Layout, Menu, Modal} from 'antd';
import './index.less'
import common from "../../../utils/common";
import ChangePassword from "../../passport/ChangePassword";
import Search from "../../../utils/search";

const {Header, Sider, Content} = Layout
const {SubMenu} = Menu

let siderTheme = 'dark'
let headerTheme = 'dark'

function SideLayout(props) {

    // 高度自适应
    useEffect(() => {
        let content = document.getElementsByClassName('content')[0]
        content.style.minHeight = window.innerHeight - 94 + "px";
    }, [common.getUser().brand.id, common.getUser().company.id])

    let [collapsed, setCollapsed] = useState(false)
    let [openKeys, setOpenKeys] = useState([])

    let [companies, setCompanies] = useState([])
    let [brands, setBrands] = useState([])

    let [passVisible, setPassVisible] = useState(false)

    let getCompanyIds = (id) => {
        common.loadingStart()
        common.ajax("get", "/passport/employee/findById", {id: id}).then(data => {
            data.companyIds.push(data.companyId)
            getCompanies(data.companyIds)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getCompanies = (ids) => {
        if (ids.length > 0) {
            common.loadingStart()
            common.ajax("get", "/passport/company/findByIds", {ids: ids}).then(data => {
                setCompanies(data)
            }).finally(() => {
                common.loadingStop()
            })
        } else {
            setCompanies([])
        }
    }

    let getBrandIds = () => {
        common.ajax("get", "/passport/company/findById", {id: common.getUser().company.id}).then(data => {
            getBrands(data.brandIds)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        common.loadingStart()
        common.ajax("get", "/passport/user/findByAuthUser", {}).then(data => {
            getCompanyIds(data.id)
            getBrandIds()
        }).finally(() => {
            common.loadingStop()
        })

        // 获取当前用户在指定公司下的权限
        common.ajax('get', '/passport/task/findTaskIdsByAuthUser', {companyId: common.getUser().company.id}).then((tasks) => {
            common.consoleLog(tasks)
            common.setTasks(tasks)
            props.changeTasks(tasks)
        })

    }, [common.getUser().brand.id, common.getUser().company.id])

    let getBrands = (ids) => {
        if (ids.length > 0) {
            common.loadingStart()
            common.ajax("get", "/wms/brand/findByIds", {ids: ids}).then(data => {
                setBrands(data)
            }).finally(() => {
                common.loadingStop()
            })
        } else {
            setBrands([])
        }
    }


    let setUser = (type, obj) => {
        let user = common.getUser()

        if (type === "company") {
            user.company.id = obj.id
            user.company.name = obj.name


            common.loadingStart()
            common.ajax("get", "/passport/company/findById", {id: obj.id}).then(data => {
                if (data.brandIds.length > 0) {
                    common.consoleLog(data, 'company')
                    let userBrandId = data.brandId || data.brandIds[0]
                    user.brand.id = userBrandId

                    common.ajax("get", "/wms/brand/findById", {id: userBrandId}).then(data1 => {
                        user.brand.name = data1.name

                        // 获取当前用户在指定公司下的权限
                        common.ajax('get', '/passport/task/findTaskIdsByAuthUser', {companyId: obj.id}).then((tasks) => {
                            props.changeTasks(tasks)
                        })

                        props.changeUser(user)

                    }).finally(() => {
                        common.loadingStop()
                    })
                } else {
                    user.brand.id = ''
                    user.brand.name = ''

                    props.changeUser(user)
                }

            }).finally(() => {
                common.loadingStop()
            })
        } else {
            user.brand.id = obj.id
            user.brand.name = obj.name

            props.changeUser(user)
        }


    }

    function toggle() {
        setCollapsed(!collapsed)
    }

    function onOpenChange(openKeys) {
        //只展开一个
        setOpenKeys([...openKeys.splice(-1)]);
    }

    function handleMenuClick(item, key, keyPath, domEvent) {
        common.consoleLog("item", item)
        props.history.push(item.item.props.url)
    }

    // 没有task属性的菜单，不做权限验证
    const menuData = [
        {
            name: '客流管理',
            icon: <UserSwitchOutlined />,
            children: [
                {name: '客流登记', url: '/crm/lead/handle', task: 'crm.lead'},
                {name: '线索分配', url: '/crm/lead/match', task: 'crm.lead.dispatch'},
                {name: '潜客管理', url: '/crm/task', task: 'crm.task'},
                {name: '战败审核', url: '/crm/task/defeat', task: 'crm.task.fail'},
                {name: '潜客批注', url: '/crm/task/annotation', task: 'crm.task.proposal'},
                {name: '潜客划转', url: '/crm/task/transfer', task: 'crm.task.transfer'},
                {name: '试驾登记', url: '/crm/driver/registration', task: 'crm.driver.registration'},
                {name: '试驾反馈', url: '/crm/driver/feedback', task: 'crm.driver.feedback'},
            ]
        },

        {
            name: '车辆管理',
            icon: <CarOutlined />,
            children: [
                {name: '车辆入库', url: '/wms/car/receipt', task: 'wms.car.receipt'},
                {name: '车辆退货', url: '/wms/car/receipt/return', task: 'wms.car.receipt.return'},  // 生成负数的单据 是退给厂商

                {name: '订单查询', url: '/wms/car/order', task: 'wms.car.order'},  //动作: 配车、取消配车、出库、取消出库、(再配车、再出库)   不用生成新的单据
                {name: '出库查询', url: '/wms/car/delivery', task: 'wms.car.delivery.query'},
                // {name: '订单退车', url: '/wms/car/delivery/return', task: 'wms.vehicle.delivery.back'},  // 退车后，库存要增加     客户退到仓库中

                {name: '库存管理', url: '/wms/car/sku', task: 'wms.car.sku'},   // 增加移库(生成移库单、车辆可以不生成直接改仓库// )、特殊价格设置
                {name: '调拨管理', url: '/wms/car/allot', task: 'wms.car.transfer'},

                {name: '进销存统计', url: '/wms/car/receiptItem', task: 'wms.car.receipt.delivery'},
            ]
        },

        {
            name: '配件管理',
            icon: <ControlOutlined />,
            children: [
                {name: '配件入库', url: '/wms/part/receipt', task: 'wms.part.receipt'},
                {name: '配件退货', url: '/wms/part/receipt/return', task: 'wms.part.receipt.return'}, // 退给供货商，要生成负数的单据

                {name: '工单查询', url: '/wms/part/order', task: 'wms.part.order'}, // 其实是工单出库
                {name: '销售出库', url: '/wms/part/sale', task: 'wms.part.delivery.sale'},
                {name: '领用出库', url: '/wms/part/delivery/receive', task: 'wms.part.delivery.receive'},

                {name: '配件退库', url: '/wms/part/delivery/return', task: 'wms.part.delivery.return'},

                {name: '出库查询', url: '/wms/part/delivery', task: 'wms.part.delivery.query'}, // 缺库别这个条件，需要配件编号和名称，列表中也需要
                {name: '编号库存', url: '/wms/part/sku', task: 'wms.part.sku.number'},
                {name: '批次库存', url: '/wms/part/sku/receipt', task: 'wms.part.sku.receipt'},
                {name: '实物库存', url: '/wms/part/inventory', task: 'wms.part.sku.actual'},

                {name: '调拨管理', url: '/wms/part/allot', task: 'wms.part.transfer'},
                {name: '进销存统计', url: '/wms/part/receiptItem', task: 'wms.part.receipt.delivery'},

                {name: '虚入库管理', url: '/wms/part/empty', task: 'wms.part.receipt.empty'},
                {name: '出库明细', url: '/wms/part/delivery/notShowReturn', task: 'wms.part.delivery.notShowReturn'},
            ]
        },

        {
            name: '集采管理',
            icon: <ControlOutlined />,
            children: [
                {name: '我要询价', url: '/wms/group/buy/inquiry', task: 'group.buy.inquiry'},
                {name: '询价单审核', url: '/wms/group/buy/inquiry/auditPage', task: 'group.buy.inquiry.audit'},
                {name: '提交询价', url: '/wms/group/buy/quotation', task: 'group.buy.quotation'},
                {name: '询价消息', url: '/wms/group/buy/supplierPage', task: 'group.buy.supplier'},
                {name: '报价单比对', url: '/wms/group/buy/quotation/page', task: 'group.buy.quotation.page'},
                {name: '采购计划单审批', url: '/wms/group/buy/quotation/auditPage', task: 'group.buy.quotation.audit'},
                {name: '采购消息', url: '/wms/group/buy/supplier/delivery', task: 'group.buy.supplier.delivery'},
                {name: '我的订单', url: '/wms/group/buy/purchasePlan/receive', task: 'group.buy.purchase.receive'},
            ]
        },

        {
            name: '精品管理',
            icon: <CrownOutlined />,
            children: [
                // 测试去除task使用
                // {name: '商品销售', url: '/wms/commodity/sale'},
                // {name: '商品库存', url: '/wms/commodity/sku'},
                // {name: '商品领用', url: '/wms/commodity/delivery/receive'},


                {name: '精品入库', url: '/wms/article/receipt', task: 'wms.boutique.receipt'},    // 可以直接新增精品
                {name: '精品退货', url: '/wms/article/receipt/return', task: 'wms.boutique.receipt.return'},

                {name: '订单查询', url: '/wms/article/order', task: 'wms.boutique.order'},
                {name: '销售出库', url: '/wms/article/sale', task: 'wms.boutique.delivery.sale'},
                {name: '商品销售', url: '/wms/commodity/sale', task: 'wms.commodity.delivery.sale'},
                {name: '领用出库', url: '/wms/article/delivery/receive', task: 'wms.boutique.delivery.receive'},
                {name: '商品领用', url: '/wms/commodity/delivery/receive', task: 'wms.commodity.delivery.receive'},
                {name: '精品退库', url: '/wms/article/delivery/return', task: 'wms.boutique.delivery.return'},
                {name: '出库查询', url: '/wms/article/delivery', task: 'wms.boutique.delivery.query'},
                {name: '编号库存', url: '/wms/article/sku', task: 'wms.boutique.sku.number'},
                {name: '商品库存', url: '/wms/commodity/sku', task: 'wms.commodity.sku.number'},
                {name: '批次库存', url: '/wms/article/sku/receipt', task: 'wms.boutique.sku.receipt'},
                {name: '实物库存', url: '/wms/article/inventory', task: 'wms.boutique.sku.actual'},

                {name: '调拨管理', url: '/wms/article/allot', task: 'wms.boutique.transfer'},
                {name: '进销存统计', url: '/wms/article/receiptItem', task: 'wms.boutique.receipt.delivery'},
                {name: '虚入库管理', url: '/wms/article/empty', task: 'wms.boutique.receipt.empty'},
                {name: '实物发放管理', url: '/wms/article/grant', task: 'wms.boutique.grant'},
            ]
        },

        {
            name: '新车销售',
            icon: <DashboardOutlined />,
            children: [
                {name: '新车订单', url: '/sale/order', task: 'sale.order'},
                {name: '精品套餐', url: '/sale/plan', task: 'sale.plan'},
                {name: '价格政策', url: '/sale/price', task: 'sale.price'},
                {name: '订单试算', url: '/sale/trial', task: 'sale.trial'},
                {name: '车辆查询', url: '/wms/car/sku/check', task: 'wms.car.sku.query'},
                {name: '用品查询', url: '/wms/article/sku/check', task: 'wms.boutique.sku.number.query'},
            ]
        },


        {
            name: '维修管理',
            icon: <ContainerOutlined />,
            children: [
                {name: '预约登记', url: '/support/appointment', task: 'support.appointment'},
                {name: '维修工单', url: '/support/repair', task: 'support.repair'},
                {name: '维修查询', url: '/support/repair/search', task: 'support.repair.search'},
                {name: '委内业务', url: '/support/delegate', task: 'support.delegate'},
                {name: '工时项目', url: '/support/project', task: 'support.project'},
                {name: '委外项目', url: '/support/outsource', task: 'support.outsource'},
                {name: '维修套餐', url: '/support/plan', task: 'support.plan'},
                {name: '维修日报', url: '/support/repair/daily', task: 'support.repair.daily'},
                // {name: '预检项目[测试]', url: '/support/InspectItems'},
                // {name: '快捷报修[测试]', url: '/support/repair/quick'},
            ]
        },

        {
            name: '增值业务',
            icon: <SnippetsOutlined />,
            children: [
                {name: '产品管理', url: '/avocation/product', task: 'avocation.product'},
                {name: '按揭管理', url: '/avocation/mortgage', task: 'avocation.mortgage'},
                {name: '保险管理', url: '/avocation/premium', task: 'avocation.premium'},
                {name: '业务办理', url: '/avocation/order', task: 'avocation.order'},
            ]
        },
        {
            name: '加装管理',
            icon: <SnippetsOutlined />,
            children: [
                {name: '用品规划', url: '/wms/defaultProductQuantity', task: 'wms.defaultProductQuantity'},
                {name: '公众号背景', url: '/install/app/background', task: 'accessory.app.background'},
                // {name: '公众号设置', url: '/install/app/background'},
            ]
        },

        {
            name: '车间管理',
            icon: <ToolOutlined />,
            children: [
                {name: '维修工单', url: '/support/workshop', task: 'support.workshop'},
                {name: '钣喷委托', url: '/support/workshop/spray', task: 'support.workshop.spray'},
                {name: '维修记录', url: '/support/workshop/history', task: 'support.workshop.history'},
                {name: '维修工时', url: '/support/workshop/repairItems', task: 'support.workshop.repairItems'},
                {name: '维修看板', url: '/support/workshop/board', task: 'support.workshop.board'},
                {name: '原始记录', url: '/support/workshop/oldRepairItems', task: 'support.workshop.history.old'},
            ]
        },
        {
            name: '索赔管理',
            icon: <ContainerOutlined />,
            children: [
                {name: '索赔列表', url: '/support/claim', task: 'support.claim'},
                {name: '结案登记', url: '/support/claim/receive', task: 'support.claim.receive'},
            ]
        },
        {
            name: '礼券管理',
            icon: <WalletOutlined />,
            children: [
                {name: '礼券信息', url: '/coupon', task: 'coupon.coupon'},
                {name: '礼券方案', url: '/coupon/plan', task: 'coupon.plan'},
                {name: '礼券销售', url: '/coupon/sale', task: 'coupon.sale'},
                {name: '礼券审核', url: '/coupon/audit', task: 'coupon.sale.audit'},
                {name: '礼券发送', url: '/coupon/sendTicket', task: 'coupon.sendTicket'},
                {name: '发放管理', url: '/coupon/ticket/grant', task: 'coupon.ticket.confirm'},
                {name: '卡券管理', url: '/coupon/ticket', task: 'coupon.ticket'},
                {name: '卡券查询', url: '/coupon/ticket/query', task: 'coupon.ticket.query'},
                // {name: '礼券审核', url: '/coupon/audit'}
            ]
        },

        {
            name: '盘点管理',
            icon: <BarcodeOutlined />,
            children: [
                {name: '车辆盘点', url: '/wms/car/stock', task: 'wms.stock.car'},
                {name: '精品盘点', url: '/wms/article/stock', task: 'wms.stock.boutique'},
                {name: '配件盘点', url: '/wms/part/stock', task: 'wms.stock.part'},
                {name: '盘点单据', url: '/wms/stock', task: 'wms.stock.bill'},
            ]
        },

        {
            name: '财务管理',
            icon: <MoneyCollectOutlined />,
            children: [
                {name: '结算中心', url: '/finance/prepay', task: 'finance.prepay'},
                {name: '收款作业', url: '/finance/receivable/customers', task: 'finance.receivable.customers'},
                {name: '收款明细', url: '/finance/receipt/list', task: 'finance.receipt.list'},
                {name: '单据审核', url: '/report/finance/receivable/review', task: 'report.finance.receivable.review'},
                {name: '客户往来', url: '/finance/customer/record', task: 'finance.customer.record'},
                {name: '付款申请', url: '/finance/payable/apply', task: 'finance.payable.apply'},
                {name: '付款审核', url: '/finance/payable/review', task: 'finance.payable.review'},
                {name: '支付确认', url: '/finance/payable/customers', task: 'finance.payable.customers'},
                {name: '付款明细', url: '/finance/paylist', task: 'finance.pay.list'},
                {name: '预收请求', url: '/finance/advance', task: 'finance.advance'},
                {name: '预收款管理', url: '/finance/advance/balance', task: 'finance.advance.balance'},
                {name: '支付渠道', url: '/finance/channel', task: 'finance.channel'},
            ]
        },

        {
            name: '客户管理',
            icon: <UsergroupAddOutlined />,
            children: [
                {name: '会员档案', url: '/passport/customer/vip', task: 'passport.customer.vip'},
                {name: '车辆档案', url: '/support/vehicle', task: 'support.vehicle'},
                {name: '客户档案', url: '/passport/customer', task: 'passport.customer'},
                {name: '销售回访', url: '/crm/review/sale', task: 'crm.review.sale'},
                {name: '售后回访', url: '/crm/review/support', task: 'crm.review.support'},
                {name: '短信管理', url: '/crm/sms', task: 'crm.sms'},
                {name: '业务预警器', url: '/crm/warning', task: 'crm.warning'},
            ]
        },

        {
            name: '资料管理',
            icon: <HddOutlined />,
            children: [
                {name: '仓库管理', url: '/wms/warehouse', task: 'wms.warehouse'},
                {name: '外部单位', url: '/passport/partner', task: 'passport.partner'},
                {name: '配件档案管理', url: '/wms/goods', task: 'wms.product.part'},
                {name: '车系档案管理', url: '/wms/series', task: 'wms.series'},
                {name: '销售车型管理', url: '/wms/product', task: 'wms.product.car'},
                {name: '车型内饰颜色', url: '/wms/product/colorInside', task: 'wms.product.colorInside'},
                {name: '车型外饰颜色', url: '/wms/product/colorOutside', task: 'wms.product.colorOutside'},
                {name: '客流等级', url: '/crm/level', task: 'crm.level'},
                {name: '增值业务管理', url: '/avocation/rebateRate/list', task: 'avocation.rebate.rate'},
                {name: '配件管理分类', url: '/wms/product/category', task: 'wms.product.category'},
                {name: '预约时段', url: '/crm/bookingTime', task: 'crm.bookingTime'},
                {name: '车辆来源', url: '/support/vehicle/sourceType', task: 'support.vehicle.sourceType'},
            ]
        },

        {
            name: '报表中心',
            icon: <BarChartOutlined />,
            children: [
                {name: '业务报表', url: '/report/business', task: 'report.business'},
                {name: '财务报表', url: '/report/finance', task: 'report.finance'},
                // {name: '经营汇总', url: '/report/summary', task: 'report.summary'},
                // {name: 'BI 报表', url: '/report/bi', task: 'report.bi'},
            ]
        },
        {
            name: '数据筛选器',
            icon: <FilterOutlined />,
            children: [
                {name: '维修客户管理', url: '/support/owner', task: 'crm.filter.wxkhgl'},
                {name: '新车客户跟进', url: '/crm/filter/xckhgj', task: 'crm.filter.xckhgj'},
                {name: '保养客户提醒', url: '/crm/filter/bykhtx', task: 'crm.filter.bykhtx'},
                {name: '流失客户提醒', url: '/crm/filter/lskhtx', task: 'crm.filter.lxkhtx'},
                {name: '保险业务统计', url: '/crm/filter/bxywtj', task: 'crm.filter.bxywtj'},
                {name: '维修业务构成', url: '/crm/filter/wxywgc', task: 'crm.filter.wxywgc'},
                {name: '服务评价统计', url: '/crm/filter/fwpjtj', task: 'crm.filter.fwpjtj'},
            ]
        },
        {
            name: '系统管理',
            icon: <SettingOutlined />,
            children: [
                {name: '员工档案', url: '/passport/employee', task: 'passport.employee'},
                // {name: '用户权限', url: '/'},
            ]
        },
        // 只有超级管理员才能操作，加一个后台不存在的 task
        {
            name: '参数设置',
            icon: <ControlOutlined />,
            children: [
                {name: '客流管理模块', url: '/crm/setting', task: 'crm.setting'},
                {name: '销售管理模块', url: '/sale/setting', task: 'sale.setting'},
                {name: '维修管理模块', url: '/support/setting', task: 'support.setting'},
                {name: '增值管理模块', url: '/avocation/setting', task: 'avocation.setting'},
                {name: '财务管理模块', url: '/finance/setting', task: 'finance.setting'},
                {name: '配件管理模块', url: '/wms/setting', task: 'wms.setting'},
            ]
        },
        // {
        //     name: '加装界面',
        //     icon: <ControlOutlined />,
        //     children: [
        //         {name: '潜客管理', url: '/install/crm/task'},
        //         {name: '预约提醒', url: '/install/crm/appointment'},
        //         {name: '预约登记', url: '/install/crm/appointment/create'},
        //         {name: '订单管理', url: '/install/order'},
        //         {name: '生产管理', url: '/install/support/workshop'},
        //         {name: '生产派工', url: '/install/support/workshop/dispatch'},
        //         {name: '竣工确认', url: '/install/support/workshop/completed'},
        //         {name: '拍照', url: '/install/support/workshop/takephotos'},
        //         {name: '质保信息', url: '/install/order/warranty'},
        //     ]
        // },
    ];

    // 带权限的菜单数据, show=true的表示显示
    let getMenuDataWithPermission = (menuData) => {

        for (let i = 0;i < menuData.length;i++) {

            let count = 0

            for (let j = 0;j < menuData[i].children.length;j++) {

                //没有task属性的菜单，不做权限验证
                if (menuData[i].children[j].task == null) {
                    count++
                    menuData[i].children[j].show = true
                    continue
                }

                if (props.permission.tasks.includes(menuData[i].children[j].task)) {
                    count++
                    menuData[i].children[j].show = true
                } else {
                    menuData[i].children[j].show = false
                }
            }

            // 一个子菜单都没有，则父级菜单也不显示
            if (count === 0) {
                menuData[i].show = false
            } else {
                menuData[i].show = true
            }
        }

        return menuData
    }

    return (
        <Layout className={'SideLayout'}>
            <Sider theme={siderTheme} trigger={null} collapsible collapsed={collapsed}>
                <Link to={'/'}>
                    <div className="logo">{collapsed ? 'Erp' : 'Erp Auto'}</div>
                </Link>
                <Menu theme={siderTheme} mode="inline"
                    // defaultSelectedKeys={['1']}
                    onClick={handleMenuClick}
                    openKeys={openKeys}
                    onOpenChange={onOpenChange}
                    style={{marginTop: '20px'}}
                >

                    {getMenuDataWithPermission(menuData).map((sub, subIndex) => {

                        if (!sub.show) {
                            return null
                        }

                        return (
                            <SubMenu
                                className={'menu-one'}
                                key={`sub${subIndex}`}
                                title={
                                    <span>{sub.icon}<span>{sub.name}</span></span>
                                }
                            >
                                {sub.children.map((item, itemIndex) => {

                                    if (!item.show) {
                                        return null
                                    }

                                    return (
                                        <Menu.Item
                                            className={'menu-two'}
                                            key={`sub${subIndex}-${itemIndex}`}
                                            url={item.url}>{item.name}</Menu.Item>
                                    )
                                })}
                            </SubMenu>
                        )
                    })}
                </Menu>
            </Sider>
            <Layout>
                <Header style={{padding: 0}}>

                    {
                        collapsed ?
                            <MenuUnfoldOutlined
                                className="trigger"
                                onClick={toggle}
                            /> :
                            <MenuFoldOutlined
                                className="trigger"
                                onClick={toggle}
                            />
                    }

                    <Menu
                        theme={headerTheme}
                        mode="horizontal"
                        defaultSelectedKeys={['2']}
                        style={{lineHeight: '64px', float: 'left'}}
                    >
                        {common.getUser().type !== 'supplier' &&
                            <SubMenu
                                className={'menu-top'}
                                title={
                                    <span>{props.user.company.name}</span>
                                }
                            >
                                {
                                    companies.map(item => {
                                        return <Menu.Item key={item.id} onClick={() => {
                                            Search.clearParams()
                                            setUser("company", item)
                                        }}>{item.name}</Menu.Item>
                                    })
                                }
                            </SubMenu>}
                        {common.getUser().type !== 'supplier' &&
                            <SubMenu
                                className={'menu-top'}
                                title={
                                    <span>{props.user.brand.name ? props.user.brand.name : '[未选择品牌]'}</span>
                                }
                            >
                                {
                                    brands.map(item => {
                                        return <Menu.Item key={item.id} onClick={() => {
                                            setUser("brand", item)
                                        }}>{item.name}</Menu.Item>
                                    })
                                }
                            </SubMenu>}
                        {common.isShowTenant() &&
                            <Menu.Item
                                className={'menu-top'}
                                key="8" onClick={() => {
                                    props.history.push('/admin')
                                }}>集团后台</Menu.Item>}
                    </Menu>

                    <Menu
                        theme={headerTheme}
                        mode="horizontal"
                        defaultSelectedKeys={['2']}
                        style={{lineHeight: '64px', float: 'right'}}
                    >
                        {common.getUser().type !== 'supplier' &&
                            <Menu.Item key="0"
                                onClick={() => props.history.push('/crm/todo/mine')}>待办任务</Menu.Item>}
                        {common.getUser().type !== 'supplier' &&
                            <Menu.Item key="1" onClick={() => props.history.push('/ext/activity')}>我的审批</Menu.Item>}
                        <SubMenu
                            title={
                                <span className="submenu-title-wrapper">
                                    {props.user.nickname}
                                </span>
                            }
                        >
                            <Menu.Item key="newWindow" onClick={() => {
                                window.open(window.location.protocol + '//' + window.location.host, '_blank')
                            }}>多开窗口</Menu.Item>
                            <Menu.Item key="changePassword" onClick={() => {
                                setPassVisible(true)
                            }}>修改密码</Menu.Item>
                            <Menu.Item key="logout" onClick={() => {
                                common.setToken(null)
                                common.redirectToLogin()
                            }}>退出登录</Menu.Item>
                        </SubMenu>
                    </Menu>

                </Header>

                <Content key={common.getUser().company.id + common.getUser().brand.id} className={'content'}>
                    {props.children}
                </Content>
            </Layout>

            <div>
                <Modal
                    destroyOnClose={true}
                    maskClosable={false}
                    footer={null}
                    width={1000}
                    visible={passVisible}
                    onCancel={() => {
                        setPassVisible(false)
                    }}
                >
                    <ChangePassword onOk={() => {
                        setPassVisible(false)
                        common.alert('密码修改成功，请重新登录', () => {
                            common.setToken(null)
                            common.redirectToLogin()
                        })
                    }}></ChangePassword>
                </Modal>
            </div>
        </Layout>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        permission: state.permission,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        },
        changeTasks: (tasks) => {
            dispatch({type: 'CHANGE_TASKS', tasks: tasks})
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideLayout))

