import React, {useEffect, useState} from "react"
import {Button, Col, Divider, Form, Input, Row, Table, Select} from "antd"

import common from "../../../../utils/common"
import moment from "moment";
import PageTop from "../../../../components/layout/PageTop";
import {PrinterOutlined, SaveOutlined} from "@ant-design/icons";
import {Coupon, TaxonomyEnum} from "../../../../components/coupon/config";
import {SupportImages} from "../../../../components/support/config";
import BigNumber from "bignumber.js";

function AppliedForm(props) {
    let {defaultValue = [], onOk} = props

    let [ticketList, setTicketList] = useState(defaultValue)
    let [typeIsOther, setTypeIsOther] = useState(false)
    let [number, setNumber] = useState(0)
    let [appliedData, setAppliedData] = useState({
        typeName: "",
        spec: "",
    })
    let [ticketAppliedTypeList, setTicketAppliedTypeList] = useState([])
    let [company, setCompany] = useState({})
    let [imageUrl, setImageUrl] = useState(null);
    let [buttonVisible, setButtonVisible] = useState(false)

    const [form] = Form.useForm()
    const {Item: FormItem} = Form

    // 获取打印logo
    let getPrintLogo = () => {
        common.loadingStart()
        common.ajax('get', '/support/images/list', {
            ownerId: common.getUser().company.id,
            type: SupportImages.TYPE_PRINT_LOGO
        })
            .then(res => {
                if (res.length > 0 && res[0].url !== "") {
                    setImageUrl(res[0].url)
                }
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    useEffect(() => {
        getPrintLogo()
    }, [])

    // 表单提交
    let onFinish = (values) => {
        applied({
            ownerId: common.getUser().company.id,
            spec: values.spec,
            type: values.type,
            ids: ticketList.map(item => item.id)
        })
    }

    // 核销卡券
    const applied = (params) => {
        common.loadingStart()
        common.ajax('post', '/coupon/ticket/appliedByIds', params).then(() => {
            common.toast("核销成功")
            onOk()
            setButtonVisible(true)
        }).finally(common.loadingStop)
    }

    const columns2 = [
        {
            title: '编号名称',
            dataIndex: 'code',
            width: 200,
            ellipsis: true,
        },
        {
            title: '卡券名称',
            dataIndex: ['couponDto', 'name'],
            width: 200,
            ellipsis: true,
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '预收金额',
            dataIndex: ["couponDto", 'price'],
            width: 200,
            ellipsis: true,
        },
        {
            title: '最大抵扣金额',
            dataIndex: ["couponDto", 'reduceMax'],
            width: 200,
            ellipsis: true,
        },
    ];

    useEffect(() => {
        ticketList.forEach((item) => {
            if (item.couponDto.category1 === Coupon.CATEGORY1_ADVANCE) {
                setNumber(1)
            }
        })
    }, [ticketList])

    // 卡券核销原因
    let getTicketAppliedTypeList = () => {
        common.loadingStart()
        common.ajax('get', '/coupon/term/list', {
            taxonomy: TaxonomyEnum.TICKET_APPLIED_TYPE,
            status: TaxonomyEnum.TERM_STATUS_ENABLE,
            ownerId: '',
        }).then(res => {
            setTicketAppliedTypeList(res.terms)
        }).finally(() => {
            common.loadingStop()
        })
    }

    function getCompany() {
        common.loadingStart()
        common.ajax("get", "/passport/company/findById", {id: common.getUser().company.id}).then((data) => {
            setCompany(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        getCompany()
        getTicketAppliedTypeList()
    }, [])

    // 打印
    let print = () => {
        // 组装数据
        let printData = {
            "code": "coupon_ticket_applied",
            "owner_id": common.getUser().company.id,
            "data": {
                "logo": imageUrl || null,
                "company": {
                    "name": common.getUser().company.name,
                    "address": (company.provinceName || "") + (company.cityName || "") + (company.areaName || "") + (company.address || ""),
                    "phone": company.supportHotline || "",
                },
                "printAt": moment().format("YYYY-MM-DD HH:mm:ss"),
                "time": moment().format("YYYY-MM-DD"),// 操作时间
                "people": common.getUser().nickname,// 操作人
                "type": appliedData.typeName,
                "spec": appliedData.spec,
                "order": {
                    "customerName": ticketList[0].customerName,
                    "plate": ticketList[0].plate,
                    "vin": ticketList[0].vin,
                },
                "list": ticketList,
                "subtotal": {
                    "quantity": ticketList.length,
                    "total": ticketList.reduce((pre, cur) => new BigNumber(pre).plus(new BigNumber(cur.couponDto.reduceMax || "0.00")).toFixed(2), "0.00"),
                }
            }
        }
        common.loadingStart()
        common.ajax("post", "/ext/print/template/parse", printData).then((res) => {
            common.print(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <>
            <br/>
            <PageTop title={'卡券核销'}>
                <Button
                    disabled={!buttonVisible}
                    icon={<PrinterOutlined/>}
                    onClick={() => {
                        print()
                    }}>打印</Button>
                <Button
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    disabled={buttonVisible}
                    onClick={() => {
                        form.submit()
                    }}>保存</Button>
            </PageTop>
            <Form
                form={form}
                className={'ant-advanced-inline-form label-character-4'}
                initialValues={{
                    people: common.getUser().nickname,
                    time: moment(new Date().toLocaleDateString(), "YYYY/MM/DD").format('YYYY-MM-DD'),
                }}
                onFinish={onFinish}
            >
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem
                            name={'type'}
                            rules={[{required: true, message: '核销原因不允许为空'}]}
                            label={'核销原因'}
                        >
                            <Select onChange={(val) => {
                                let foundItem = ticketAppliedTypeList.find(item => item.name === val);
                                if (foundItem) {
                                    setAppliedData({
                                        ...appliedData,
                                        typeName: foundItem.spec || ""
                                    })
                                    setTypeIsOther(foundItem.flag === TaxonomyEnum.SETTING_YES)
                                }
                            }}>
                                {ticketAppliedTypeList.map((item) => {
                                    return <Select.Option value={item.name}
                                                          key={item.name}>{item.spec}</Select.Option>
                                })}
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            name={'people'}
                            rules={[{required: true, message: '操作人不允许为空'}]}
                            label={'操作人'}
                        >
                            <Input disabled={true}/>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            name={'time'}
                            rules={[{required: true, message: '操作时间不允许为空'}]}
                            label={'操作时间'}
                        >
                            <Input disabled={true}/>
                        </FormItem>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24}>
                        <FormItem
                            name={'spec'}
                            rules={typeIsOther ? [{
                                required: true,
                                message: '核销备注不允许为空'
                            }] : []}
                            label={'核销备注'}
                        >
                            <Input onChange={(e) => {
                                setAppliedData({
                                    ...appliedData,
                                    spec: e.target.value || ""
                                })
                            }}/>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
            <Divider orientation="left">卡券({ticketList.length})</Divider>
            <Table
                rowKey={'id'}
                pagination={false}
                dataSource={ticketList}
                columns={columns2}
                scroll={{x: '100%', y: "400px"}}
            />
            {number > 0 &&
                <div style={{color: 'red', marginTop: '5px'}}>提醒:
                    核销券中包含为预收款的卡券，如果进行核销会同时减少该客户的预收款。</div>}
        </>

    )
}

export default AppliedForm