import React from 'react';
import {Route, Switch} from "react-router-dom"

import Repair from "./repair"
import Vehicle from "./vehicle"
import Owner from './owner';
import Project from "./project"
import Plan from "./plan"
import Workshop from "./workshop"
import Appointment from "./appointment"
import Claim from "./claim"
import Delegate from "./delegate";
import Setting from "./setting";
import RepairBoard from './repair/components/board';
import InspectItems from './repair/components/InspectItems';

function Support() {

    return (
        <div>
            <Switch>
                <Route path={'/support/appointment'} component={Appointment} />
                <Route path={'/support/repair'} component={Repair} />
                <Route path={'/support/delegate'} component={Delegate} />
                <Route path={'/support/project'} component={Project} />
                <Route path={'/support/outsource'} component={Project} />
                <Route path={'/support/plan'} component={Plan} />
                <Route path={'/support/workshop'} component={Workshop} />
                <Route path={'/support/vehicle'} component={Vehicle} />
                <Route path={'/support/owner'} component={Owner} />
                <Route path={'/support/claim'} component={Claim} />
                <Route path={'/support/setting'} component={Setting} />
                <Route path={'/support/board'} component={RepairBoard} />
                <Route path={'/support/InspectItems'} component={InspectItems} />
            </Switch>
        </div>
    )

}

export default Support

