import React, {useEffect, useState} from "react"
import common from "../../../utils/common";
import BigNumber from "bignumber.js";
import {Button} from "antd";
import moment from "moment";
import {PrinterOutlined} from "@ant-design/icons";
import {SupportImages} from "../../support/config";

function Index(props) {

    let {receiptId, selectedReceiptItemIds = []} = props

    let initialReceipt = {
        type: 0,
        receiptItems: []
    }

    let [imageUrl, setImageUrl] = useState(null);// logo
    let [receipt, setReceipt] = useState(initialReceipt) // 收款单详情
    let [receiptItems, setReceiptItems] = useState([]) // 收款明细
    let [receiptMoney, setReceiptMoney] = useState("0.00") // 收款合计
    let [businessCategoryMap, setBusinessCategoryMap] = useState(new Map()) //财务业务类别

    // 获取收款单信息
    useEffect(() => {
        if (receiptId) {
            let params = {id: receiptId}
            common.loadingStart()
            common.ajax('get', '/finance/receipt/findById', params).then(res => {
                setReceipt(res)
            }).finally(common.loadingStop)
        }
    }, [receiptId])

    // 设置收款单明细
    useEffect(() => {
        receiptItems = receipt.receiptItems
        // 筛选出选择的预收款明细
        if (selectedReceiptItemIds.length > 0) {
            let newArr = []
            for (let i = 0; i < receiptItems.length; i++) {
                if (selectedReceiptItemIds.includes(receiptItems[i].id)) {
                    newArr.push = receiptItems[i].id
                }
                receiptItems = newArr
            }
        }
        // 设置财务业务类别名称
        receiptItems = setBusinessCategoryNameFor(receiptItems)
        setReceiptItems(receiptItems)
    }, [receipt])


    // 设置收款合计
    useEffect(() => {
        receiptMoney = "0.00"
        receiptItems.forEach((item) => {
            receiptMoney = common.numberCut(new BigNumber(receiptMoney).plus(item.money).toString(), 2)
        })
        setReceiptMoney(receiptMoney)
    }, [receiptItems])

    // 财务业务类别
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/finance/term/findByTaxonomy', {taxonomy: 'businessCategory'})
            .then(res => {
                for (let i = 0; i < res.length; i++) {
                    businessCategoryMap.set(res[i].id, res[i].name)
                }
                setBusinessCategoryMap(businessCategoryMap)
            })
            .finally(common.loadingStop)
    }, [])


    // 设置收款单明细的财务业务类型名称
    let setBusinessCategoryNameFor = (receiptItems) => {
        for (let i = 0; i < receiptItems.length; i++) {
            if (businessCategoryMap.get(receiptItems[i].businessCategoryId)) {
                receiptItems[i].businessCategoryName = businessCategoryMap.get(receiptItems[i].businessCategoryId)
            }
        }
        return receiptItems
    }

    // 获取打印logo
    let getPrintLogo = () => {
        common.loadingStart()
        common.ajax('get', '/support/images/list', {
            ownerId: common.getUser().company.id,
            type: SupportImages.TYPE_PRINT_LOGO
        })
            .then(res => {
                if (res.length > 0 && res[0].url !== "") {
                    setImageUrl(res[0].url)
                }
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    useEffect(() => {
        getPrintLogo()
    }, [])


    // 打印预收款
    let print = () => {

        let ADVANCE_SIMPLE = "0.00"
        if (receipt.payments.ADVANCE_SALE !== undefined) {
            ADVANCE_SIMPLE = receipt.payments.ADVANCE_SALE
        }
        if (receipt.payments.ADVANCE_SUPPORT !== undefined) {
            ADVANCE_SIMPLE = receipt.payments.ADVANCE_SUPPORT
        }
        let total = common.numberCut(new BigNumber(receipt.payments.CASH === undefined ? "0.00" : receipt.payments.CASH)
            .plus(receipt.payments.ALIPAY === undefined ? "0.00" : receipt.payments.ALIPAY)
            .plus(receipt.payments.WXPAY === undefined ? "0.00" : receipt.payments.WXPAY)
            .plus(receipt.payments.BANK === undefined ? "0.00" : receipt.payments.BANK)
            .plus(receipt.payments.POS === undefined ? "0.00" : receipt.payments.POS)
            .plus(ADVANCE_SIMPLE)
            .plus(receipt.payments.ADVANCE_VIP === undefined ? "0.00" : receipt.payments.ADVANCE_VIP)
            .plus(receipt.payments.ADVANCE_PAY === undefined ? "0.00" : receipt.payments.ADVANCE_PAY)
            .plus(receipt.payments.COUPON === undefined ? "0.00" : receipt.payments.COUPON).toString(), 2)

        let data = {
            "code": "finance_advance",
            "owner_id": common.getUser().company.id,
            "data": {
                "companyName": common.getUser().company.name,
                "createdAt": receipt.createdAt,
                "code": receipt.code,
                "typeName": "预收收款",
                "customerName": receipt.customerName,
                "customerCode": receipt.customerCode,
                "CASH": receipt.payments.CASH ? receipt.payments.CASH : "0.00",
                "WXPAY": receipt.payments.WXPAY ? receipt.payments.WXPAY : "0.00",
                "ALIPAY": receipt.payments.ALIPAY ? receipt.payments.ALIPAY : "0.00",
                "BANK": receipt.payments.BANK ? receipt.payments.BANK : "0.00",
                "POS": receipt.payments.POS ? receipt.payments.POS : "0.00",
                "ADVANCE_SIMPLE": ADVANCE_SIMPLE,
                "ADVANCE_VIP": receipt.payments.ADVANCE_VIP ? receipt.payments.ADVANCE_VIP : "0.00",
                "ADVANCE_PAY": receipt.payments.ADVANCE_PAY ? receipt.payments.ADVANCE_PAY : "0.00",
                "COUPON": receipt.payments.COUPON ? receipt.payments.COUPON : "0.00",
                "total": total,
                "spec": receipt.spec,
                "receiptItems": receipt.receiptItems,
                "receiptMoney": receiptMoney,
                "logo": imageUrl || null,
                "totalUpper": common.moneyToChinese(receiptMoney),// 大写,
                "creatorName": receipt.creatorName,
                "customerPhone": common.maskPhone(receipt.customerPhone),
                "vin": receipt.businessOrders[0].vin,
                "type": receipt.type === 1 ? '销售预收' : "售后预收",
                "plate": receipt.businessOrders[0].plate,
                "printAt": moment().format("YYYY-MM-DD HH:mm:ss"),
                "actualAt": receipt.actualAt,
                "paymentsTypeCodeMap": receipt.paymentsTypeCodeMap
            }
        }
        common.loadingStart()
        common.ajax("post", "/ext/print/template/parse", data).then((res) => {
            common.print(res)
        }).finally(() => {
            common.loadingStop()
        })
    }


    return (
        <Button icon={<PrinterOutlined/>} onClick={print} type="primary">打印</Button>
    )

}

export default Index