import React, {useEffect, useState} from "react";
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    Modal,
    Pagination,
    Row,
    Select,
    Table,
    Tabs,
    Tooltip
} from 'antd';
import SearchArea from "../../../components/layout/SearchArea";
import {
    CloseOutlined,
    DownloadOutlined,
    QuestionCircleOutlined,
    ReloadOutlined,
    SearchOutlined,
    SelectOutlined
} from "@ant-design/icons";
import common from "../../../utils/common";
import moment from "moment";
import PageBottom from "../../../components/layout/PageBottom";
import LookupBrand from "../../../components/wms/LookupBrand";
import LookupProduct from "../../../components/wms/LookupProduct";
import LookupSeries from "../../../components/wms/LookupSeries";
import PageTop from "../../../components/layout/PageTop";
import BigNumber from "bignumber.js";
import LookupCompany from "../../passport/LookupCompany";
import SearchPlanButton from "../SearchPlanButton";
import "./index.less"
import PrepayForm from "../PrepayForm/index";
import LookupEmployee from "../../passport/LookupEmployee";
import LookupPartner from "../../passport/LookupPartner";
import ExportButton from "../../export";

const FormItem = Form.Item
const {RangePicker} = DatePicker;
const Option = Select.Option
const {TabPane} = Tabs;

//维修明细类型 : 1.工时费用
const REPAIR_ITEM_TYPE_PROJECT = "1"

//维修业务的状态 1启用 2禁用
const BUSINESS_KIND_STATUS_ENABLE = 1;

// 收费区分 1:客户自费、2:保险维修、3:厂家索赔、4:委托费用
const CHARGE_TYPE_CUSTOMER = 1;
const CHARGE_TYPE_INSURE = 2;
const CHARGE_TYPE_FACTORY = 3;
// const CHARGE_TYPE_DELEGATE = 4;

//收费区分名字
let chargeTypeAlias = {
    [CHARGE_TYPE_CUSTOMER]: '客户自费',
    [CHARGE_TYPE_INSURE]: '保险维修',
    [CHARGE_TYPE_FACTORY]: '厂家索赔',
}

//分组类型
let GROUP_TYPE_ITEM = "1"
let GROUP_TYPE_BUSINESS_KIND = "2"
let GROUP_TYPE_CHARGE_TYPE = "3"
let GROUP_TYPE_EMPLOYEE = "4"
let GROUP_TYPE_COMPANY = "5"
let GROUP_TYPE_CATEGORY = "6"
let GROUP_TYPE_SERIES = "7"
let GROUP_TYPE_INSURANCE = "8"


//收费区分名称
let groupTypeAlias = {
    [GROUP_TYPE_BUSINESS_KIND]: 'businessKind',
    [GROUP_TYPE_CHARGE_TYPE]: 'chargeType',
    [GROUP_TYPE_EMPLOYEE]: 'employee',
    [GROUP_TYPE_COMPANY]: 'company',
    [GROUP_TYPE_CATEGORY]: 'category',
    [GROUP_TYPE_SERIES]: 'series',
    [GROUP_TYPE_INSURANCE]: 'insurance',
}

//工单状态 0.全部 1.未收款 2.已收款
const REPAIR_STATUS_ALL = 0
const REPAIR_STATUS_CLEARING = 1
const REPAIR_STATUS_CLEARED = 2

function ReportProfit(props) {

    //url地址
    //url '/support/repair' String类型
    //是否是集团调用
    //isTenant ture:是     false:否
    let {url, isTenant} = props

    let initialSearch = {
        // type: REPAIR_ITEM_TYPE_PROJECT,
        repairCreatedAtStart: "",
        repairCreatedAtEnd: "",
        settlementAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        settlementAtEnd: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        sectionId: "",
        workerId: "",
        groupId: "",
        brandId: "",
        repairCategoryId: "",
        businessKindIds: [],
        categoryIds: [],
        chargeType: "",
        settlementStatus: 0,
        employeeId: "",
        employee: "",
        companyNameList: "",
        companyIdList: [],
        brandName: "",
        departmentId: "",
        productName: "",
        productId: "",
        seriesId: "",
        seriesName: "",
        repairCode: "",
        customerName: "",
        plate: "",
        insuranceId: "",
        insuranceName: "",
        spec: "",
        name: "",
        phone: "",
        buyDateStart: "",
        buyDateEnd: "",
        companyId: props.isTenant ? "" : common.getUser().company.id
    }

    const initProfit = {
        tenantId: "",
        ownerId: "",
        creatorId: "",
        companyId: "",
        repairCreatedAt: null,
        settlementAt: null,
        repairId: "",
        repairCode: "",
        customerId: "",
        customerName: "",
        plate: "",
        brandId: "",
        brandName: "",
        seriesId: "",
        seriesName: "",
        productId: "",
        productName: "  ",
        departmentId: "",
        departmentName: " ",
        employeeId: "",
        employeeName: "",
        projectTotal: "",
        projectDiscount: "",
        projectActualPayPrice: "",
        projectCost: "",
        projectProfit: "",
        productTotal: "",
        productDiscount: "",
        productActualPayPrice: "",
        productCost: "",
        productProfit: "",
        total: "",
        discount: "",
        price: "",
        cost: "",
        profit: "",
        flag: "",
    }

    const initialSummaries = {
        repairNum: 0,
        customerNum: 0,
        projectTotal: "0.00",
        projectDiscount: "0.00",
        projectActualPayToTal: "0.00",
        projectCost: "0.00",
        projectProfit: "0.00",
        productTotal: "0.00",
        productDiscount: "0.00",
        productActualPayToTal: "0.00",
        productCost: "0.00",
        productProfit: "0.00",
        total: "0.00",
        discount: "0.00",
        actualPayToTal: "0.00",
        cost: "0.00",
        profit: "0.00",
        profitRate: "0.00",
        typeId: "",
        typeName: "",
        customerAvgTotal: "0.00",
        price: "0.00",
        customerAvgPrice: "0.00",
        customerAvgProfit: "0.00",
    }

    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [total, setTotal] = useState(0)     //总记录条数
    let [profitList, setProfitList] = useState([initProfit]) //毛利列表
    let [businessKinds, setBusinessKinds] = useState([]) //业务类别
    let [categories, setCategories] = useState([]) // 工单类型
    let [companyList, setCompanyList] = useState([]) //有权限访问的公司列表
    let [tabKey, setTabKey] = useState(isTenant ? GROUP_TYPE_COMPANY : GROUP_TYPE_BUSINESS_KIND) //标签页的key
    let [counts, setCounts] = useState([]) //统计数据
    let [summaries, setSummaries] = useState(initialSummaries);
    let [selectedRepairId, setSelectedRepairId] = useState("") //选择的业务单号
    let [prepayFormVisible, setPrepayFormVisible] = useState(false)//预结算详情
    let [lookupBrandVisible, setLookupBrandVisible] = useState(false)    //选择品牌
    let [lookupSeriesVisible, setLookupSeriesVisible] = useState(false) //选择车系
    let [lookupProductVisible, setLookupProductVisible] = useState(false) //选择车型
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false)//选择公司
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)//选择服务顾问
    let [lookupPartnerVisible, setLookupPartnerVisible] = useState(false)
    let [pagination, setPagination] = useState({page: 1, limit: 10})

    //维修毛利明细列
    let columns = [
        {
            title: '报修日期',
            width: 120,
            align: "center",
            dataIndex: 'repairCreatedAt',
            ellipsis: true,
            render: (text, record, index) => {
                if (index === profitList.length - 1) {
                    return "合计"
                } else if (index === profitList.length - 2) {
                    return "小计"
                } else {
                    return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                }
            }
        },
        {
            title: '结算日期',
            width: 120,
            align: "center",
            dataIndex: 'settlementAt',
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '业务单号',
            width: 150,
            dataIndex: 'repairCode',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <span style={{color: "#1890ff", cursor: "pointer"}} onClick={() => {
                        selectedRepairId = record.repairId
                        setSelectedRepairId(selectedRepairId)
                        setPrepayFormVisible(true)
                    }}>{text}</span>
                )
            }
        },
        {
            title: '客户名称',
            width: 150,
            ellipsis: true,
            dataIndex: 'customerName',
        },
        {
            title: '车牌号',
            width: 150,
            ellipsis: true,
            dataIndex: 'plate',
        },
        {
            title: '车系',
            width: 150,
            ellipsis: true,
            dataIndex: 'seriesName',
        },
        {
            title: '车型',
            width: 200,
            dataIndex: 'productName',
            ellipsis: true,
        },
        {
            title: 'VIN',
            width: 200,
            dataIndex: 'vin',
            ellipsis: true,
        },
        {
            title: '备用VIN',
            width: 200,
            dataIndex: 'spareFrameNumber',
            ellipsis: true,
        },
        {
            title: '业务部门',
            width: 150,
            ellipsis: true,
            dataIndex: 'departmentName',
        },
        {
            title: '服务顾问',
            width: 150,
            ellipsis: true,
            dataIndex: 'employeeName',
        },
        {
            title: '台次',
            dataIndex: 'repairNum',
            width: 80,
            ellipsis: true,
            align: "right",
        },
        {
            title: '总工时',
            width: 150,
            align: "right",
            dataIndex: 'projectTotal',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '工时优惠',
            width: 150,
            align: "right",
            dataIndex: 'projectDiscount',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '优惠后工时',
            width: 150,
            align: "right",
            dataIndex: 'projectActualPayPrice',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '工时成本',
            width: 150,
            align: "right",
            dataIndex: 'projectCost',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '工时毛利',
            dataIndex: 'projectProfit',
            align: "right",
            width: 120,
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '总材料',
            width: 150,
            align: "right",
            dataIndex: 'productTotal',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '材料优惠',
            width: 150,
            align: "right",
            dataIndex: 'productDiscount',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '优惠后材料',
            width: 150,
            align: "right",
            dataIndex: 'productActualPayPrice',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '材料成本',
            width: 150,
            align: "right",
            dataIndex: 'productCost',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '材料毛利',
            width: 150,
            align: "right",
            dataIndex: 'productProfit',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: <div>材料毛利率 <Tooltip title="材料毛利率 = 材料毛利 / 优惠后材料">
                <QuestionCircleOutlined/>
            </Tooltip></div>,
            width: 150,
            align: "right",
            dataIndex: 'productProfitRate',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text) + "%"
            }
        },
        {
            title: '总产值',
            width: 150,
            align: "right",
            dataIndex: 'total',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '优惠合计',
            width: 120,
            align: "right",
            dataIndex: 'discount',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '净产值',
            width: 150,
            align: "right",
            dataIndex: 'price',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '工单成本',
            width: 150,
            align: "right",
            dataIndex: 'cost',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '工单毛利',
            width: 150,
            align: "right",
            dataIndex: 'profit',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: <div>工单毛利率 <Tooltip title="工单毛利率 = 工单毛利 / 净产值">
                <QuestionCircleOutlined/>
            </Tooltip></div>,
            width: 150,
            align: "right",
            dataIndex: 'profitRate',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text) + "%"
            }
        },
        {
            title: '单据标识',
            width: 150,
            dataIndex: 'flag',
            ellipsis: true,
        },
        {
            title: '预结算备注',
            width: 200,
            dataIndex: 'prepaySpec',
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '结算备注',
            width: 200,
            dataIndex: 'clearingSpec',
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '工单类型',
            width: 200,
            dataIndex: 'categoryName',
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '进场里程',
            width: 100,
            dataIndex: 'mileage',
            align: "right",
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '车主电话',
            width: 150,
            dataIndex: 'vehicleMasterPhone',
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '外观颜色',
            width: 100,
            dataIndex: 'vehicleColor',
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '购车时间',
            width: 100,
            dataIndex: 'buyDate',
            ellipsis: true,
            render: text => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '索赔厂家',
            width: 200,
            dataIndex: 'partnerName',
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '车辆来源',
            width: 100,
            dataIndex: 'vehicleType',
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '车辆用途',
            width: 100,
            dataIndex: 'vehiclePurpose',
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '车辆类型',
            width: 100,
            dataIndex: 'vehicleMode',
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '档案创建日期',
            width: 100,
            dataIndex: 'vehicleCreatedAt',
            ellipsis: true,
            render: text => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '保险公司',
            width: 200,
            dataIndex: 'insuranceName',
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '保险联系人',
            width: 100,
            dataIndex: 'premiumName',
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '联系电话',
            width: 150,
            dataIndex: 'premiumPhone',
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '起保日期',
            width: 100,
            dataIndex: 'premiumStartAt',
            ellipsis: true,
            render: text => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '截止日期',
            width: 100,
            dataIndex: 'premiumEndAt',
            ellipsis: true,
            render: text => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '审车日期',
            width: 100,
            dataIndex: 'premiumAuditAt',
            ellipsis: true,
            render: text => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '保险备注',
            width: 100,
            dataIndex: 'premiumSpec',
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '送修人',
            width: 100,
            dataIndex: 'name',
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '送修人电话',
            width: 130,
            dataIndex: 'phone',
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '注册日期',
            width: 100,
            dataIndex: 'registerDate',
            ellipsis: true,
            render: text => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '竣工时间',
            width: 100,
            dataIndex: 'finishAt',
            ellipsis: true,
            render: text => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '预结时间',
            width: 100,
            dataIndex: 'prepayAt',
            ellipsis: true,
            render: text => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '工单备注',
            width: 100,
            dataIndex: 'spec',
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '检验备注',
            width: 100,
            dataIndex: 'inspection',
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '故障描述',
            width: 100,
            dataIndex: 'failure',
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '客户编号',
            width: 120,
            dataIndex: 'customerCode',
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '档案编号',
            width: 120,
            dataIndex: 'vehicleCode',
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
    ]

    //子表格
    let expandedRowRender = (record) => {
        let key = 0
        if (!record.items) {
            return
        }
        record.items.forEach(item => {
            item.key = key
            key++
        })
        const columns = [
            {
                title: '报修日期',
                width: 120,
                align: "center",
                dataIndex: 'repairCreatedAt',
                ellipsis: true,
                render: () => {
                    return record.repairCreatedAt === null ? null : moment(record.repairCreatedAt, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                }
            },
            {
                title: '结算日期',
                width: 120,
                align: "center",
                dataIndex: 'settlementAt',
                ellipsis: true,
                render: () => {
                    return record.settlementAt === null ? null : moment(record.settlementAt, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                }
            },
            {
                title: '业务单号',
                width: 150,
                dataIndex: 'repairCode',
                ellipsis: true,
                render: () => {
                    return record.repairCode
                }
            },
            {
                title: '客户名称',
                width: 150,
                ellipsis: true,
                dataIndex: 'customerName',
                render: () => {
                    return record.customerName
                }
            },
            {
                title: '车牌号',
                width: 150,
                dataIndex: 'plate',
                ellipsis: true,
                render: () => {
                    return record.plate
                }
            },
            {
                title: '车系',
                width: 150,
                dataIndex: 'seriesName',
                ellipsis: true,
                render: () => {
                    return record.seriesName
                }
            },
            {
                title: '车型',
                width: 200,
                dataIndex: 'productName',
                ellipsis: true,
                render: () => {
                    return record.productName
                }
            },
            {
                title: '业务部门',
                width: 150,
                dataIndex: 'departmentName',
                ellipsis: true,
                render: () => {
                    return record.departmentName
                }
            },
            {
                title: '付费类型',
                width: 230,
                align: "center",
                dataIndex: 'chargeTypeName',
                ellipsis: true,
                render: text => {
                    return <div style={{color: "#EF4566"}}>{text}</div>
                }
            },
            {
                title: '总工时',
                width: 150,
                align: "right",
                dataIndex: 'projectTotal',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '工时优惠',
                width: 150,
                align: "right",
                dataIndex: 'projectDiscount',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '优惠后工时',
                width: 150,
                align: "right",
                dataIndex: 'projectActualPayPrice',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '工时成本',
                width: 150,
                align: "right",
                dataIndex: 'projectCost',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '工时毛利',
                dataIndex: 'projectProfit',
                align: "right",
                width: 120,
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '总材料',
                width: 150,
                align: "right",
                dataIndex: 'productTotal',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '材料优惠',
                width: 150,
                align: "right",
                dataIndex: 'productDiscount',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '优惠后材料',
                width: 150,
                align: "right",
                dataIndex: 'productActualPayPrice',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '材料成本',
                width: 150,
                align: "right",
                dataIndex: 'productCost',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '材料毛利',
                width: 150,
                align: "right",
                dataIndex: 'productProfit',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '总产值',
                width: 150,
                align: "right",
                dataIndex: 'total',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '优惠合计',
                width: 120,
                align: "right",
                dataIndex: 'discount',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '净产值',
                width: 150,
                align: "right",
                dataIndex: 'price',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '工单成本',
                width: 150,
                align: "right",
                dataIndex: 'cost',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '工单毛利',
                width: 150,
                align: "right",
                dataIndex: 'profit',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '',
                width: 300,
                ellipsis: true,
            },
        ];
        return (
            <div style={{paddingTop: 10, paddingBottom: 10}}>
                <Table columns={columns} dataSource={record.items} pagination={false}/>
            </div>
        )
    };

    //维修毛利统计列
    let reportColumns = [
        {
            title: '',
            width: 120,
            dataIndex: 'typeName',
            ellipsis: true,
        },
        {
            title: <div>客户数 <Tooltip title="客户数：在结算日期内,某vin号有多次维修结算台次,只算一个客户。"><QuestionCircleOutlined/> </Tooltip>
            </div>,
            width: 120,
            dataIndex: 'customerNum',
            align: "right",
            ellipsis: true,
        },
        {
            title: <div>台次 <Tooltip title="台次：在结算日期内的维修工单数。"><QuestionCircleOutlined/> </Tooltip></div>,
            width: 120,
            dataIndex: 'repairNum',
            align: "right",
            ellipsis: true,
        },
        {
            title: <div>台数 <Tooltip title="台次在结算日期内,某vin号有多次维修结算台次,且是同一天只算一个台数。"><QuestionCircleOutlined/> </Tooltip>
            </div>,
            width: 120,
            dataIndex: 'vehicleNum',
            align: "right",
            ellipsis: true,
        },
        {
            title: '工时收入',
            width: 120,
            align: "right",
            dataIndex: 'projectActualPayPrice',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '工时成本',
            width: 120,
            align: "right",
            dataIndex: 'projectCost',
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '工时毛利',
            width: 120,
            align: "right",
            dataIndex: 'projectProfit',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '材料收入',
            width: 120,
            align: "right",
            dataIndex: 'productActualPayPrice',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '材料成本',
            width: 120,
            align: "right",
            dataIndex: 'productCost',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '材料毛利',
            width: 120,
            align: "right",
            dataIndex: 'productProfit',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: <div>材料毛利率 <Tooltip title="材料毛利率 = 材料毛利 / 优惠后材料">
                <QuestionCircleOutlined/>
            </Tooltip></div>,
            width: 150,
            align: "right",
            dataIndex: 'productProfitRate',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text) + "%"
            }
        },
        {
            title: '净产值',
            width: 120,
            align: "right",
            dataIndex: 'actualPayPrice',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: <div>维修成本 <Tooltip title="维修成本 = 工时成本 + 材料成本">
                <QuestionCircleOutlined/>
            </Tooltip></div>,
            width: 120,
            align: "right",
            dataIndex: 'cost',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: "维修毛利",
            width: 120,
            align: "right",
            dataIndex: 'profit',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: <div>毛利率 <Tooltip title="毛利率 = 总毛利 / 维修收入">
                <QuestionCircleOutlined/>
            </Tooltip></div>,
            width: 120,
            align: "right",
            dataIndex: 'profitRate',
            ellipsis: true,
            render: text => {
                return text + "%"
            }
        },
    ]

    //按服务顾问的维修统计列
    let statisticsByEmployeeColumns = [
        {
            title: '',
            width: 120,
            dataIndex: 'typeName',
            ellipsis: true,
        },
        {
            title: <div>客户数 <Tooltip title="客户数：在结算日期内,某vin号有多次维修结算台次,只算一个客户。"><QuestionCircleOutlined/> </Tooltip>
            </div>,
            width: 120,
            dataIndex: 'customerNum',
            align: "right",
            ellipsis: true,
        },
        {
            title: <div>台次 <Tooltip title="台次：在结算日期内的维修工单数。"><QuestionCircleOutlined/> </Tooltip></div>,
            width: 120,
            dataIndex: 'repairNum',
            align: "right",
            ellipsis: true,
        },
        {
            title: <div>台数 <Tooltip title="台次在结算日期内,某vin号有多次维修结算台次,且是同一天只算一个台数。"><QuestionCircleOutlined/> </Tooltip>
            </div>,
            width: 120,
            dataIndex: 'vehicleNum',
            align: "right",
            ellipsis: true,
        },
        {
            title: '工时收入',
            width: 120,
            align: "right",
            dataIndex: 'projectActualPayPrice',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '工时成本',
            width: 120,
            align: "right",
            dataIndex: 'projectCost',
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '工时毛利',
            width: 120,
            align: "right",
            dataIndex: 'projectProfit',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '材料收入',
            width: 120,
            align: "right",
            dataIndex: 'productActualPayPrice',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '材料成本',
            width: 120,
            align: "right",
            dataIndex: 'productCost',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: "材料毛利",
            width: 120,
            align: "right",
            dataIndex: 'productProfit',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: <div>材料毛利率 <Tooltip title="材料毛利率 = 材料毛利 / 优惠后材料">
                <QuestionCircleOutlined/>
            </Tooltip></div>,
            width: 150,
            align: "right",
            dataIndex: 'productProfitRate',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text) + "%"
            }
        },
        {
            title: '净产值',
            width: 120,
            align: "right",
            dataIndex: 'actualPayPrice',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: <div>维修成本 <Tooltip title="维修成本 = 工时成本 + 材料成本">
                <QuestionCircleOutlined/>
            </Tooltip></div>,
            width: 120,
            align: "right",
            dataIndex: 'cost',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '维修毛利',
            width: 120,
            align: "right",
            dataIndex: 'profit',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '毛利率',
            width: 120,
            align: "right",
            dataIndex: 'profitRate',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text) + " %"
            }
        },
        {
            title: "其中:客户自费",
            children: [
                {
                    title: '净产值',
                    dataIndex: 'customerActualPayPrice',
                    key: 'customerActualPayPrice',
                    align: "right",
                    width: 150,
                    ellipsis: true,
                    render: text => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '成本',
                    dataIndex: 'customerCost',
                    key: 'customerCost',
                    align: "right",
                    width: 150,
                    ellipsis: true,
                    render: text => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '工时毛利',
                    dataIndex: 'customerProjectProfit',
                    key: 'customerProjectProfit',
                    align: "right",
                    width: 150,
                    ellipsis: true,
                    render: text => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '材料毛利',
                    dataIndex: 'customerProductProfit',
                    key: 'customerProductProfit',
                    align: "right",
                    width: 150,
                    ellipsis: true,
                    render: text => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '毛利合计',
                    dataIndex: 'customerProfit',
                    key: 'customerProfit',
                    align: "right",
                    width: 150,
                    ellipsis: true,
                    render: text => {
                        return common.numberFormat(text)
                    }
                },
            ]
        },
        {
            title: "其中:保险事故",
            children: [
                {
                    title: '净产值',
                    dataIndex: 'insuranceActualPayPrice',
                    key: 'insuranceActualPayPrice',
                    align: "right",
                    width: 150,
                    ellipsis: true,
                    render: text => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '成本',
                    dataIndex: 'insuranceCost',
                    key: 'insuranceCost',
                    align: "right",
                    width: 150,
                    ellipsis: true,
                    render: text => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '工时毛利',
                    dataIndex: 'insuranceProjectProfit',
                    key: 'insuranceProjectProfit',
                    align: "right",
                    width: 150,
                    ellipsis: true,
                    render: text => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '材料毛利',
                    dataIndex: 'insuranceProductProfit',
                    key: 'insuranceProductProfit',
                    align: "right",
                    width: 150,
                    ellipsis: true,
                    render: text => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '毛利合计',
                    dataIndex: 'insuranceProfit',
                    key: 'insuranceProfit',
                    align: "right",
                    width: 150,
                    ellipsis: true,
                    render: text => {
                        return common.numberFormat(text)
                    }
                },
            ]
        },
        {
            title: "其中:厂家索赔",
            children: [
                {
                    title: '净产值',
                    dataIndex: 'factoryActualPayPrice',
                    key: 'factoryActualPayPrice',
                    align: "right",
                    width: 150,
                    ellipsis: true,
                    render: text => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '成本',
                    dataIndex: 'factoryCost',
                    key: 'factoryCost',
                    align: "right",
                    width: 150,
                    ellipsis: true,
                    render: text => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '工时毛利',
                    dataIndex: 'factoryProjectProfit',
                    key: 'factoryProjectProfit',
                    align: "right",
                    width: 150,
                    ellipsis: true,
                    render: text => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '材料毛利',
                    dataIndex: 'factoryProductProfit',
                    key: 'factoryProductProfit',
                    align: "right",
                    width: 150,
                    ellipsis: true,
                    render: text => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '毛利合计',
                    dataIndex: 'factoryProfit',
                    key: 'factoryProfit',
                    align: "right",
                    width: 150,
                    ellipsis: true,
                    render: text => {
                        return common.numberFormat(text)
                    }
                },
            ]
        },
    ]

    //页码发生变化就请求数据
    useEffect(() => {
        if (tabKey === GROUP_TYPE_ITEM) {
            common.loadingStart()
            let params = {
                ...query,
                ...pagination
            }
            common.ajax('get', '/report/support/profitList', params)
                .then(res => {
                    profitList = (res.repairs) || []
                    profitList.forEach(profit => {
                        profit.items = profit.children
                        delete profit.children //删除children字段，不然会冒出新的父table的列
                    })
                    if (res.repairs.length > 0) {
                        setProfitList([...profitList, res.subtotal, res.total])
                    } else {
                        setProfitList([...profitList])
                    }
                    setTotal(res.pagination.total)
                    // 设置重点指标
                    summaries = res.summaries || initialSummaries
                    setSummaries({...summaries})
                }).finally(() => {
                common.loadingStop()
            })
        } else {
            //获取统计数据
            getCounts(tabKey)
        }
    }, [pagination, query, tabKey])

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    //获取业务类别
    let getBusinessKinds = () => {
        common.loadingStart()
        let params = {
            ownerId: "",
            status: BUSINESS_KIND_STATUS_ENABLE, //维修业务状态：1.启用
        }
        common.ajax('get', '/support/businessKind/list', params).then(res => {
            setBusinessKinds(res.businessKinds)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(() => {
        getBusinessKinds()  //获取业务类别
    }, [])

    //获取统计数据
    let getCounts = (key) => {
        common.loadingStart()
        let params = {
            ...query,
            type: groupTypeAlias[key],
        }
        common.ajax('get', '/report/support/countProfit', params).then(res => {
            setCounts([...res])
        }).finally(() => {
            common.loadingStop()
        })
    }

    //获取当前有权限访问的公司列表
    let getCompanyList = () => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/findCompaniesByAuthUserId')
            .then((res) => {
                companyList = res || []
                setCompanyList(companyList)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getCompanyList()
    }, [])

    //导出数据
    // let exportProfit = () => {
    //     common.loadingStart()
    //     common.ajax('get',
    //         '/report/support/profitListExport',
    //         {...search, exportType: 'profit'}, // search 参数
    //         {responseType: 'blob', filename: '维修毛利统计表.xlsx'})
    //         .finally(common.loadingStop())
    // }

    //获取工单类型
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/support/term/findByTaxonomy', {taxonomy: 'repairCategory'})
            .then((data) => {
                setCategories(data || [])
            })
            .finally(() => {
                common.loadingStop()
            })
    }, [])
    return (
        <div id={"report-profit-warp"}>

            <PageTop title={"维修毛利核算"}>
                <ExportButton
                    ownerId={search.companyId}
                    filename={'维修毛利统计表.xlsx'}
                    buttonName={'数据导出'}
                    params={JSON.stringify({
                        companyId:query.companyId,
                        repairCreatedAtStart: query.repairCreatedAtStart,
                        repairCreatedAtEnd: query.repairCreatedAtEnd,
                        settlementAtStart: query.settlementAtStart,
                        settlementAtEnd: query.settlementAtEnd,
                        settlementStatus: query.settlementStatus,
                        categoryIds: query.categoryIds,
                        seriesId: query.seriesId,
                        productId: query.productId,
                        customerName: query.customerName,
                        insuranceId: query.insuranceId,
                        businessKindIds: query.businessKindIds,
                        chargeType: query.chargeType,
                        plate: query.plate,
                        // 车架号码/备用VIN
                        bothVin: query.bothVin,
                        companyIdList: query.companyIdList,
                        brandId: query.brandId,
                        repairCode: query.repairCode,
                        employeeId: query.employeeId,
                    })}
                    type={'support_report_profit'}
                />
                {/*<Button icon={<DownloadOutlined/>} onClick={exportProfit}>数据导出</Button>*/}
            </PageTop>

            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>
                        {/*日期*/}
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"报修日期"} className={'label-character-3'}>
                                        <RangePicker
                                            value={[
                                                search.repairCreatedAtStart ? moment(search.repairCreatedAtStart) : null,
                                                search.repairCreatedAtEnd ? moment(search.repairCreatedAtEnd) : null
                                            ]}
                                            onChange={(val) => {
                                                //val不为null,设置搜索条件
                                                val ? setSearch({
                                                    ...search,
                                                    repairCreatedAtStart: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : null,
                                                    repairCreatedAtEnd: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : null,
                                                }) : setSearch({
                                                    ...search,
                                                    repairCreatedAtStart: "",
                                                    repairCreatedAtEnd: "",
                                                })
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"结算日期"} className={'label-character-3'}>
                                        <RangePicker
                                            value={[
                                                search.settlementAtStart ? moment(search.settlementAtStart) : null,
                                                search.settlementAtEnd ? moment(search.settlementAtEnd) : null
                                            ]}
                                            onChange={(val) => {
                                                //val不为null,设置搜索条件
                                                val ? setSearch({
                                                    ...search,
                                                    settlementAtStart: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : null,
                                                    settlementAtEnd: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : null,
                                                }) : setSearch({
                                                    ...search,
                                                    settlementAtStart: "",
                                                    settlementAtEnd: "",
                                                })
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"工单状态"} className={'label-character-3'}>
                                        <Select disabled={true} value={search.settlementStatus} onChange={(val) => {
                                            setSearch({...search, settlementStatus: val})
                                        }}>
                                            <Option key={REPAIR_STATUS_ALL} value={REPAIR_STATUS_ALL}>
                                                全部
                                            </Option>
                                            <Option key={REPAIR_STATUS_CLEARING} value={REPAIR_STATUS_CLEARING}>
                                                未收款
                                            </Option>
                                            <Option key={REPAIR_STATUS_CLEARED} value={REPAIR_STATUS_CLEARED}>
                                                已收款
                                            </Option>
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"工单类型"} className={'label-character-3'}>
                                        <Select value={search.categoryIds}
                                                placeholder={"请选择工单类型"}
                                                mode="multiple"
                                                onChange={value => {
                                                    setSearch({...search, categoryIds: value})
                                                }}>
                                            {
                                                categories.map(item => {
                                                    return (
                                                        <Option key={item.id} value={item.id}>{item.name}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"送修人"} className={'label-character-3'}>
                                        <Input value={search.name} placeholder={"请输入送修人"} onChange={e => {
                                            setSearch({...search, name: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"维修车系"}>
                                        <Input
                                            value={search.seriesName}
                                            readOnly
                                            placeholder="车系名称"
                                            suffix={
                                                search.seriesName ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setSearch({...search, seriesName: "", seriesId: ""})
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupSeriesVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupSeriesVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"维修车型"}>
                                        <Input
                                            readOnly={true}
                                            autoComplete="off"
                                            value={search.productName}
                                            placeholder="车型名称"
                                            suffix={
                                                search.productName ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            search({...search, productId: "", productName: ""})
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupProductVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupProductVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"客户名称"}>
                                        <Input value={search.customerName} placeholder={"请输入客户名称"} onChange={e => {
                                            setSearch({...search, customerName: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"保险公司"}>
                                        <Input
                                            value={search.insuranceName}
                                            readOnly
                                            placeholder="请选择保险公司"
                                            suffix={
                                                search.insuranceId ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setSearch({...search, insuranceName: "", insuranceId: ""})
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupPartnerVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupPartnerVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"联系电话"}>
                                        <Input value={search.phone} placeholder={"请输入送修人电话"} onChange={e => {
                                            setSearch({...search, phone: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"业务类别"}>
                                        <Select value={search.businessKindIds}
                                                mode="multiple"
                                                onChange={value => {
                                                    setSearch({...search, businessKindIds: value})
                                                }}>
                                            {
                                                businessKinds.map(item => {
                                                    return (
                                                        <Option key={item.id} value={item.id}>{item.name}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"付费类型"}>
                                        <Select value={search.chargeType} onChange={value => {
                                            setSearch({...search, chargeType: value})
                                        }}>
                                            <Option key={""} value={""}>请选择</Option>
                                            <Option value={CHARGE_TYPE_CUSTOMER} key={CHARGE_TYPE_CUSTOMER}>
                                                {chargeTypeAlias[CHARGE_TYPE_CUSTOMER]}
                                            </Option>
                                            <Option value={CHARGE_TYPE_INSURE} key={CHARGE_TYPE_INSURE}>
                                                {chargeTypeAlias[CHARGE_TYPE_INSURE]}
                                            </Option>
                                            <Option value={CHARGE_TYPE_FACTORY} key={CHARGE_TYPE_FACTORY}>
                                                {chargeTypeAlias[CHARGE_TYPE_FACTORY]}
                                            </Option>
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"车牌号码"}>
                                        <Input placeholder={"请输入车牌号码"} value={search.plate}
                                               onChange={e => {
                                                   setSearch({...search, plate: e.target.value.trim()})
                                               }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"车架号码"}>
                                        <Input placeholder={"请输入车架号码或者备用VIN"} value={search.bothVin}
                                               onChange={e => {
                                                   setSearch({...search, bothVin: e.target.value.trim()})
                                               }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"购车日期"}>
                                        <RangePicker
                                            value={[
                                                search.buyDateStart ? moment(search.buyDateStart) : null,
                                                search.buyDateEnd ? moment(search.buyDateEnd) : null
                                            ]}
                                            onChange={(val) => {
                                                //val不为null,设置搜索条件
                                                val ? setSearch({
                                                    ...search,
                                                    buyDateStart: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : null,
                                                    buyDateEnd: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : null,
                                                }) : setSearch({
                                                    ...search,
                                                    buyDateStart: "",
                                                    buyDateEnd: "",
                                                })
                                            }}/>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24} hidden={!isTenant}>
                                    <FormItem label={"经营主体"}>
                                        <Input
                                            value={search.companyNameList}
                                            readOnly
                                            autoComplete="off"
                                            placeholder="请选择经营主体"
                                            suffix={
                                                search.companyNameList ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setSearch({
                                                                ...search,
                                                                companyNameList: "",
                                                                companyIdList: []
                                                            })
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupCompanyVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupCompanyVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"经营品牌"}>
                                        <Input
                                            value={search.brandName}
                                            readOnly
                                            autoComplete="off"
                                            placeholder="请选择经营品牌"
                                            suffix={
                                                search.brandName ?
                                                    <CloseOutlined onClick={() => {
                                                        setSearch({...search, brandName: "", brandId: ""})
                                                    }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupBrandVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupBrandVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"业务单号"}>
                                        <Input value={search.repairCode} placeholder={"请输入业务单号"}
                                               onChange={e => {
                                                   setSearch({...search, repairCode: e.target.value.trim()})
                                               }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"服务顾问"}>
                                        <Input
                                            value={search.employeeName}
                                            readOnly
                                            autoComplete="off"
                                            placeholder="请选择服务顾问"
                                            suffix={
                                                search.employeeName ?
                                                    <CloseOutlined onClick={() => {
                                                        setSearch({...search, employeeId: "", employeeName: ""})
                                                    }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupEmployeeVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupEmployeeVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"工单备注"}>
                                        <Input placeholder={"请输入工单备注"} value={search.spec}
                                               onChange={e => {
                                                   setSearch({...search, spec: e.target.value.trim()})
                                               }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <div className={'search-btns'}>
                                        <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                                            setPagination({...pagination, page: 1})
                                            setQuery(search)
                                        }}>搜索</Button>
                                        <Button icon={<ReloadOutlined/>} onClick={() => {
                                            setPagination({...pagination, page: 1})
                                            setSearch({...initialSearch})
                                            setQuery({...initialSearch})
                                        }}>清空</Button>
                                        {/*搜索方案保存*/}
                                        <SearchPlanButton initSearch={initialSearch} url={url} search={search}
                                                          setSearch={setSearch}/>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Tabs
                activeKey={tabKey}
                onChange={key => {
                    setTabKey(key)
                }}>
                <TabPane tab="按业务类别汇总" key={GROUP_TYPE_BUSINESS_KIND}>
                    <Table
                        columns={reportColumns}
                        pagination={false}
                        scroll={{x: "100%"}}
                        rowKey={record => record.typeId}
                        dataSource={counts}
                        // bordered
                        summary={(pageData) => {
                            let repairNum = 0
                            let vehicleNum = 0
                            let customerNum = 0
                            let projectProfit = "0.00"
                            let productProfit = "0.00"
                            let profit = "0.00"
                            let total = "0.00"
                            let projectActualPayPrice = "0.00"
                            let productActualPayPrice = "0.00"
                            let actualPayPrice = "0.00"
                            let productProfitRate = "0.00"
                            let projectCost = "0.00"
                            let productCost = "0.00"
                            let cost = "0.00"
                            let profitRate = "0.00"

                            for (let i = 0; i < pageData.length; i++) {
                                repairNum += pageData[i].repairNum
                                vehicleNum += pageData[i].vehicleNum
                                customerNum += pageData[i].customerNum
                                projectProfit = common.numberHalfUp(new BigNumber(projectProfit).plus(pageData[i].projectProfit).toString(), 2)
                                productProfit = common.numberHalfUp(new BigNumber(productProfit).plus(pageData[i].productProfit).toString(), 2)
                                profit = common.numberHalfUp(new BigNumber(profit).plus(pageData[i].profit).toString(), 2)
                                total = common.numberHalfUp(new BigNumber(total).plus(pageData[i].total).toString(), 2)

                                projectActualPayPrice = common.numberHalfUp(new BigNumber(projectActualPayPrice).plus(pageData[i].projectActualPayPrice).toString(), 2)
                                productActualPayPrice = common.numberHalfUp(new BigNumber(productActualPayPrice).plus(pageData[i].productActualPayPrice).toString(), 2)
                                actualPayPrice = common.numberHalfUp(new BigNumber(actualPayPrice).plus(pageData[i].actualPayPrice).toString(), 2)

                                projectCost = common.numberHalfUp(new BigNumber(projectCost).plus(pageData[i].projectCost).toString(), 2)
                                productCost = common.numberHalfUp(new BigNumber(productCost).plus(pageData[i].productCost).toString(), 2)
                                cost = common.numberHalfUp(new BigNumber(cost).plus(pageData[i].productCost).toString(), 2)
                            }
                            if (total !== "0.00") {
                                profitRate = common.numberHalfUp(new BigNumber(profit).multipliedBy(100).dividedBy(actualPayPrice).toString(), 2)
                            }

                            // 材料毛利率 = 材料毛利/材料优惠后
                            if (productActualPayPrice !== "0.00") {
                                productProfitRate = common.numberHalfUp(new BigNumber(productProfit)
                                    .multipliedBy(100)
                                    .dividedBy(productActualPayPrice).toString(), 2)
                            }

                            return (
                                <>
                                    <tr>
                                        <th>合计</th>
                                        <td style={{textAlign: "right"}}>
                                            {customerNum}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {repairNum}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {vehicleNum}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(projectActualPayPrice)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(projectCost)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(projectProfit)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(productActualPayPrice)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(productCost)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(productProfit)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(productProfitRate) + "%"}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(actualPayPrice)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(cost)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(profit)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(profitRate) + "%"}
                                        </td>
                                    </tr>
                                </>
                            )
                        }}
                    />
                </TabPane>
                <TabPane tab="按付费类型汇总" key={GROUP_TYPE_CHARGE_TYPE}>
                    <Table dataSource={counts}
                           pagination={false}
                           columns={reportColumns}
                           scroll={{x: "100%"}}
                           rowKey={record => record.typeId}
                           summary={(pageData) => {
                               let repairNum = 0
                               let vehicleNum = 0
                               let customerNum = 0
                               let projectProfit = "0.00"
                               let productProfit = "0.00"
                               let productProfitRate = "0.00"
                               let profit = "0.00"
                               let total = "0.00"
                               let projectActualPayPrice = "0.00"
                               let productActualPayPrice = "0.00"
                               let actualPayPrice = "0.00"

                               let projectCost = "0.00"
                               let productCost = "0.00"
                               let cost = "0.00"
                               let profitRate = "0.00"

                               for (let i = 0; i < pageData.length; i++) {
                                   repairNum += pageData[i].repairNum
                                   vehicleNum += pageData[i].vehicleNum
                                   customerNum += pageData[i].customerNum
                                   projectProfit = common.numberHalfUp(new BigNumber(projectProfit).plus(pageData[i].projectProfit).toString(), 2)
                                   productProfit = common.numberHalfUp(new BigNumber(productProfit).plus(pageData[i].productProfit).toString(), 2)
                                   profit = common.numberHalfUp(new BigNumber(profit).plus(pageData[i].profit).toString(), 2)
                                   total = common.numberHalfUp(new BigNumber(total).plus(pageData[i].total).toString(), 2)

                                   projectActualPayPrice = common.numberHalfUp(new BigNumber(projectActualPayPrice).plus(pageData[i].projectActualPayPrice).toString(), 2)
                                   productActualPayPrice = common.numberHalfUp(new BigNumber(productActualPayPrice).plus(pageData[i].productActualPayPrice).toString(), 2)
                                   actualPayPrice = common.numberHalfUp(new BigNumber(actualPayPrice).plus(pageData[i].actualPayPrice).toString(), 2)

                                   projectCost = common.numberHalfUp(new BigNumber(projectCost).plus(pageData[i].projectCost).toString(), 2)
                                   productCost = common.numberHalfUp(new BigNumber(productCost).plus(pageData[i].productCost).toString(), 2)
                                   cost = common.numberHalfUp(new BigNumber(cost).plus(pageData[i].productCost).toString(), 2)
                               }
                               if (actualPayPrice !== "0.00") {
                                   profitRate = common.numberHalfUp(new BigNumber(profit).multipliedBy(100).dividedBy(actualPayPrice).toString(), 2)
                               }
                               // 材料毛利率 = 材料毛利/材料优惠后
                               if (productActualPayPrice !== "0.00") {
                                   productProfitRate = common.numberHalfUp(new BigNumber(productProfit)
                                       .multipliedBy(100)
                                       .dividedBy(productActualPayPrice).toString(), 2)
                               }

                               return (
                                   <>
                                       <tr>
                                           <th>合计</th>
                                           <td style={{textAlign: "right"}}>
                                               {customerNum}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {repairNum}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {vehicleNum}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(projectActualPayPrice)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(projectCost)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(projectProfit)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(productActualPayPrice)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(productCost)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(productProfit)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(productProfitRate) + "%"}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(actualPayPrice)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(cost)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(profit)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(profitRate) + "%"}
                                           </td>
                                       </tr>
                                   </>
                               )
                           }}
                    >
                    </Table>
                </TabPane>
                <TabPane tab="按工单类型汇总" key={GROUP_TYPE_CATEGORY}>
                    <Table
                        columns={reportColumns}
                        pagination={false}
                        scroll={{x: "100%"}}
                        rowKey={record => record.typeId}
                        dataSource={counts}
                        summary={(pageData) => {
                            let repairNum = 0
                            let vehicleNum = 0
                            let customerNum = 0
                            let projectProfit = "0.00"
                            let productProfit = "0.00"
                            let productProfitRate = "0.00"
                            let profit = "0.00"
                            let total = "0.00"
                            let projectActualPayPrice = "0.00"
                            let productActualPayPrice = "0.00"
                            let actualPayPrice = "0.00"

                            let projectCost = "0.00"
                            let productCost = "0.00"
                            let cost = "0.00"
                            let profitRate = "0.00"

                            for (let i = 0; i < pageData.length; i++) {
                                repairNum += pageData[i].repairNum
                                vehicleNum += pageData[i].vehicleNum
                                customerNum += pageData[i].customerNum
                                projectProfit = common.numberHalfUp(new BigNumber(projectProfit).plus(pageData[i].projectProfit).toString(), 2)
                                productProfit = common.numberHalfUp(new BigNumber(productProfit).plus(pageData[i].productProfit).toString(), 2)
                                profit = common.numberHalfUp(new BigNumber(profit).plus(pageData[i].profit).toString(), 2)
                                total = common.numberHalfUp(new BigNumber(total).plus(pageData[i].total).toString(), 2)

                                projectActualPayPrice = common.numberHalfUp(new BigNumber(projectActualPayPrice).plus(pageData[i].projectActualPayPrice).toString(), 2)
                                productActualPayPrice = common.numberHalfUp(new BigNumber(productActualPayPrice).plus(pageData[i].productActualPayPrice).toString(), 2)
                                actualPayPrice = common.numberHalfUp(new BigNumber(actualPayPrice).plus(pageData[i].actualPayPrice).toString(), 2)

                                projectCost = common.numberHalfUp(new BigNumber(projectCost).plus(pageData[i].projectCost).toString(), 2)
                                productCost = common.numberHalfUp(new BigNumber(productCost).plus(pageData[i].productCost).toString(), 2)
                                cost = common.numberHalfUp(new BigNumber(cost).plus(pageData[i].productCost).toString(), 2)
                            }
                            if (actualPayPrice !== "0.00") {
                                profitRate = common.numberHalfUp(new BigNumber(profit).multipliedBy(100).dividedBy(actualPayPrice).toString(), 2)
                            }
                            // 材料毛利率 = 材料毛利/材料优惠后
                            if (productActualPayPrice !== "0.00") {
                                productProfitRate = common.numberHalfUp(new BigNumber(productProfit)
                                    .multipliedBy(100)
                                    .dividedBy(productActualPayPrice).toString(), 2)
                            }

                            return (
                                <>
                                    <tr>
                                        <th>合计</th>
                                        <td style={{textAlign: "right"}}>
                                            {customerNum}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {repairNum}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {vehicleNum}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(projectActualPayPrice)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(projectCost)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(projectProfit)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(productActualPayPrice)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(productCost)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(productProfit)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(productProfitRate) + "%"}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(actualPayPrice)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(cost)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(profit)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(profitRate) + "%"}
                                        </td>
                                    </tr>
                                </>
                            )
                        }}
                    />
                </TabPane>
                <TabPane tab="按车系汇总" key={GROUP_TYPE_SERIES}>
                    <Table
                        columns={reportColumns}
                        pagination={false}
                        scroll={{x: "100%"}}
                        rowKey={record => record.typeId}
                        dataSource={counts}
                        // bordered
                        summary={(pageData) => {
                            let repairNum = 0
                            let vehicleNum = 0
                            let customerNum = 0
                            let projectProfit = "0.00"
                            let productProfit = "0.00"
                            let profit = "0.00"
                            let total = "0.00"
                            let projectActualPayPrice = "0.00"
                            let productActualPayPrice = "0.00"
                            let actualPayPrice = "0.00"
                            let productProfitRate = "0.00"

                            let projectCost = "0.00"
                            let productCost = "0.00"
                            let cost = "0.00"
                            let profitRate = "0.00"

                            for (let i = 0; i < pageData.length; i++) {
                                repairNum += pageData[i].repairNum
                                vehicleNum += pageData[i].vehicleNum
                                customerNum += pageData[i].customerNum
                                projectProfit = common.numberHalfUp(new BigNumber(projectProfit).plus(pageData[i].projectProfit).toString(), 2)
                                productProfit = common.numberHalfUp(new BigNumber(productProfit).plus(pageData[i].productProfit).toString(), 2)
                                profit = common.numberHalfUp(new BigNumber(profit).plus(pageData[i].profit).toString(), 2)
                                total = common.numberHalfUp(new BigNumber(total).plus(pageData[i].total).toString(), 2)

                                projectActualPayPrice = common.numberHalfUp(new BigNumber(projectActualPayPrice).plus(pageData[i].projectActualPayPrice).toString(), 2)
                                productActualPayPrice = common.numberHalfUp(new BigNumber(productActualPayPrice).plus(pageData[i].productActualPayPrice).toString(), 2)
                                actualPayPrice = common.numberHalfUp(new BigNumber(actualPayPrice).plus(pageData[i].actualPayPrice).toString(), 2)

                                projectCost = common.numberHalfUp(new BigNumber(projectCost).plus(pageData[i].projectCost).toString(), 2)
                                productCost = common.numberHalfUp(new BigNumber(productCost).plus(pageData[i].productCost).toString(), 2)
                                cost = common.numberHalfUp(new BigNumber(cost).plus(pageData[i].productCost).toString(), 2)
                            }
                            if (actualPayPrice !== "0.00") {
                                profitRate = common.numberHalfUp(new BigNumber(profit).multipliedBy(100).dividedBy(actualPayPrice).toString(), 2)
                            }

                            // 材料毛利率 = 材料毛利/材料优惠后
                            if (productActualPayPrice !== "0.00") {
                                productProfitRate = common.numberHalfUp(new BigNumber(productProfit)
                                    .multipliedBy(100)
                                    .dividedBy(productActualPayPrice).toString(), 2)
                            }

                            return (
                                <>
                                    <tr>
                                        <th>合计</th>
                                        <td style={{textAlign: "right"}}>
                                            {customerNum}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {repairNum}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {vehicleNum}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(projectActualPayPrice)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(projectCost)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(projectProfit)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(productActualPayPrice)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(productCost)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(productProfit)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(productProfitRate) + " %"}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(actualPayPrice)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(cost)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(profit)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(profitRate) + "%"}
                                        </td>
                                    </tr>
                                </>
                            )
                        }}
                    />
                </TabPane>
                <TabPane tab="按保险公司汇总" key={GROUP_TYPE_INSURANCE}>
                    <Table dataSource={counts}
                           pagination={false}
                           columns={reportColumns}
                           scroll={{x: "100%"}}
                           rowKey={record => record.typeId}
                        // bordered
                           summary={(pageData) => {
                               let repairNum = 0
                               let vehicleNum = 0
                               let customerNum = 0
                               let projectProfit = "0.00"
                               let productProfit = "0.00"
                               let profit = "0.00"
                               let total = "0.00"
                               let productProfitRate = "0.00"

                               let projectActualPayPrice = "0.00"
                               let productActualPayPrice = "0.00"
                               let actualPayPrice = "0.00"

                               let projectCost = "0.00"
                               let productCost = "0.00"
                               let cost = "0.00"
                               let profitRate = "0.00"

                               for (let i = 0; i < pageData.length; i++) {
                                   repairNum += pageData[i].repairNum
                                   vehicleNum += pageData[i].vehicleNum
                                   customerNum += pageData[i].customerNum
                                   projectProfit = common.numberHalfUp(new BigNumber(projectProfit).plus(pageData[i].projectProfit).toString(), 2)
                                   productProfit = common.numberHalfUp(new BigNumber(productProfit).plus(pageData[i].productProfit).toString(), 2)
                                   profit = common.numberHalfUp(new BigNumber(profit).plus(pageData[i].profit).toString(), 2)
                                   total = common.numberHalfUp(new BigNumber(total).plus(pageData[i].total).toString(), 2)

                                   projectActualPayPrice = common.numberHalfUp(new BigNumber(projectActualPayPrice).plus(pageData[i].projectActualPayPrice).toString(), 2)
                                   productActualPayPrice = common.numberHalfUp(new BigNumber(productActualPayPrice).plus(pageData[i].productActualPayPrice).toString(), 2)
                                   actualPayPrice = common.numberHalfUp(new BigNumber(actualPayPrice).plus(pageData[i].actualPayPrice).toString(), 2)

                                   projectCost = common.numberHalfUp(new BigNumber(projectCost).plus(pageData[i].projectCost).toString(), 2)
                                   productCost = common.numberHalfUp(new BigNumber(productCost).plus(pageData[i].productCost).toString(), 2)
                                   cost = common.numberHalfUp(new BigNumber(cost).plus(pageData[i].cost).toString(), 2)
                               }
                               if (actualPayPrice !== "0.00") {
                                   profitRate = common.numberHalfUp(new BigNumber(profit).multipliedBy(100).dividedBy(actualPayPrice).toString(), 2)
                               }
                               // 材料毛利率 = 材料毛利/材料优惠后
                               if (productActualPayPrice !== "0.00") {
                                   productProfitRate = common.numberHalfUp(new BigNumber(productProfit)
                                       .multipliedBy(100)
                                       .dividedBy(productActualPayPrice).toString(), 2)
                               }

                               return (
                                   <>
                                       <tr>
                                           <th>合计</th>
                                           <td style={{textAlign: "right"}}>
                                               {customerNum}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {repairNum}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {vehicleNum}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(projectActualPayPrice)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(projectCost)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(projectProfit)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(productActualPayPrice)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(productCost)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(productProfit)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(productProfitRate) + "%"}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(actualPayPrice)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(cost)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(profit)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(profitRate) + "%"}
                                           </td>
                                       </tr>
                                   </>
                               )
                           }}
                    >
                    </Table>
                </TabPane>
                <TabPane tab="按服务顾问汇总" key={GROUP_TYPE_EMPLOYEE}>
                    <Table dataSource={counts}
                           pagination={false}
                           scroll={{x: "100%"}}
                           columns={statisticsByEmployeeColumns}
                           rowKey={record => record.typeId}
                           summary={(pageData) => {
                               let repairNum = 0
                               let vehicleNum = 0
                               let customerNum = 0
                               let projectProfit = "0.00"
                               let projectActualPayPrice = "0.00"
                               let projectCost = "0.00"
                               let productActualPayPrice = "0.00"
                               let productProfit = "0.00"
                               let productProfitRate = "0.00"
                               let productCost = "0.00"
                               let profit = "0.00"
                               let cost = "0.00"
                               let profitRate = "0.00"
                               let actualPayPrice = "0.00"
                               let customerActualPayPrice = "0.00"
                               let customerCost = "0.00"
                               let customerProjectProfit = "0.00"
                               let customerProductProfit = "0.00"
                               let customerProfit = "0.00"

                               // 其中:保险事故
                               let insuranceActualPayPrice = "0.00"
                               let insuranceCost = "0.00"
                               let insuranceProjectProfit = "0.00"
                               let insuranceProductProfit = "0.00"
                               let insuranceProfit = "0.00"

                               // 其中:厂家索赔
                               let factoryProfit = "0.00"
                               let factoryCost = "0.00"
                               let factoryActualPayPrice = "0.00"
                               let factoryProjectProfit = "0.00"
                               let factoryProductProfit = "0.00"
                               for (let i = 0; i < pageData.length; i++) {
                                   repairNum += pageData[i].repairNum
                                   vehicleNum += pageData[i].vehicleNum
                                   customerNum += pageData[i].customerNum
                                   projectActualPayPrice = common.numberHalfUp(new BigNumber(projectActualPayPrice).plus(pageData[i].projectActualPayPrice).toString(), 2)
                                   projectProfit = common.numberHalfUp(new BigNumber(projectProfit).plus(pageData[i].projectProfit).toString(), 2)
                                   projectCost = common.numberHalfUp(new BigNumber(projectCost).plus(pageData[i].projectCost).toString(), 2)

                                   productActualPayPrice = common.numberHalfUp(new BigNumber(productActualPayPrice).plus(pageData[i].productActualPayPrice).toString(), 2)
                                   productProfit = common.numberHalfUp(new BigNumber(productProfit).plus(pageData[i].productProfit).toString(), 2)
                                   productCost = common.numberHalfUp(new BigNumber(productCost).plus(pageData[i].productCost).toString(), 2)
                                   actualPayPrice = common.numberHalfUp(new BigNumber(actualPayPrice).plus(pageData[i].actualPayPrice).toString(), 2)
                                   cost = common.numberHalfUp(new BigNumber(cost).plus(pageData[i].cost).toString(), 2)

                                   profit = common.numberHalfUp(new BigNumber(profit).plus(pageData[i].profit).toString(), 2)

                                   // 其中:客户自费
                                   customerActualPayPrice = common.numberHalfUp(new BigNumber(customerActualPayPrice).plus(pageData[i].customerActualPayPrice).toString(), 2)
                                   customerCost = common.numberHalfUp(new BigNumber(customerCost).plus(pageData[i].customerCost).toString(), 2)
                                   customerProjectProfit = common.numberHalfUp(new BigNumber(customerProjectProfit).plus(pageData[i].customerProjectProfit).toString(), 2)
                                   customerProductProfit = common.numberHalfUp(new BigNumber(customerProductProfit).plus(pageData[i].customerProductProfit).toString(), 2)
                                   customerProfit = common.numberHalfUp(new BigNumber(customerProfit).plus(pageData[i].customerProfit).toString(), 2)

                                   // 其中:保险事故
                                   insuranceCost = common.numberHalfUp(new BigNumber(insuranceCost).plus(pageData[i].insuranceCost).toString(), 2)
                                   insuranceActualPayPrice = common.numberHalfUp(new BigNumber(insuranceActualPayPrice).plus(pageData[i].insuranceActualPayPrice).toString(), 2)
                                   insuranceProjectProfit = common.numberHalfUp(new BigNumber(insuranceProjectProfit).plus(pageData[i].insuranceProjectProfit).toString(), 2)
                                   insuranceProductProfit = common.numberHalfUp(new BigNumber(insuranceProductProfit).plus(pageData[i].insuranceProductProfit).toString(), 2)
                                   insuranceProfit = common.numberHalfUp(new BigNumber(insuranceProfit).plus(pageData[i].insuranceProfit).toString(), 2)

                                   // 其中:厂家索赔
                                   factoryActualPayPrice = common.numberHalfUp(new BigNumber(factoryActualPayPrice).plus(pageData[i].factoryActualPayPrice).toString(), 2)
                                   factoryCost = common.numberHalfUp(new BigNumber(factoryCost).plus(pageData[i].factoryCost).toString(), 2)
                                   factoryProfit = common.numberHalfUp(new BigNumber(factoryProfit).plus(pageData[i].factoryProfit).toString(), 2)
                                   factoryProjectProfit = common.numberHalfUp(new BigNumber(factoryProjectProfit).plus(pageData[i].factoryProjectProfit).toString(), 2)
                                   factoryProductProfit = common.numberHalfUp(new BigNumber(factoryProductProfit).plus(pageData[i].factoryProductProfit).toString(), 2)
                               }

                               // 材料毛利率 = 材料毛利/材料优惠后
                               if (productActualPayPrice !== "0.00") {
                                   productProfitRate = common.numberHalfUp(new BigNumber(productProfit)
                                       .multipliedBy(100)
                                       .dividedBy(productActualPayPrice).toString(), 2)
                               }

                               // 毛利率
                               if (actualPayPrice !== "0.00") {
                                   profitRate = common.numberHalfUp(new BigNumber(profit)
                                       .multipliedBy(100)
                                       .dividedBy(actualPayPrice).toString(), 2)
                               }

                               return (
                                   <>
                                       <tr>
                                           <th>合计</th>
                                           <td style={{textAlign: "right"}}>
                                               {customerNum}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {repairNum}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {vehicleNum}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(projectActualPayPrice)}
                                           </td>

                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(projectCost)}
                                           </td>

                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(projectProfit)}
                                           </td>

                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(productActualPayPrice)}
                                           </td>

                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(productCost)}
                                           </td>

                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(productProfit)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(productProfitRate) + "%"}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(actualPayPrice)}
                                           </td>

                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(cost)}
                                           </td>

                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(profit)}
                                           </td>

                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(profitRate) + " %"}
                                           </td>

                                            {/*其中:客户自费*/}
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(customerActualPayPrice)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(customerCost)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(customerProjectProfit)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(customerProductProfit)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(customerProfit)}
                                           </td>

                                           {/*其中:保险事故*/}
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(insuranceActualPayPrice)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(insuranceCost)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(insuranceProjectProfit)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(insuranceProductProfit)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(insuranceProfit)}
                                           </td>

                                           {/*其中:厂家索赔*/}
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(factoryActualPayPrice)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(factoryCost)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(factoryProjectProfit)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(factoryProductProfit)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(factoryProfit)}
                                           </td>
                                       </tr>
                                   </>
                               )
                           }}
                    >
                    </Table>
                </TabPane>
                {isTenant ? <TabPane tab="按公司汇总" key={GROUP_TYPE_COMPANY}>
                    <Table
                        columns={reportColumns}
                        pagination={false}
                        scroll={{x: "100%"}}
                        rowKey={record => record.typeId}
                        dataSource={counts}
                        // bordered
                        summary={(pageData) => {
                            let customerNum = 0
                            let repairNum = 0
                            let vehicleNum = 0
                            let projectProfit = "0.00"
                            let productProfit = "0.00"
                            let profit = "0.00"
                            let profitRate = "0.00"
                            let productProfitRate = "0.00"

                            let projectActualPayPrice = "0.00"
                            let productActualPayPrice = "0.00"
                            let actualPayPrice = "0.00"

                            let projectCost = "0.00"
                            let productCost = "0.00"
                            let cost = "0.00"
                            let total = "0.00"

                            for (let i = 0; i < pageData.length; i++) {
                                repairNum += pageData[i].repairNum
                                vehicleNum += pageData[i].vehicleNum
                                customerNum += pageData[i].customerNum
                                projectProfit = common.numberHalfUp(new BigNumber(projectProfit).plus(pageData[i].projectProfit).toString(), 2)
                                productProfit = common.numberHalfUp(new BigNumber(productProfit).plus(pageData[i].productProfit).toString(), 2)
                                profit = common.numberHalfUp(new BigNumber(profit).plus(pageData[i].profit).toString(), 2)
                                total = common.numberHalfUp(new BigNumber(total).plus(pageData[i].total).toString(), 2)

                                projectActualPayPrice = common.numberHalfUp(new BigNumber(projectActualPayPrice).plus(pageData[i].projectActualPayPrice).toString(), 2)
                                productActualPayPrice = common.numberHalfUp(new BigNumber(productActualPayPrice).plus(pageData[i].productActualPayPrice).toString(), 2)
                                actualPayPrice = common.numberHalfUp(new BigNumber(actualPayPrice).plus(pageData[i].actualPayPrice).toString(), 2)

                                projectCost = common.numberHalfUp(new BigNumber(projectCost).plus(pageData[i].projectCost).toString(), 2)
                                productCost = common.numberHalfUp(new BigNumber(productCost).plus(pageData[i].productCost).toString(), 2)
                                cost = common.numberHalfUp(new BigNumber(cost).plus(pageData[i].cost).toString(), 2)
                            }

                            // 材料毛利率 = 材料毛利/材料优惠后
                            if (productActualPayPrice !== "0.00") {
                                productProfitRate = common.numberHalfUp(new BigNumber(productProfit)
                                    .multipliedBy(100)
                                    .dividedBy(productActualPayPrice).toString(), 2)
                            }


                            if (actualPayPrice !== "0.00") {
                                profitRate = common.numberHalfUp(new BigNumber(profit).multipliedBy(100).dividedBy(actualPayPrice).toString(), 2)
                            }

                            return (
                                <>
                                    <tr>
                                        <th>合计</th>
                                        <td style={{textAlign: "right"}}>
                                            {customerNum}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {repairNum}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {vehicleNum}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(projectActualPayPrice)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(projectCost)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(projectProfit)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(productActualPayPrice)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(productCost)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(productProfit)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(productProfitRate) + "%"}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(actualPayPrice)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(cost)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(profit)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(profitRate) + "%"}
                                        </td>
                                    </tr>
                                </>
                            )
                        }}
                    />
                </TabPane> : null}
                <TabPane tab="维修毛利明细" key={GROUP_TYPE_ITEM}>
                    <Table
                        rowKey={record => record.repairId}
                        columns={columns}
                        pagination={false}
                        scroll={{x: "100%"}}
                        expandable={{expandedRowRender}}
                        dataSource={profitList}
                        bordered
                    />
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "flex-end"}}>
                        <div>
                            <div>
                                <span>总产值：{common.numberFormat(summaries.total)}</span>
                                <Divider type="vertical"/>
                                <span>台次：{summaries.repairNum}</span>
                                <Divider type="vertical"/>
                                {/* 客单价 = 总产值/台次 */}
                                <span>客单价：{common.numberFormat(summaries.customerAvgTotal)}</span>
                            </div>

                            <div>
                                <span>净产值：{common.numberFormat(summaries.price)}</span>
                                <Divider type="vertical"/>
                                <span>台次：{summaries.repairNum}</span>
                                <Divider type="vertical"/>
                                {/* 客单价 = 净产值/台次 */}
                                <span>客单价：{common.numberFormat(summaries.customerAvgPrice)}</span>
                            </div>

                            <div>
                                <span>总毛利：{common.numberFormat(summaries.profit)}</span>
                                <Divider type="vertical"/>
                                <span>台次：{summaries.repairNum}</span>
                                <Divider type="vertical"/>
                                {/* 单台毛利 = 总毛利/台次 */}
                                <span>客单价：{common.numberFormat(summaries.customerAvgProfit)}</span>
                                <Divider type="vertical"/>
                                {/*毛利率*/}
                                <span>毛利率 <Tooltip title="毛利率 = 总毛利 / 净产值">
                                                <QuestionCircleOutlined/>
                                            </Tooltip> ：{common.numberFormat(summaries.profitRate)}%</span>
                            </div>
                        </div>
                        <PageBottom className={"page-bottom-pagination"}
                                    pagination={
                                        <Pagination
                                            pageSizeOptions={['10', '20', '40', '80']}
                                            onChange={handlePageChange}
                                            onShowSizeChange={handlePageChange}
                                            showQuickJumper={true}
                                            showTotal={total => `共${total}条`}
                                            total={total}
                                            defaultCurrent={pagination.page}
                                            current={pagination.page}
                                            showSizeChanger
                                            defaultPageSize={pagination.limit}
                                        />
                                    }
                        >
                        </PageBottom>
                    </div>
                </TabPane>
            </Tabs>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择品牌"
                    visible={lookupBrandVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupBrandVisible(false)
                    }}>
                    <LookupBrand
                        isMultiple={false}  //需要多选，则isMultiple=true
                        isCompany={true}    // false(默认) 集团可见品牌 true 公司可见品牌
                        onOk={(val) => {
                            setSearch({...search, brandId: val.id, brandName: val.name})
                            setLookupBrandVisible(false)
                        }}/>

                </Modal>
            </div>

            <div>
                <Modal title="选择车系"
                       destroyOnClose={true}
                       visible={lookupSeriesVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupSeriesVisible(false)
                       }}>
                    <LookupSeries
                        isMultiple={false} //需要多选,则isMultiple=true
                        onOk={(val) => {
                            setSearch({...search, seriesId: val.id, seriesName: val.name})
                            setLookupSeriesVisible(false)
                        }}/>
                </Modal>
            </div>

            <div>
                <Modal title="选择车型"
                       visible={lookupProductVisible}
                       width={1000}
                       footer={null}
                       destroyOnClose={true}
                       onCancel={() => {
                           setLookupProductVisible(false)
                       }}>
                    <LookupProduct isMultiple={false} onOk={(val) => {//需要多选，则isMultiple=true
                        setLookupProductVisible(false)
                        setSearch({...search, productId: val.id, productName: val.name})
                    }}/>
                </Modal>
            </div>

            <div>
                <Modal title="选择经营主体"
                       visible={lookupCompanyVisible}
                       width={1000}
                       footer={null}
                       destroyOnClose={true}
                       onCancel={() => {
                           setLookupCompanyVisible(false)
                       }}>
                    <LookupCompany type={'group'} isMultiple={true} onOk={(val) => {  //需要多选，则isMultiple=true
                        setLookupCompanyVisible(false)
                        setSearch({
                            ...search,
                            companyNameList: val.map((item) => item.nameShort).join(", "),
                            companyIdList: val.map((item) => item.id)
                        })
                    }}/>
                </Modal>
            </div>

            <div>
                <Modal title="选择服务顾问"
                       visible={lookupEmployeeVisible}
                       width={1000}
                       footer={null}
                       destroyOnClose={true}
                       onCancel={() => {
                           setLookupEmployeeVisible(false)
                       }}>
                    <LookupEmployee onOk={(value) => {  //需要多选，则isMultiple=true
                        setLookupEmployeeVisible(false)
                        setSearch({
                            ...search,
                            employeeId: value.id,
                            employeeName: value.name
                        })
                    }}/>
                </Modal>
            </div>

            {/*预结算详情*/}
            <Modal
                maskClosable={false}
                visible={prepayFormVisible}
                footer={null} width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setPrepayFormVisible(false)
                }}>
                <br/>
                <PrepayForm repairId={selectedRepairId} scene={"look"}/>
            </Modal>

            {/*保险公司*/}
            <Modal
                title={'选择保险公司'}
                visible={lookupPartnerVisible}
                footer={null} width={1000}
                destroyOnClose={true}
                onCancel={() => setLookupPartnerVisible(false)}>
                <LookupPartner isMultiple={false} dataSource={3} type={"保险公司"} onOk={value => {
                    setSearch({...search, insuranceId: value.id, insuranceName: value.name})
                    setLookupPartnerVisible(false)
                }}/>
            </Modal>


        </div>
    )
}

export default ReportProfit