import React from 'react'
import RepairQuickForm from "../../../../components/support/RepairForm/quick"

//页面来源（哪个页面在调这个组件）
// const PAGE_SOURCE_WORKSHOP = 1 //车间管理
const PAGE_SOURCE_REPAIR = 1    //维修开单

function RepairQuick(props) {
    return <RepairQuickForm scene={PAGE_SOURCE_REPAIR} id={''} repairId={props.match.params.id || ''} />
}

export default RepairQuick