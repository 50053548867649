import React, {useEffect, useState} from 'react';
import {
    CloseOutlined,
    PlusOutlined,
    PrinterOutlined,
    ReloadOutlined,
    SearchOutlined,
    SelectOutlined
} from '@ant-design/icons';
import {Button, Col, Input, Table, Pagination, DatePicker, Select, Row, Form, Modal, Divider} from "antd";
import SearchArea from "../../../../components/layout/SearchArea";
import PageTop from "../../../../components/layout/PageTop";
import PageBottom from "../../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import {Basic, Delivery, Product, Warehouse} from "../../../../components/wms/config";
import BigNumber from "bignumber.js";
import LookupProduct from "../../../../components/wms/LookupProductGoods";
import LookupEmployee from "../../../../components/passport/LookupEmployee";
import {Link} from "react-router-dom";
import LookupAll from "../../../../components/passport/LookupAll";
import Search from "../../../../utils/search";
import moment from "moment";

const {RangePicker} = DatePicker
const {Option} = Select

function DeliveryIndex(props) {
    // action 字符串 配件/精品 必填 part(配件) article(精品) commodity(商品 实际上就是精品 只是不显示成本价)
    let {action} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })
    // 列表数据
    let [list, setList] = useState([])
    let [subtotal, setSubtotal] = useState({})
    let [warehouse, setWarehouse] = useState([]) // 仓库
    let [visible2, setVisible2] = useState(false)
    let [visible, setVisible] = useState(false)
    let [modalTitle, setModalTitle] = useState("")
    let [modalNumber, setModalNumber] = useState(0)

    // 搜索条件
    let initialSearch = {
        createdAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        createdAtEnd: moment().format("YYYY-MM-DD") + " 23:59:59",
        deliveryCode: "",
        productNumber: "",
        deliveryEmployeeId: "",
        productId: "",
        warehouseId: "",

        exampleEmployeeName: "",
        exampleName: "",
        exampleSupplierName: "",
    }
    let [search, setSearch] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch)   //提交ajax搜索值
    let [rowKeys, setRowKeys] = useState([]);
    let [rows, setRows] = useState([]);// 打印的数据

    const columns = [
        {
            title: '出库时间',
            dataIndex: 'deliveryDate',
            width: 120,
            ellipsis: true,
            render: (text) => text === '小计' ? '小计' : text.substring(0, 10)
        },
        {
            title: '出库单号',
            dataIndex: ['deliveryDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: action === Product.KIND_PART ? '配件编号' : action === Product.KIND_ARTICLE ? '精品编号' : '商品编号',
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true
        },
        {
            title: action === Product.KIND_PART ? '配件名称' : action === Product.KIND_ARTICLE ? '精品名称' : '商品名称',
            dataIndex: ['productDto', 'name'],
            width: 200,
            ellipsis: true
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '出库单价',
            dataIndex: 'price',
            align: 'right',
            width: 150,
            ellipsis: true,
            render: (text) => text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '出库金额',
            dataIndex: 'total',
            align: 'right',
            width: 150,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '仓库',
            align: 'center',
            dataIndex: ['warehouseDto', 'name'],
            width: 150,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text) => text in Delivery.DeliveryItemStatusAlias ? Delivery.DeliveryItemStatusAlias[text] : text
        },
        {
            title: '出库类型',
            dataIndex: ['deliveryDto', 'type'],
            width: 100,
            ellipsis: true,
            render: (text) => text in Delivery.DeliveryTypeAlias ? Delivery.DeliveryTypeAlias[text] : text
        },
        {
            title: '领料人',
            dataIndex: 'employeeName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '供货商',
            dataIndex: 'supplierName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true
        },
        {
            title: '出库单备注',
            dataIndex: 'deliveryDto',
            width: 200,
            ellipsis: true,
            render: (text) => {
                if (text === undefined) {
                    return ''
                }
                if (text.type in Delivery.DeliveryTypeReturnAlias) {
                    return "原出库单号: " + text.spec
                } else {
                    return text.spec
                }
            }
        },
    ]

    // 获取仓库
    let getWarehouse = () => {
        common.loadingStart();
        common.ajax("get", "/wms/warehouse/list", {
            status: Warehouse.STATUS_ENABLE,//仓库状态为启用
            ownerId: common.getUser().company.id,
            type: Warehouse.TYPE_GOODS,
            isAll: Basic.IS_ANSWER_YES,
        }).then((data) => {
            setWarehouse(data.warehouses)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getWarehouse, [])

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/deliveryItem/list", {
            ...pagination,
            ...query,
            ownerId: common.getUser().company.id,
            kind: action === Product.KIND_PART ? Product.KIND_PART : Product.KIND_ARTICLE,
            isReceipt: Basic.IS_ANSWER_YES,
            type: Delivery.DELIVERY_TYPE_RECEIVE,
            brandId: common.getUser().brand.id
        }).then((data) => {
            setSubtotal(data.subtotal === null ? {} : data.subtotal)

            if (data.deliveryItems.length > 0) {
                let objMy = {
                    id: "-1",
                    total: 0,
                    quantity: 0,
                    deliveryDate: '小计',
                }
                let objMy2 = {
                    id: "-2",
                    total: data.subtotal.price,
                    quantity: data.subtotal.quantity,
                    deliveryDate: '合计',
                }
                data.deliveryItems.forEach((item) => {
                    item.total = new BigNumber(item.quantity).multipliedBy(item.price).toFixed(2)

                    // 小计
                    // 数量
                    objMy.quantity = new BigNumber(item.quantity).plus(objMy.quantity).toString()
                    // 出库金额
                    objMy.total = new BigNumber(item.total).plus(objMy.total).toString()
                })

                data.deliveryItems.push(objMy)
                data.deliveryItems.push(objMy2)
            }
            setList(data.deliveryItems)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 初始化页面
    useEffect(getData, [pagination, query])

    let rowSelection = {
        // columnTitle: ' ',
        hideSelectAll: true,
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows, action) => {
            let length = selectedRowKeys.length;
            if (action === undefined) {
                if (length === 0 || rowSelection.selectedRowKeys.indexOf(length > 1 ? selectedRowKeys[length - 1] : selectedRowKeys[0]) >= 0) {
                    rowSelection.selectedRowKeys = []
                    setRowKeys([])
                    setRows([])
                } else {
                    let arr = []
                    list.forEach((item) => {
                        if (item.deliveryId === selectedRows[length > 1 ? length - 1 : 0].deliveryId) {
                            arr.push(item)
                        }
                    })
                    rowSelection.selectedRowKeys = arr.map((item) => item.id)
                    setRowKeys(arr.map((item) => item.id))
                    setRows(arr)
                }
            } else {
                setRowKeys(selectedRowKeys)
                setRows(selectedRows)
            }
        },
        getCheckboxProps: record => ({
            disabled: record.id === "-1" || record.id === "-2"
        })
    }

    // 打印
    let print = () => {
        if (rows.length === 0) {
            common.alert("请选择一个出库单");
            return false
        }
        let total = 0;
        rows.forEach((item) => {
            // item.total = common.numberCut(item.total, 2)
            total = new BigNumber(item.total).plus(total).toNumber()
        })

        let data = {
            "title": "配件领用出库单",
            "companyName": common.getUser().company.name,
            "order": {
                "customerName": rows[0].customerName,
                "code": rows[0].deliveryDto.orderCode,
                "plate": rows[0].deliveryDto.plate,
            },
            "typeName": Delivery.DeliveryTypeAlias[rows[0].deliveryDto.type],
            "code": rows[0].deliveryDto.code,
            "total": common.numberCut(total, 2),
            "totalUpper": common.moneyToChinese(total),// 大写
            "createdAt": rows[0].deliveryDate.substring(0, 10),
            "spec": rows[0].deliveryDto.spec,
            "provider": rows[0].employeeName,
            "receiver": rows[0].employeeName,
            "salesman": common.getUser().nickname,
            "deliveryItems": rows
        }
        common.loadingStart()
        common.ajax("post", "/ext/print/template/parse", {
            "code": "wms_delivery",
            "owner_id": common.getUser().company.id,
            data: data
        }).then((res) => {
            common.print(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <>
            <PageTop
                title={action === Product.KIND_PART ? '配件领用出库' : action === Product.KIND_ARTICLE ? '精品领用出库' : '商品领用出库'}>
                <Button type="primary">
                    <Link
                        to={action === Product.KIND_PART ? '/wms/part/delivery/receive/create' : action === Product.KIND_ARTICLE ? '/wms/article/delivery/receive/create' : '/wms/commodity/delivery/receive/create'}>
                        <PlusOutlined/>
                        <span> 新增</span>
                    </Link>
                </Button>
                {action === Product.KIND_PART &&
                    <Button
                        disabled={rows.length === 0}
                        icon={<PrinterOutlined/>} onClick={print}>打印</Button>}
            </PageTop>
            <SearchArea>
                <Form name={'validate_other'} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="出库时间">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.createdAtStart ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd ? moment(search.createdAtEnd) : null
                                    ]}
                                    onChange={(dateTime, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="出库单号">
                                <Input value={search.deliveryCode} onChange={(e) => {
                                    setSearch({...search, deliveryCode: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="领料人">
                                <Input
                                    value={search.exampleEmployeeName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleEmployeeName === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({
                                                        ...search,
                                                        deliveryEmployeeId: '',
                                                        exampleEmployeeName: ''
                                                    })
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle("选择领料人")
                                                setModalNumber(1)
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalTitle("选择领料人")
                                        setModalNumber(1)
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="仓库">
                                <Select
                                    value={search.warehouseId}
                                    onChange={(val) => {
                                        setSearch({...search, warehouseId: val})
                                    }}>
                                    {
                                        warehouse.map((item) => {
                                            return <Option value={item.id} key={item.id}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                label={action === Product.KIND_PART ? "配件名称" : action === Product.KIND_ARTICLE ? "精品名称" : '商品名称'}>
                                <Input
                                    value={search.exampleName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleName === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, productId: '', exampleName: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setVisible2(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setVisible2(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={action === Product.KIND_PART ? "配件编号" : action === Product.KIND_ARTICLE ? "精品编号" : '商品编号'}>
                                <Input value={search.productNumber} onChange={(e) => {
                                    setSearch({...search, productNumber: e.target.value.trim()})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="供货商">
                                <Input
                                    value={search.exampleSupplierName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleSupplierName === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, supplierId: '', exampleSupplierName: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle("选择供货商")
                                                setModalNumber(2)
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalTitle("选择供货商")
                                        setModalNumber(2)
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={list}
                scroll={{x: '100%', y: '460px'}}
                rowSelection={rowSelection}
                onRow={record => {
                    return {
                        onClick: () => {
                            // 小计 不允许选择
                            if (record.id === '-1' || record.id === '-2') {
                                return false
                            }

                            if (rowSelection.selectedRowKeys.indexOf(record.id) >= 0) {
                                rowSelection.selectedRowKeys = []
                                rowSelection.onChange([], [], 1)
                            } else {
                                let arr = []
                                list.forEach((item) => {
                                    if (item.deliveryId === record.deliveryId) {
                                        arr.push(item)
                                    }
                                })
                                rowSelection.selectedRowKeys = arr.map((item) => item.id)
                                rowSelection.onChange(arr.map((item) => item.id), arr, 1)
                            }
                        }, // 点击行
                    };
                }}
            />
            <PageBottom
                children={
                    list.length > 0 &&
                    <div>
                        出库数量: {common.numberFormat(subtotal.quantity)}
                        <Divider type="vertical"/>
                        出库总金额: {common.numberFormat(subtotal.price)}
                        <Divider type="vertical"/>
                        去税金额: {common.numberFormat(subtotal.manufacturerRebate)}
                        <Divider type="vertical"/>
                        税金: {common.numberFormat(subtotal.priceTax)}
                    </div>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}/>
                }>
            </PageBottom>
            <Modal
                title={modalTitle}
                maskClosable={false}
                visible={visible}
                width={1000}
                footer={null}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}>
                {modalNumber === 1 &&
                    <LookupEmployee
                        companyId={common.getUser().company.id}
                        isMultiple={false}
                        onOk={(value) => {
                            setSearch({...search, deliveryEmployeeId: value.id, exampleEmployeeName: value.name})
                            setVisible(false)
                        }}
                    />}

                {modalNumber === 2 &&
                    <LookupAll
                        isCompany={true}
                        isPartner={true}
                        isMultiple={false}
                        onOk={(value) => {
                            setSearch({...search, supplierId: value.id, exampleSupplierName: value.name})
                            setVisible(false)
                        }}
                    />}
            </Modal>
            <Modal
                maskClosable={false}
                visible={visible2}
                title={action === Product.KIND_PART ? "选择配件" : action === Product.KIND_ARTICLE ? "选择精品" : "选择商品"}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible2(false)
                }}
                footer={null}
            >
                <LookupProduct
                    isHaltProduction={'all'}
                    action={'all'}
                    defaultValue={action === Product.KIND_PART ? Product.KIND_PART : Product.KIND_ARTICLE}
                    isMultiple={false}
                    onOk={(val) => {
                        setSearch({...search, productId: val.id, exampleName: val.name})
                        setVisible2(false)
                    }}/>
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryIndex)