import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {Button, Form, Row, Col, DatePicker, Input, Select, Table, Pagination, Modal, Space, Radio, Timeline} from 'antd'
import {
    PlusOutlined,
    SearchOutlined,
    ReloadOutlined,
    DownloadOutlined,
    SelectOutlined,
    CloseOutlined
} from '@ant-design/icons'
import PageTop from '../../../components/layout/PageTop'
import SearchArea from '../../../components/layout/SearchArea'
import PageBottom from '../../../components/layout/PageBottom'
import ApprovalProgress from './approvalProgress'
import common from "../../../utils/common"
import {Audit} from "../../../components/wms/config";
import {Coupon, Order} from "../../../components/coupon/config";
import LookupAll from "../../../components/passport/LookupAll";

function CouponAudit() {
    const [form] = Form.useForm()
    const [formAudit] = Form.useForm()
    const initParams = {
        exampleName: "",
        memberId: "",
    }
    const [searchParams, setSearchParams] = useState(initParams)
    const [couponOrderShow, setCouponOrderShow] = useState(false)
    let [modalTitle, setModalTitle] = useState('');
    let [modalNumber, setModalNumber] = useState(0);
    let [visible, setVisible] = useState(false);
    const [auditShow, setAuditShow] = useState(false)
    const [selectRecord, setSelectRecord] = useState(null)
    const [departmentList, setDepartmentList] = useState([])
    const [tableList, setTableList] = useState([])
    const [orderDetail, setOrderDetail] = useState([])
    const [orderDetailAuditList, setOrderDetailAuditList] = useState([])
    const [total, setTotal] = useState(0)
    const [pagination, setPagination] = useState({page: 1, limit: 15,})

    // 审核部门
    const initDepartmentData = () => {
        // 审核部门
        common.loadingStart();
        common.ajax('get', '/passport/company/findDepartments', {id: common.getUser().company.id}).then((data) => {
            setDepartmentList(data)
        }).finally(() => {
            common.loadingStop()
        })
    }
    // 列表数据
    const onGetListData = () => {
        let _params = {...searchParams, ...pagination}
        if (_params.ownerId === undefined || _params.ownerId === null || _params.ownerId === '') {
            _params.ownerId = common.getUser().company.id
        }
        common.loadingStart();
        common.ajax("get", "/coupon/order/auditPage", _params).then(data => {
            if (data && data.list) {
                setTableList(data.list)
            } else {
                setTableList([])
            }
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }
    // 获取订单详情
    const onGetOrderDetail = (record) => {
        let _params = {
            businessCode: record.businessCode,
            ownerId: common.getUser().company.id,
            limit: 500,
            page: 1
        }
        common.loadingStart();
        common.ajax("get", "/coupon/order/detail", _params).then(data => {
            if (data.length > 0) {
                data[0].creatorName = record.creatorName
                data[0].departmentName = record.departmentName
                data[0].meAudit = record.meAudit
                data[0].auditId = record.auditId
            }
            setOrderDetail(data)
            setCouponOrderShow(true)
        }).finally(() => {
            common.loadingStop()
        })
    }
    // 审批
    const audit = (params) => {
        if (orderDetail == null || orderDetail.length === 0) {
            return;
        }
        // 是否是本人审核 true:是本人审核 false:直接不审核
        if (!orderDetail[0].meAudit) {
            return;
        }
        common.consoleLog(params)
        let _params = {
            id: orderDetail[0].auditId,
            ...params
        }
        common.loadingStart();
        common.ajax("post", "/wms/audit/updateById", _params).then(data => {
            onGetListData()
            common.toast("操作成功")
            setAuditShow(false)
            setCouponOrderShow(false)
        }).finally(() => {
            common.loadingStop()
        })
    }
    // 获取订单 审批进度
    // const getOrderDetailAudit = (record) => {
    //     let _params = {
    //         businessIds: [record.businessCode],
    //         ownerId: common.getUser().company.id,
    //         type: Audit.TYPE_COUPON_SALE
    //     }
    //     common.loadingStart();
    //     common.ajax("get", "/wms/audit/findListByBusinessIds", _params).then(data => {
    //         setOrderDetailAuditList(data)
    //         setProgressShow(true)
    //     }).finally(() => {
    //         common.loadingStop()
    //     })
    // }
    useEffect(() => {
        onGetListData()
    }, [pagination])
    useEffect(() => {
        initDepartmentData()
    }, [])
    const onSearch = (vals) => {
        let _params = {...searchParams}
        // 合并默认参数和表单参数
        vals = {...searchParams, ...vals}
        if (vals.createdAtRange) {
            if (vals.createdAtRange[0]) {
                vals.createdAtStart = vals.createdAtRange[0].format('YYYY-MM-DD')
            }
            if (vals.createdAtRange[1]) {
                vals.createdAtEnd = vals.createdAtRange[1].format('YYYY-MM-DD')
            }
        }
        if (vals) {
            let _query = {}
            Object.keys(vals).map(key => {
                if (vals[key] !== undefined) {
                    _query[key] = vals[key]
                }
            })
            _params = {..._params, ..._query}
            setSearchParams(_params)
        }
        setPagination({
            ...pagination,
            page: 1
        })
    }
    return (
        <>
            <PageTop title="礼券审批"></PageTop>
            <SearchArea>
                <Form
                    className={"ant-advanced-search-form"}
                    form={form} onFinish={onSearch} initialValues={searchParams}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="订单编号" name="businessCode">
                                <Input autoComplete='off' type="text" placeholder={'输入销售单号'}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="客户姓名" name="memberName" className='label-character-3'>
                                <Input autoComplete="off"/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="业务部门" className='label-character-3' name="departmentIds">
                                <Select placeholder="选择业务部门">
                                    <Select.Option value="" key="">全部</Select.Option>
                                    {
                                        departmentList.map(c => {
                                            return <Select.Option value={c.id} key={c.id}>{c.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="创建日期" name="createdAtRange">
                                <DatePicker.RangePicker
                                    value={[searchParams.createdAtStart ? moment(searchParams.createdAtStart) : null, searchParams.createdAtEnd ? moment(searchParams.createdAtEnd) : null]}
                                />
                            </Form.Item>
                        </Col>
                        {/*<Col span={6}>*/}
                        {/*    <Form.Item label="预收金额" name="plate">*/}
                        {/*        <Input autoComplete='off' type="text" placeholder={'输入预收金额'}/>*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        <Col span={6}>
                            <Form.Item label="单据状态" name="approvalStatus">
                                <Select placeholder="选择单据状态">
                                    <Select.Option value={Order.APPROVAL_STATUS_ING}
                                                   key={Order.APPROVAL_STATUS_ING}>{Order.ApprovalStatusTypes[Order.APPROVAL_STATUS_ING]}</Select.Option>
                                    <Select.Option value={Order.APPROVAL_STATUS_PASS}
                                                   key={Order.APPROVAL_STATUS_PASS}>{Order.ApprovalStatusTypes[Order.APPROVAL_STATUS_PASS]}</Select.Option>
                                    <Select.Option value={Order.APPROVAL_STATUS_REFUSE}
                                                   key={Order.APPROVAL_STATUS_REFUSE}>{Order.ApprovalStatusTypes[Order.APPROVAL_STATUS_REFUSE]}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车牌号" name="plate" className='label-character-3'>
                                <Input autoComplete="off"/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="VIN" className='label-character-3' name="vin">
                                <Input autoComplete='off'/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    form.submit()
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    form.resetFields()
                                    setSearchParams(initParams)
                                    setTimeout(() => {
                                        form.resetFields()
                                        setSearchParams(initParams)
                                        form.submit()
                                    }, 30)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            {/* 数据表格 */}
            <Table
                rowKey={record => record.id}
                columns={[
                    // {
                    //     title: '序号',
                    //     key: 'index',
                    //     width: 50,
                    //     fixed: 'left',
                    //     render: (text, record, index) => `${record.code != '小计' ? index + 1 : ''}`,
                    // },
                    {
                        title: '订单编号',
                        fixed: 'left',
                        dataIndex: 'businessCode',
                        key: 'businessCode',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '业务部门',
                        dataIndex: 'departmentName',
                        key: 'departmentName',
                        ellipsis: true,
                        width: 100,
                    },
                    {
                        title: '客户',
                        dataIndex: 'memberName',
                        key: 'memberName',
                        ellipsis: true,
                        width: 100,
                    },
                    {
                        title: '联系电话',
                        dataIndex: 'memberMobile',
                        key: 'memberMobile',
                        ellipsis: true,
                        width: 100,
                    },
                    {
                        title: '车牌号',
                        dataIndex: 'plate',
                        key: 'plate',
                        ellipsis: true,
                        width: 100,
                    },
                    {
                        title: 'VIN',
                        dataIndex: 'vin',
                        key: 'vin',
                        ellipsis: true,
                        width: 160,
                    },
                    {
                        title: '车型',
                        dataIndex: 'productName',
                        key: 'productName',
                        ellipsis: true,
                        width: 160,
                    },
                    {
                        title: '方案名称',
                        dataIndex: 'planName',
                        key: 'planName',
                        ellipsis: true,
                        width: 100,
                    },
                    {
                        title: '数量/张',
                        align: 'right',
                        dataIndex: 'quantity',
                        key: 'quantity',
                        ellipsis: true,
                        width: 100,
                    },
                    {
                        title: '预收金额',
                        dataIndex: 'price',
                        key: 'price',
                        ellipsis: true,
                        width: 100,
                        align: 'right',
                        render: (text) => {
                            if (text === undefined) {
                                return ""
                            }
                            return common.numberFormat(text)
                        }
                    },
                    {
                        title: '最大抵扣金额',
                        dataIndex: 'couponName',
                        key: 'couponName',
                        ellipsis: true,
                        width: 100,
                        align: 'right',
                        render: (text) => {
                            if (text === undefined) {
                                return ""
                            }
                            return common.numberFormat(text)
                        }
                    },
                    {
                        title: '单据状态',
                        dataIndex: 'approvalStatus',
                        key: 'approvalStatus',
                        ellipsis: true,
                        width: 100,
                        render: (text) => {
                            if (text === undefined) {
                                return ""
                            }
                            return Order.ApprovalStatusTypes[text]
                        }
                    },
                    {
                        title: '创建人',
                        dataIndex: 'creatorName',
                        key: 'creatorName',
                        ellipsis: true,
                        width: 100,
                    },
                    {
                        title: '创建日期',
                        dataIndex: 'createdAt',
                        key: 'createdAt',
                        ellipsis: true,
                        width: 100,
                        render: text => {
                            return (
                                <span>{text === null ? "" : moment(text).format("YYYY-MM-DD")}</span>
                            )
                        }
                    },
                    {
                        title: '备注',
                        dataIndex: 'spec',
                        key: 'spec',
                        ellipsis: true,
                        width: 100,
                    },
                    {
                        title: '操作',
                        align: 'center',
                        dataIndex: 'action',
                        key: 'action',
                        ellipsis: true,
                        width: 100,
                        fixed: 'right',
                        render: (text, record, index) => {
                            return <div className={'action-btns'}>
                                <Button
                                    disabled={!record.meAudit}
                                    type='link'
                                    onClick={() => {
                                        if (record.meAudit) {
                                            onGetOrderDetail(record);
                                        }
                                    }}>审批</Button>
                                <Button
                                    type={'link'}
                                    onClick={() => {
                                        setSelectRecord(record)
                                    }}>进度</Button>
                            </div>
                            // return <Space><a
                            //     style={record.meAudit ? {} : {
                            //         'color': '#999', /* 灰色 */
                            //         'pointer-events': 'none', /* 禁用鼠标事件 */
                            //         'cursor': 'default', /* 将鼠标光标设置为默认 */
                            //     }}
                            //     onClick={() => {
                            //         // 是否是本人审核 true:是本人审批
                            //         if (record.meAudit) {
                            //             onGetOrderDetail(record);
                            //         }
                            //     }}>审批</a>
                            //     <a onClick={() => {
                            //     getOrderDetailAudit(record)
                            // }}>进度</a></Space>
                        }
                    }
                ]}
                dataSource={tableList}
                scroll={{x: '100%'}}
                pagination={false}
            />
            {/* 分页 */}
            <PageBottom pagination={
                <Pagination
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total}
                    current={pagination.page}
                    showSizeChanger
                    pageSizeOptions={['15', '30', '50', '100']}
                    defaultPageSize={pagination.limit}
                    onChange={(page, limit) => {
                        setPagination({page, limit})
                    }}
                    onShowSizeChange={(page, limit) => {
                        setPagination({page, limit})
                    }}
                />
            }>
            </PageBottom>
            {/* 弹框 */}
            <Modal title="礼券信息"
                   visible={couponOrderShow}
                   width={600}
                   footer={null}
                   onCancel={() => {
                       setCouponOrderShow(false)
                   }}>
                {couponOrderShow &&
                    <div>
                        <div style={{lineHeight: '24px'}}>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <div>{"客户：" + orderDetail[0].memberName + (orderDetail[0].memberMobile == null ? "" : "(" + orderDetail[0].memberMobile + ")")}</div>
                                    <div>{"车辆：" + (orderDetail[0].plate || "") + (orderDetail[0].vin == null ? "" : "(" + orderDetail[0].vin + ")")}</div>
                                    <div>{"车型：" + (orderDetail[0].productName || "")}</div>
                                </Col>
                                <Col span={12}>
                                    <div>{"订单编号：" + orderDetail[0].businessCode}</div>
                                    <div>{"发券：" + orderDetail[0].departmentName + " " + orderDetail[0].creatorName}</div>
                                    <div>{"创建：" + orderDetail[0].createdAt}</div>
                                </Col>
                            </Row>
                        </div>
                        <hr></hr>
                        <div style={{maxHeight: '300px', overflowY: "auto", lineHeight: '24px'}}>
                            {
                                orderDetail.map((item, index) => {
                                    return <div key={index} style={{
                                        border: '1px solid #f1f2f3',
                                        borderRadius: '4px',
                                        marginBottom: '4px',
                                        padding: '4px',
                                        color: '#333'
                                    }}>
                                        <div>
                                            <h4>礼券{index + 1}：{item.couponDto.name}</h4>
                                        </div>
                                        <div>
                                            编号：{item.couponDto.code}
                                        </div>
                                        <div>
                                            数量：{item.quantity}
                                        </div>
                                        {item.couponDto.validType === Coupon.VALID_TYPE_REGION &&
                                            <div>
                                                有效期区间：{item.couponDto.beginDate.substring(0, 10)} 至 {item.couponDto.endDate.substring(0, 10)}
                                            </div>}
                                        {item.couponDto.validType === Coupon.VALID_TYPE_LENGTH &&
                                            <>
                                                <div>
                                                    生效预备时长(天)：{item.couponDto.fixedBeginTerm}
                                                </div>
                                                <div>
                                                    有效时长(天)：{item.couponDto.fixedTerm}
                                                </div>
                                            </>
                                        }
                                        <div>
                                            使用说明：{item.couponDto.description}
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <hr></hr>
                        <Space><Button onClick={() => {
                            setCouponOrderShow(false)
                        }}>知道了</Button><Button type='primary' onClick={() => {
                            setAuditShow(true)
                            formAudit.setFieldsValue({
                                status: 1,
                                advice: '',
                                spec: '',
                                auditor: common.getUser().nickname,
                                auditedAt: moment().format('YYYY-MM-DD HH:mm:ss')
                            })
                        }}>审批</Button></Space>
                    </div>}
            </Modal>
            <Modal title={modalTitle}
                   visible={visible}
                   width={1000}
                   footer={null}
                   onCancel={() => {
                       setVisible(false)
                   }}>
                {modalNumber === 1 &&
                    <LookupAll
                        isVehicle={true}
                        isCustomer={true}
                        onOk={(value) => {
                            setSearchParams({...searchParams, memberId: value.id, exampleName: value.name})
                            setVisible(false)
                        }}/>}
            </Modal>
            <Modal title="订单审批"
                   visible={auditShow}
                   width={400}
                   footer={null}
                   onCancel={() => {
                       setAuditShow(false)
                   }}>
                <Form form={formAudit} onFinish={audit}>
                    <Form.Item className="label-character-4" label="审批结果" name="status"
                               rules={[{required: false, message: "请选择审核结果"}]}>
                        <Radio.Group>
                            <Radio value={Audit.STATUS_YES} key={Audit.STATUS_YES}>通过</Radio>
                            <Radio value={Audit.STATUS_NO} key={Audit.STATUS_NO}>不通过</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {/*<Form.Item className="label-character-4" label="审批意见" name="advice"*/}
                    {/*           rules={[{required: false, message: "请选择审批意见"}]}>*/}
                    {/*    <Select>*/}
                    {/*        <Select.Option value="">请选择</Select.Option>*/}
                    {/*    </Select>*/}
                    {/*</Form.Item>*/}
                    <Form.Item className="label-character-4" label="备注" name="spec">
                        <Input type="text" placeholder="请输入备注"/>
                    </Form.Item>
                    <Form.Item className="label-character-4" label="审批人" name="auditor">
                        <Input type="text" disabled/>
                    </Form.Item>
                    <Form.Item className="label-character-4" label="审批日期" name="auditedAt">
                        <Input type="text" disabled/>
                    </Form.Item>
                </Form>
                <hr></hr>
                <Button type='primary' onClick={formAudit.submit}>提交</Button>
            </Modal>

            {selectRecord != null &&
                <ApprovalProgress businessCode={selectRecord.businessCode} onOk={() => setSelectRecord(null)}/>}
        </>
    )
}

export default CouponAudit